<template>
  <nav class="site-nav" aria-label="site navigation">
    <ul class="site-nav__list">
      <li
        v-for="item in topNavPages"
        :key="item.pageId"
        class="site-nav__item"
        :aria-haspopup="item.childItems ? true : false"
      >
        <span v-html="item.label" />
        <Submenu v-if="item.childItems" :item="item" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { get } from "lodash";
import Submenu from "./Submenu.vue";

export default {
  name: "SiteNav",
  components: {
    Submenu,
  },

  computed: {
    topNavPages() {
      return this.$store.state.menu.siteMenuData;
    },
  },

  created() {
    // Refresh the vuex store on navigation
    this.$store.dispatch("menu/load", this.$apolloProvider.defaultClient);
  },

  methods: {
    setMenuItemCookie(pageId) {
      this.$cookies.set("menuItemId", pageId);
    },
  },
};
</script>

<style lang="scss">
.site-nav {
  display: none;
  @include desktop() {
    display: flex;
    width: 50%;
  }
  &__list {
    align-items: flex-end;
    display: flex;
    font-family: $font-serif;
    font-size: 1.2rem;
    justify-content: space-around;
    letter-spacing: 0.05em;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    max-width: 1100px;
    padding-left: 0;
    padding: 0;
    text-indent: 0;
    text-transform: uppercase;
    width: 100%;
  }
  &__item {
    text-indent: 0;
    padding-left: 0;
    position: relative;
  }
  &__link {
    text-decoration: none;
    color: black;
  }
}
</style>
