export const lineDefaults = {
  backgroundColor: "rgba(25,25,25,1)",
  // borderColor: 'rgba(25,25,25,1)',
  // pointBorderColor: 'rgba(25,25,25,1)',
  // pointBackgroundColor: 'rgba(25,25,25,1)',
  // pointHoverBackgroundColor: 'rgba(25,25,25,1)',
  // cubicInterpolationMode: 'monotone',
  fill: false,
  lineTension: 0,
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  borderWidth: 2,
  pointBorderWidth: 1,
  pointHoverRadius: 3,
  // pointHoverBorderColor: "rgba(220,220,220,1)",
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10,
  spanGaps: false,
};

export default lineDefaults;
