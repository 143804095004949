import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f2710bd = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _c5c4aa06 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _18b909b4 = () => interopDefault(import('../pages/Talk-To-An-Expert.vue' /* webpackChunkName: "pages/Talk-To-An-Expert" */))
const _04f653a2 = () => interopDefault(import('../pages/visualization/index.vue' /* webpackChunkName: "pages/visualization/index" */))
const _751403b8 = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _4f2c4d99 = () => interopDefault(import('../pages/admin/tools/index.vue' /* webpackChunkName: "pages/admin/tools/index" */))
const _4581f34b = () => interopDefault(import('../pages/reports/new.vue' /* webpackChunkName: "pages/reports/new" */))
const _d94ec6a4 = () => interopDefault(import('../pages/visualization/chart.vue' /* webpackChunkName: "pages/visualization/chart" */))
const _3f1ea44a = () => interopDefault(import('../pages/visualization/DataFitting.js' /* webpackChunkName: "pages/visualization/DataFitting" */))
const _037ba10a = () => interopDefault(import('../pages/visualization/oldFunctions.js' /* webpackChunkName: "pages/visualization/oldFunctions" */))
const _206425f2 = () => interopDefault(import('../pages/visualization/test.vue' /* webpackChunkName: "pages/visualization/test" */))
const _03ad7b4a = () => interopDefault(import('../pages/reports/_.vue' /* webpackChunkName: "pages/reports/_" */))
const _0f91c66c = () => interopDefault(import('../pages/announcement/_.vue' /* webpackChunkName: "pages/announcement/_" */))
const _00f19fe6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ac32521e = () => interopDefault(import('../pages/_chap/index.vue' /* webpackChunkName: "pages/_chap/index" */))
const _15df0904 = () => interopDefault(import('../pages/_chap/_.vue' /* webpackChunkName: "pages/_chap/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/reports",
    component: _1f2710bd,
    name: "reports"
  }, {
    path: "/search",
    component: _c5c4aa06,
    name: "search"
  }, {
    path: "/Talk-To-An-Expert",
    component: _18b909b4,
    name: "Talk-To-An-Expert"
  }, {
    path: "/visualization",
    component: _04f653a2,
    name: "visualization"
  }, {
    path: "/admin/login",
    component: _751403b8,
    name: "admin-login"
  }, {
    path: "/admin/tools",
    component: _4f2c4d99,
    name: "admin-tools"
  }, {
    path: "/reports/new",
    component: _4581f34b,
    name: "reports-new"
  }, {
    path: "/visualization/chart",
    component: _d94ec6a4,
    name: "visualization-chart"
  }, {
    path: "/visualization/DataFitting",
    component: _3f1ea44a,
    name: "visualization-DataFitting"
  }, {
    path: "/visualization/oldFunctions",
    component: _037ba10a,
    name: "visualization-oldFunctions"
  }, {
    path: "/visualization/test",
    component: _206425f2,
    name: "visualization-test"
  }, {
    path: "/reports/*",
    component: _03ad7b4a,
    name: "reports-all"
  }, {
    path: "/announcement/*",
    component: _0f91c66c,
    name: "announcement-all"
  }, {
    path: "/",
    component: _00f19fe6,
    name: "index"
  }, {
    path: "/:chap",
    component: _ac32521e,
    name: "chap"
  }, {
    path: "/:chap/*",
    component: _15df0904,
    name: "chap-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
