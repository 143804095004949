const sampleData = {
  labels: [
    1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000,
    2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
    2013, 2014, 2015, 2016,
  ],
  datasets: [
    {
      backgroundColor: "rgba(0,0,0,1)",
      fill: false,
      lineTension: 0,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0,
      borderJoinStyle: "miter",
      borderWidth: 2,
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      spanGaps: false,
      label: "Deer Harvested",
      yAxisID: "y-axis-0",
      borderColor: "rgba(0,0,0,1)",
      pointHoverBorderColor: "rgba(0,0,0,1)",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        201209,
        196241,
        199611,
        188901,
        189634,
        182270,
        181451,
        180811,
        148615,
        145720,
        155229,
        144303,
      ],
    },
    {
      backgroundColor: "rgba(0, 99, 132, 0.6)",
      fill: false,
      lineTension: 0,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0,
      borderJoinStyle: "miter",
      borderWidth: 2,
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      spanGaps: false,
      label: "DVA Rate",
      yAxisID: "y-axis-1",
      borderColor: "rgba(0, 99, 132, 1)",
      pointHoverBorderColor: "rgba(0, 99, 132, 1)",
      data: [
        113.9,
        128.4,
        140.3,
        144.6,
        141,
        144,
        144.9,
        null,
        134.5,
        137,
        155.8,
        149.1,
        173.2,
        173.2,
        187.5,
        184.6,
        174.5,
        185.6,
        180.9,
        178.1,
        178.1,
        161.9,
        173.3,
        148.3,
        145.3,
        143.7,
        null,
        null,
      ],
    },
  ],
};

export default sampleData;
