import { get } from "lodash";
import sampleData from "~/components/visualization/sampleData";
import buckData from "~/components/visualization/buckData";
import barData from "~/components/visualization/barData";
import options from "~/components/visualization/options";
import lineDefaults from "~/components/visualization/lineDefaults";
import chartColors, {
  colorIterator,
} from "~/components/visualization/chartColors";
import VisualizationDataSamples from "~/lib/VisualizationDataSamples";
import VisualizationFunctions from "~/components/visualization/visualizationFunctions";

export const strict = false;

function labelGenerator(label, selector) {
  const bucket = label.substr(0, label.indexOf(" Deer Harvested"));
  const bucketTypes = bucket
    .split("-")
    .filter((v, i) => (i % 2 === 0 ? true : false)); /*?*/
  const buckets = bucket
    .split("-")
    .filter((v, i) => (i % 2 === 0 ? false : true)); /*?*/
  let newLabel = "";
  bucketTypes.forEach((t, i) => {
    const val = buckets[i];
    switch (t) {
      case "county":
        if (selector) {
          const c = selector.county.$in.find(
            (county) =>
              county.toLowerCase().replace(/\s|\./g, "") ===
                val.toLowerCase() || county.toLowerCase() === val.toLowerCase()
          );
          if (c) {
            const tmp = c.split(" ");
            tmp.forEach((j) => {
              if (!(j.match(/[A-Z]/) && j.match(/[a-z]/))) {
                newLabel += `${
                  j.charAt(0).toUpperCase() + j.slice(1).toLowerCase()
                } `;
              } else {
                newLabel += `${j} `;
              }
            });
          } else {
            newLabel = "labelGenerator: invalid input";
          }
        } else {
          newLabel += val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
        }
        break;
      case "gender":
        if (val === "M") {
          newLabel += " Male";
        } else {
          newLabel += " Female";
        }
        break;
      case "ageClass":
        switch (val) {
          case "A":
            newLabel += " Adult";
            break;
          case "Y":
            newLabel += " Yearling";
            break;
          case "F":
            newLabel += " Fawn";
            break;
        }
        break;
      case "season":
        if (val.toLowerCase() !== "latewinter" && val.toLowerCase() !== "cwd") {
          newLabel += ` ${val.charAt(0).toUpperCase()}${val
            .slice(1)
            .toLowerCase()}`;
        } else {
          if (val.toLowerCase() === "latewinter") {
            newLabel += " Late Winter";
          } else if (val.toLowerCase() === "cwd") {
            newLabel += " CWD";
          }
        }
        break;
      default:
        break;
    }
  });
  if (!newLabel.length) {
    return label;
  }
  return newLabel;
}

const defaults = {
  chartOptions: options,
  chartData: { datasets: [], labels: [] },
  chartSelection: { value: "default", name: "DVA Rate and Deer Harvest" },
  counties: [],
  countySelections: [],
  genderSelections: [],
  ageSelections: [],
  seasonSelections: [],
  dvaSelection: null,
  yearLabels: [1999, 2000],
  beginYear: null,
  endYear: null,
  chartType: "line",
  viewAs: "chart",
};

/* eslint-disable no-shadow */
export const state = () => ({
  allYearLabels: [],
  yearLabels: defaults.yearLabels,
  chartOptions: defaults.chartOptions,
  chartData: defaults.chartData,
  chartSelection: defaults.chartSelection,
  chartType: defaults.chartType,
  counties: defaults.counties,
  countySelections: defaults.countySelections,
  genderSelections: defaults.genderSelections,
  ageSelections: defaults.ageSelections,
  seasonSelections: defaults.seasonSelections,
  dvaSelection: defaults.dvaSelection,
  beginYear: defaults.beginYear,
  endYear: defaults.endYear,
  viewAs: defaults.viewAs,
  errMsg: null,
});

export const actions = {
  updateChartData({ commit, dispatch }, payload) {
    if (get(payload.value, "") !== "") {
      commit("setChartSelection", payload);
      let data;
      if (payload.value === "default") {
        data = sampleData;
      } else if (!VisualizationFunctions.isLineChart(payload.value)) {
        data = barData;
      } else {
        data = buckData;
      }
      commit("setChartData", data);
    } else {
      commit("setChartSelection", defaults.chartSelection);
      commit("setChartData", defaults.chartData);
    }
    dispatch("determineChartType", payload.value);
  },
  determineChartType({ commit }, payload) {
    const type = VisualizationFunctions.isLineChart(payload) ? "line" : "bar";
    commit("setChartType", type);
  },
  async resetVisualization({ commit }) {
    commit("setChartSelection", defaults.chartSelection);
    commit("setCountySelections", defaults.countySelections);
    commit("setGenderSelections", defaults.genderSelections);
    commit("setAgeSelections", defaults.ageSelections);
    commit("setSeasonSelections", defaults.seasonSelections);
    commit("setDvaSelection", defaults.dvaSelection);

    const {
      data: { years },
    } = await this.$axios.get("/api/sample_totals/years");
    const begin = Math.min(...years);
    const end = Math.max(...years);
    commit("setBeginYear", begin);
    commit("setEndYear", end);
    commit("setAllYearLabels", VisualizationFunctions.rangeInclude(begin, end));

    const params = {
      // selectedViz: defaults.chartSelection,
      beginYear: this.beginYear,
      endYear: this.endYear,
    };
    const url = "/api/sample_totals";
    const result = await this.$axios.get(url, { params });
    const {
      data: { samplesTotals: samplesTotals },
    } = result;
    const dva = VisualizationDataSamples.yValuesFor(
      years,
      samplesTotals.dvarates
    );
    const harvest = VisualizationDataSamples.yValuesFor(
      years,
      samplesTotals.harvests
    );

    const datasets = [];
    datasets.push(
      Object.assign({}, lineDefaults, {
        type: "line",
        label: "Deer Harvested",
        yAxisID: "y-axis-0",
        backgroundColor: chartColors.black,
        borderColor: chartColors.totalBorder,
        pointHoverBorderColor: chartColors.black,
        data: harvest,
      })
    );
    datasets.push(
      Object.assign({}, lineDefaults, {
        type: "bar",
        label: "Deer-Vehicle Accident (DVA)",
        yAxisID: "y-axis-1",
        borderWidth: 0,
        backgroundColor: chartColors.lightblue,
        borderColor: chartColors.lightblue,
        pointHoverBorderColor: chartColors.blue,
        data: dva,
      })
    );
    const newChartData = { datasets: datasets, labels: years };
    commit("setChartData", { data: newChartData });
  },
};

export const mutations = {
  setAllYearLabels(state, labels) {
    state.allYearLabels = labels;
  },
  setYearLabels(state, labels) {
    state.yearLabels = labels;
  },
  setChartOptions(state, data) {
    state.chartOptions = data;
  },
  setChartData(state, { data, selector }) {
    const datasets = data.datasets;
    colorIterator.reset();
    datasets.forEach((ds) => {
      if (!ds.label.match(/^DVA Goal/) && !ds.label.match(/Accident/)) {
        if (!ds.backgroundColor || ds.backgroundColor === "rgba(0,0,0,1)") {
          const color = colorIterator.next().value;
          ds.backgroundColor = color;
          ds.borderColor = color;
          ds.pointHoverBorderColor = color;
        }
      }
      let label = labelGenerator(ds.label, selector);
      if (!selector?.county || selector?.county?.$in?.length === 0) {
        if (!label.match(/^Statewide/)) {
          label = `Statewide ${label}`;
        }
      }
      ds.label = label;
    });
    if (datasets.length === 1 && datasets[0].data?.length === 1) {
      datasets[0].type = "bar";
    }
    // Order datasets
    const goals = datasets.find((dataset) => dataset.label.match(/DVA Goal/));
    if (goals) {
      goals.order = 0;
      const ds = datasets.filter((dataset) => dataset.yAxisID === "y-axis-0");
      ds.forEach((d, idx) => (d.order = idx + 1));
    }
    state.chartData = data;
  },
  setChartSelection(state, selection) {
    state.chartSelection = selection;
  },
  setChartType(state, type) {
    state.chartType = type;
  },
  setCounties(state, counties) {
    state.counties = counties;
  },
  setCountySelections(state, counties) {
    state.countySelections = counties;
  },
  setGenderSelections(state, genders) {
    state.genderSelections = genders;
  },
  setAgeSelections(state, ages) {
    state.ageSelections = ages;
  },
  setSeasonSelections(state, seasons) {
    state.seasonSelections = seasons;
  },
  setDvaSelection(state, dva) {
    state.dvaSelection = dva;
  },
  setBeginYear(state, year) {
    state.beginYear = year;
  },
  setEndYear(state, year) {
    state.endYear = year;
  },
  // setCountyGeoIdsSub(state, sub) {
  //   state.countyGeoIdsSub = sub;
  // },
  setViewAs(state, view) {
    state.viewAs = view;
  },
  setErrMsg(state, msg) {
    state.errMsg = msg;
  },
};
