<template>
  <nav class="footer-menu">
    <ul class="footer-menu__list">
      <li
        v-for="item in footerMenu"
        :key="item.pageId"
        class="footer-menu__item"
      >
        <a :href="item.url" class="footer-menu__link" :target="item.target">{{
          item.label
        }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    footerMenu() {
      return this.$store.state.menu.footerMenuData;
    },
  },
};
</script>

<style lang="scss">
.footer-menu {
  color: white;
  flex: auto;
  font-family: $font-serif;
  padding: 0.5rem 2rem;
  @include tablet {
    max-width: 400px;
    order: 2;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    @include tablet {
      flex-direction: column;
    }
    @include breakpoint(1050px) {
      flex-direction: row;
    }
  }
  &__item {
    @include tablet {
      & + & {
        margin-top: 0.5rem;
      }
    }
    @include breakpoint(1050px) {
      & + & {
        margin-top: 0;
      }
    }
  }
  &__link {
    color: currentColor;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
