<template>
  <content-page page-slug="Page Not Found">
    <div class="error-container">
      <div v-if="error.statusCode === 404" class="error-content">
        <div>
          <h1>Sorry, the page you were looking for doesn't exist.</h1>
          <p>
            You can return to our <a href="/">home page</a> if you can't find
            what you are looking for.
          </p>
        </div>
        <!-- <img src="~/assets/Images/404.png" /> -->
      </div>

      <div v-if="error.statusCode === 400" class="error-content">
        <div>
          <h1>Sorry, the page you are looking for doesn't exist anymore.</h1>
          <p>
            You can return to our <a href="/">home page</a> if you can't find
            what you are looking for.
          </p>
        </div>
        <!-- <img src="~/assets/Images/404.png" /> -->
      </div>

      <div v-if="error.statusCode === 410" class="error-content">
        <div>
          <h1>Sorry, the page you are looking for has been deleted.</h1>
          <p>
            You can return to our <a href="/">home page</a> if you can't find
            what you are looking for.
          </p>
        </div>
        <!-- <img src="~/assets/Images/404.png" /> -->
      </div>

      <div v-if="error.statusCode === 500" class="error-content">
        <div>
          <h1>Sorry, the page you were looking for doesn't exist.</h1>
          <p>
            You can return to our <a href="/">home page</a> if you can't find
            what you are looking for.
          </p>
        </div>
        <!-- <img src="~/assets/Images/404.png" /> -->
      </div>
    </div>
  </content-page>
</template>

<script>
import ContentPage from "~/components/ContentPage/ContentPage";

export default {
  components: {
    ContentPage,
  },
  layout: "error",
  props: ["error"],
  head() {
    return {
      title: "Lost?",
    };
  },
};
</script>

<style scoped>
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-content {
  width: 90%;
  max-width: 800px;
  margin: auto;
  display: grid;
  grid-template: auto / auto 200px;
  grid-column-gap: 10px;
  text-align: center;
}

.error-content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-content > div > h1 {
  font-size: 30px;
  margin: 0;
}

.error-content > img {
  width: 200px;
}

a {
  text-decoration: unset;
  color: var(--color-tpBlue);
}
</style>
