<template>
  <div class="search-widget">
    <div class="search-widget__container" role="search">
      <div class="search-widget__open-container">
        <form
          id="search-form"
          class="search-widget__form"
          @submit.prevent="submitSearch"
        >
          <input
            id="searchq"
            v-model="searchTerm"
            type="text"
            name="searchq"
            class="search-widget__input"
            title="Search input box"
            placeholder="Enter search"
          />
          <button type="submit">SEARCH</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchWidget",
  data() {
    return {
      isOpen: false,
      searchTerm: null,
    };
  },
  computed: {
    searchWidgetClass() {
      return {
        "search-widget--open": this.isOpen,
      };
    },
  },
  mounted() {
    if (this.$route.query.term) {
      this.searchTerm = this.$route.query.term;
    }
  },
  methods: {
    submitSearch() {
      this.$router.push({
        path: "/search",
        query: {
          term: this.searchTerm,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.search-widget {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 4px 0;
  &__container {
    display: flex;
    pointer-events: auto;
  }
  &__open-container {
    color: black;
    align-items: center;
    border: solid rgba(0, 0, 0, 0) 1px;
    display: flex;
  }
  &__form {
    align-items: center;
    display: flex;
    box-sizing: border-box;
    input {
      font-size: 16px;
      height: 31px;
      padding: 2px;
      border-color: #d8d6cb;
      border-style: solid;
      &:focus {
        border: solid 2px #537e7f;
        outline: none !important;
      }
    }
    button {
      font-size: 16px !important;
      letter-spacing: 0.05rem;
      padding: 0.25rem 0.5rem;
      background-color: #537e7f;
      color: white;
    }
  }
}
@media (max-width: 500px) {
  .search-widget {
    justify-content: center;
    margin-top: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
}
</style>
