<template>
  <header class="header" role="presentation">
    <SearchWidget />
    <a href="/" class="header__logo-link">
      <HeaderLogo />
    </a>
    <SiteNav />
    <!-- <app-announcement /> -->
  </header>
</template>

<script>
import HeaderLogo from "~/components/HeaderLogo/HeaderLogo";
import SearchWidget from "~/components/SearchWidget.vue";
import SiteNav from "~/components/SiteNav/SiteNav.vue";
// import AppAnnouncement from "~/components/AppAnnouncement/AppAnnouncement.vue";

export default {
  name: "StandardHeader",
  components: {
    HeaderLogo,
    SearchWidget,
    SiteNav,
    // AppAnnouncement
  },
};
</script>

<style lang="scss">
.header {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @include mobile {
    padding: 0.5rem 1rem;
  }
  padding: 0.5rem 2rem 8px;
  position: relative;
  @include tablet {
    justify-content: space-between;
  }

  background-color: white;
  display: flex;
  padding: 1.5rem 0.5rem 0.5rem;
  @include tablet {
    padding: 1.5rem 3rem 0.5rem;
  }
  &__logo-link {
    text-decoration: none;
    width: 100%;
    @include desktop() {
      width: 50%;
    }
  }
  &__logo {
    height: calc(100px - 2rem);
    background-image: url("/images/header/White-tailedDeerIllinoisLogo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    flex: 1 1 270px;
    margin: 1rem;
    @include desktop() {
      height: calc(100px - 1.2rem);
      margin: 0.2rem 0 1rem 1rem;
    }
  }
}
</style>
