/* eslint-disable no-shadow */
export const state = () => ({
  inputErrors: null,
  contactInformation: {
    name: null,
    email: null,
    phone: null,
  },
  location: {
    county: null,
    details: null,
  },
  deadDeer: null,
  sickDeer: null,
  symptoms: {
    symptom1: false,
    symptom2: false,
    symptom3: false,
    symptom4: false,
    symptom5: false,
    symptom6: false,
    symptom7: false,
    symptom8: false,
    other: "",
  },
  waterProximity: {
    water_deer0: false,
    water_deer1: false,
    water_deer2: false,
    water_deer3: false,
    water_deer4: false,
    water_deer5: false,
  },
  photos: [],
  admin: {
    incidentType: null,
    biologist: "",
    dateHandled: "",
    actionTaken: null,
    notes: "",
  },
});

export const mutations = {
  setContactInformation(state, contactInformation) {
    state.contactInformation = contactInformation;
  },
  setLocation(state, location) {
    state.location = location;
  },
  setDeadDeer(state, number) {
    state.deadDeer = number;
  },
  setSickDeer(state, number) {
    state.sickDeer = number;
  },
  setSymptoms(state, signs) {
    state.symptoms = signs;
  },
  setWaterProximity(state, proximity) {
    const waterProximity = Object.assign({}, state.waterProximity);
    Object.keys(waterProximity).forEach((k) => {
      waterProximity[k] = false;
      if (k === proximity) {
        waterProximity[k] = true;
      }
    });
    state.waterProximity = waterProximity;
  },
  setPhotos(state, photos) {
    state.photos = photos;
  },
  setAdmin(state, admin) {
    state.admin = admin;
  },
};
