const VisualizationDataSamples = {
  /**
   * yValuesFor
   *
   * @name yValuesFor
   * @function
   * @param {Array} years Array of Number year values
   * @param {Array} yValues Array of {year, value} objects
   * @param {String} field The field to pull the y value from
   * @returns {Array} The y values for the range of years
   */
  yValuesFor(years, yValues, field = "value") {
    const samples = years.map((yr) => {
      const samplesForYear = yValues.filter((obj) => obj.year === yr);
      let sum = samplesForYear.reduce((acc, cv) => {
        let partialSum = acc + cv[field];
        if (!partialSum) {
          partialSum = null;
        }
        return partialSum;
      }, 0);
      if (!yValues.find((obj) => obj.year === yr)) {
        sum = null;
      }
      return sum;
    });
    return samples;
  },

  yValuesForGoals(years, oldGoal, newGoal) {
    const startYear = 2008;
    const newGoalYear = 2014;
    const data = years.map((year) => {
      if (year < startYear) {
        return null;
      }
      if (year < newGoalYear) {
        return oldGoal;
      } else {
        return newGoal;
      }
    });
    return data;
  },
};

export default VisualizationDataSamples;

//import { Meteor } from "meteor/meteor";
//import { access } from "fs";
//import csv from "csv/lib";
//
///**
// * VisualizationDataSamples
// * @name VisualizationDataSamples
// * @function
// */
//class VisualizationDataSamples {
//  constructor() {
//    this.generateYearLabels.bind(this);
//    this.samplesFor.bind(this);
//    this.yValuesFor.bind(this);
//    this.export.bind(this);
//    this.writeCSV.bind(this);
//  }
//
//  /**
//   * generateYearLabels
//   *
//   * @name generateYearLabels
//   * @function
//   * @description This function uses Meteor's Promise functionality to perform
//   * async/await for the Meteor method return value.
//   * @static
//   * @param {Number} [beginYearVal] The year to start with
//   * @param {Number} [endYearVal] The year to end on
//   * @returns {Promise} A promise that wraps a Meteor method call and is
//   * resolved when the method returns
//   */
//  static async generateYearLabels(beginYearVal, endYearVal) {
//    return new Promise((resolve, reject) => {
//      Meteor.call("sampleYears", beginYearVal, endYearVal, (err, result) => {
//        if (err) {
//          reject(err);
//        }
//
//        resolve(result);
//      });
//    }).catch(e => {
//      // eslint-disable-next-line max-len
//      const msg = `VisualizationDataSamples.generateYearLabels(${beginYearVal}, ${endYearVal}) failed:`;
//      console.error(msg, e);
//    });
//  }
//
//  /**
//   * samplesFor
//   *
//   * @name samplesFor
//   * @function
//   * @static
//   * @param {[String]} counties The counties selected
//   * @param {Array} years An array of year values to select from
//   * @returns {Promise} A promise that wraps a Meteor method call and is
//   * resolved when the method returns
//   */
//  static async samplesFor(counties, years) {
//    return new Promise((resolve, reject) => {
//      Meteor.call("samplesFor", counties, years, (err, result) => {
//        if (err) {
//          reject(err);
//        }
//
//        resolve(result);
//      });
//    }).catch(e => {
//      const msg = `ERROR: VisualizationDataSamples.samplesFor(${counties}, ${years}) failed:`;
//      console.error(msg, e);
//    });
//  }
//
//  /**
//   * harvestSamplesFor
//   *
//   * @static
//   * @param {Object} sel
//   * @returns {Promise}
//   *
//   * @memberOf VisualizationDataSamples
//   */
//  static async harvestSamplesFor(sel) {
//    return new Promise((resolve, reject) => {
//      Meteor.call("harvestDataRecordQuery", sel, (err, result) => {
//        if (err) {
//          reject(err);
//        }
//        resolve(result);
//      });
//    }).catch(e => {
//      const msg = `ERROR: VisualizationDataSamples.harvestSamplesFor failed`;
//      console.error(msg, e);
//    });
//  }
//
//  /**
//   * yValuesFor
//   *
//   * @name yValuesFor
//   * @function
//   * @static
//   * @param {Array} years Array of Number year values
//   * @param {Array} yVals Array of {year, value} objects
//   * @returns {Array} The y values for the range of years
//   */
//  static yValuesFor(years, yVals) {
//    const samples = years.map(yr => {
//      const samplesForYear = yVals.filter(obj => obj.year === yr);
//      let sum = samplesForYear.reduce((acc, cv) => {
//        let partialSum = acc + cv.value;
//        if (!partialSum) {
//          partialSum = null;
//        }
//        return partialSum;
//      }, 0);
//      if (!yVals.find(obj => obj.year === yr)) {
//        sum = null;
//      }
//      return sum;
//    });
//    return samples;
//  }
//
//  /**
//   * toTableRows
//   *
//   * @name toTableRows
//   * @function
//   * @static
//   * @param {Object} samplesForReturnVal The return value of the Meteor method samplesFor
//   * @param {Array} years Array of numbers representing years.
//   * @returns {Array[Array]} An array of arrays representing the data in the table row.
//   */
//  static toTableRows(samplesForReturnVal, years, replaceChar = null) {
//    const rows = [];
//    years.forEach(yr => {
//      const county = samplesForReturnVal.county || "All Counties";
//      let dvarate, harvest, oldGoal, newGoal;
//
//      const dvaObj = samplesForReturnVal.dvarates.find(dva => dva.year === yr);
//      if (dvaObj) {
//        if (dvaObj.value) {
//          dvarate = dvaObj.value || replaceChar;
//        } else {
//          dvarate = replaceChar;
//        }
//      } else {
//        dvarate = replaceChar;
//      }
//
//      const harvestObj = samplesForReturnVal.harvests.find(hvst => hvst.year === yr);
//      if (harvestObj) {
//        harvest = harvestObj.value || replaceChar;
//      } else {
//        harvest = replaceChar;
//      }
//
//      if (samplesForReturnVal.county && samplesForReturnVal.county !== "All Counties") {
//        const oldGoalObj = samplesForReturnVal.oldGoals.find(og => og.year === yr);
//        if (oldGoalObj) {
//          oldGoal = oldGoalObj.value || replaceChar;
//        } else {
//          oldGoal = replaceChar;
//        }
//
//        const newGoalObj = samplesForReturnVal.newGoals.find(ng => ng.year === yr);
//        if (newGoalObj) {
//          newGoal = newGoalObj.value || replaceChar;
//        } else {
//          newGoal = replaceChar;
//        }
//      } else {
//        oldGoal = replaceChar;
//        newGoal = replaceChar;
//      }
//
//      rows.push([county, yr, dvarate, harvest, oldGoal, newGoal]);
//    });
//    return rows;
//  }
//
//  static allCountiesTableRows(samples, years, replaceChar = null) {
//    const rows = [];
//    samples.forEach(s => {
//      const county = s.county;
//      const year = s.year;
//      let dvarate = s.dvarate;
//      if (!dvarate) {
//        dvarate = replaceChar;
//      }
//      let harvest = s.harvest;
//      if (!harvest) {
//        harvest = replaceChar;
//      }
//      let oldGoal = s.oldGoal;
//      if (!oldGoal) {
//        oldGoal = replaceChar;
//      }
//      let newGoal = s.newGoal;
//      if (!newGoal) {
//        newGoal = replaceChar;
//      }
//      rows.push([county, year, dvarate, harvest, oldGoal, newGoal]);
//    });
//    return rows;
//  }
//
//  /**
//   * export
//   *
//   * @name export
//   * @function
//   * @static
//   * @param {[String]} [counties] County to get data for
//   * @param {Number} [beginYear] The year to start with
//   * @param {Number} [endYear] The year to end with
//   * @param {Object} [opts] { beginYear, endYear, columns, order }
//   * @return {String} The csv data
//   */
//  static async export(counties, opts) {
//    console.log("export opts:", JSON.stringify(opts));
//    if (Meteor.isClient) {
//      console.warn("VisualizationDataSamples.export is unavailable on the client");
//      return null;
//    }
//    // logger.debug('-- export --');
//
//    if (opts.beginYear > opts.endYear && opts.endYear) {
//      throw new Meteor.Error(500, "Begin Year must be before End Year.");
//    }
//
//    if (counties && counties.length === 1 && counties[0] === "All Counties") {
//      // eslint-disable-next-line no-param-reassign
//      counties = [];
//    }
//    const years = await this.generateYearLabels(opts.beginYear, opts.endYear);
//    console.log(`export years: ${years}`);
//
//    let columnHeads = null;
//    let samplesFor = null;
//    let filteredSamplesFor = null;
//    let samplesRows = null;
//    if (opts.viz === "trend") {
//      samplesFor = Meteor.call("samplesFor", counties, years);
//      if (samplesFor) {
//        samplesRows = samplesFor.harvests;
//      }
//    } else {
//      filteredSamplesFor = Meteor.call("filteredSamplesFor", counties, years, opts);
//      if (filteredSamplesFor) {
//        // columnHeads = filteredSamplesFor.columns;
//        columnHeads = ["county", "year", opts.columnHead];
//        samplesRows = filteredSamplesFor.samples;
//      }
//    }
//
//    let multiCounties = false;
//    if (counties && counties.length > 1) {
//      multiCounties = true;
//    }
//    if (opts.data === "samples") {
//      console.log(`-- counties: ${counties}`);
//      console.log(`-- multiCounties: ${multiCounties}`);
//      if (!multiCounties) {
//        columnHeads = ["Counties", "Year", "DVA Rate", "Harvest", "Old Goal", "New Goal"];
//      } else {
//        columnHeads = ["Counties", "Year", "DVA Rate", "Harvest"];
//      }
//    }
//
//    // const filteredSamples = Meteor.call("filteredSamplesFor", counties, years, opts);
//    // const samplesRows = samplesFor || filteredSamples.samples;
//    console.log(`columnHeads: ${JSON.stringify(columnHeads)}`);
//    console.log(`samplesRows: ${JSON.stringify(samplesRows)}`);
//    if (opts.columnHead) {
//      samplesRows.forEach(obj => {
//        const total = obj.total || obj.value;
//        delete obj.total;
//        delete obj.value;
//        obj[opts.columnHead] = total;
//      });
//    }
//
//    let csvFile;
//    try {
//      csvFile = await this.writeCSV(columnHeads, years, samplesRows);
//    } catch (e) {
//      logger.error("exportDeerData VisualizationDataSamples.export error:", e);
//      return e;
//    }
//
//    return csvFile;
//  }
//
//  /**
//   * writeCSV
//   *
//   * @name writeCSV
//   * @function
//   * @static
//   * @param {Object} rows The samples property of the return value of the Meteor method filteredSamplesFor
//   * @returns {Promise} A Promise that wraps the async csv.stringify operations
//   */
//  static async writeCSV(columns, years, rows) {
//    if (Meteor.isClient) {
//      console.warn("VisualizationDataSamples.export is unavailable on the client");
//      return false;
//    }
//    console.log("-- writeCSV --");
//    console.log(`rows: ${JSON.stringify(rows)}`);
//
//    return new Promise((resolve, reject) => {
//      let data = "";
//
//      // Setup csv writer
//      const csvOpts = {
//        header: true,
//        delimiter: ","
//      };
//      if (columns) {
//        csvOpts.columns = columns;
//      }
//      // const stringifier = csv.stringify({ header: true, columns: columns, delimiter: "," });
//      const stringifier = csv.stringify(csvOpts);
//      stringifier.on("readable", () => {
//        let row;
//        // eslint-disable-next-line no-cond-assign
//        while ((row = stringifier.read())) {
//          console.log("readable row:", row.toString());
//          data += row;
//        }
//      });
//      stringifier.on("error", err => {
//        logger.error("ERROR in csv.stringify");
//        // reject the Promise on error generating csv
//        reject(err);
//      });
//      stringifier.on("finish", () => {
//        // resolve the Promise with the csv file data
//        // logger.info("stringifier.on 'finish' with ", data);
//        resolve(data);
//      });
//
//      // write data to csv
//      // const rows = this.toTableRows(samplesForReturnVal, years, '');
//      rows.forEach(row => {
//        // console.log('write row:', row);
//        stringifier.write(row);
//      });
//
//      // close csv writer
//      stringifier.end();
//    });
//  }
//}
//
//export default VisualizationDataSamples;
