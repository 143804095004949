<template>
  <footer class="footer" role="presentation">
    <footer-menu class="footer__section" />
    <social-icons class="footer__section" />
    <sponsor-logos
      class="footer__section"
      role="region"
      aria-label="Sponsor logos"
    />
    <div role="region">
      <a href="https://2wav.com" target="_blank">
        <img
          src="/images/footer/createdby2wav.svg"
          class="footer__2wav-logo"
          alt="The 2wav text logo"
        />
      </a>
    </div>
  </footer>
</template>

<script>
import SocialIcons from "./SocialIcons.vue";
import SponsorLogos from "./SponsorLogos.vue";
import FooterMenu from "./FooterMenu";

export default {
  name: "Footer",
  components: {
    SocialIcons,
    SponsorLogos,
    FooterMenu,
  },
};
</script>

<style lang="scss">
.footer {
  background-color: get-color("teal");
  display: flex;
  flex-direction: column;
  min-height: 125px;
  position: relative;
  background-size: cover;
  @include tablet {
    flex-direction: row;
    justify-content: flex-end;
  }
  // &__section {
  //   @include tablet() {
  //     width: calc(100% / 2);
  //   }
  //   @include desktop() {
  //     width: calc(100% / 3);
  //   }
  // }
  &__2wav-logo {
    float: right;
    height: 110px;
    position: absolute;
    top: 0.5rem;
    right: 0.1rem;
  }
}
</style>
