<template>
  <div class="chapter-nav-links">
    <div
      class="chapter-nav-links__control chapter-nav-links__control--previous"
    >
      <a
        :href="prevPageLink"
        class="chapter-nav-links__link chapter-nav-links__link--previous"
        aria-label="previous"
      >
        <simple-svg
          :filepath="'/images/ui/angle-up-light.svg'"
          class="chapter-nav-links__icon chapter-nav-links__icon--previous"
        />
      </a>
    </div>

    <div
      class="
        next_page
        chapter-nav-links__control chapter-nav-links__control--next
      "
    >
      <a
        :href="nextPageLink"
        class="chapter-nav-links__link chapter-nav-links__link--next"
        aria-label="next"
      >
        <simple-svg
          :filepath="'/images/ui/angle-down-light.svg'"
          class="chapter-nav-links__icon chapter-nav-links__icon--next"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { get, find, findIndex } from "lodash";
import { Route } from "vue-router";
import he from "he";
import Vue from "vue";
import VueSimpleSVG from "vue-simple-svg";
Vue.use(VueSimpleSVG);

export default {
  name: "ChapterNavLinks",

  computed: {
    // chapterPages() {
    //   const chapterTitle = get(this.$store.state.chapter.current, "title");
    //   const siteMenuItem = find(this.$store.state.menu.siteMenuData, [
    //     "label",
    //     chapterTitle
    //   ]);
    //   return siteMenuItem.childItems;
    // },
    // pageIndex() {
    //   const pageTitle = get(this.$store.state.chapter, "insidePage.title");
    //   const index = findIndex(this.chapterPages, ["label", pageTitle]);
    //   return index;
    // },
    prevPageLink() {
      const chapterTitle = get(this.$store.state.chapter.current, "title");
      const siteMenuItem = find(this.$store.state.menu.siteMenuData, [
        "label",
        chapterTitle,
      ]);
      const chapterPages = siteMenuItem.childItems;
      const pageSlug = get(this.$store.state.chapter, "insidePage.slug", "");
      const index = findIndex(chapterPages, ["slug", pageSlug]);

      const pageCount = chapterPages.length;
      const newIndex = index - 1 >= 0 ? index - 1 : pageCount - 1;
      const newPage = chapterPages[newIndex];
      return newPage.url;
    },
    nextPageLink() {
      const chapterTitle = get(this.$store.state.chapter.current, "title");
      const siteMenuItem = find(this.$store.state.menu.siteMenuData, [
        "label",
        chapterTitle,
      ]);
      const chapterPages = siteMenuItem.childItems;
      const pageSlug = get(this.$store.state.chapter, "insidePage.slug", "");
      const index = findIndex(chapterPages, ["slug", pageSlug]);

      const pageCount = chapterPages.length;
      const newIndex = index + 1 < pageCount ? index + 1 : 0;
      const newPage = chapterPages[newIndex];
      return newPage.url;
    },
  },
};
</script>

<style lang="scss">
.chapter-nav-links {
  display: flex;
  float: right;
  justify-content: flex-end;
  line-height: 1;
  overflow: visible;
  &--bottom {
    float: none;
    height: auto;
    justify-content: center;
  }
  &__control {
    padding: 0 0.5rem;
    font-size: 3rem;
    margin-top: -0.3rem;
  }
  &__link {
    color: get-color("grey");
    text-decoration: none;
  }
  &__icon {
    $distance: 4px;
    fill: get-color("grey");
    transition: transform 200ms ease-in-out;
    width: 32px;
    &:hover {
      transform: scale(1.15);
    }
  }
  .content-page__content ~ & {
    justify-content: center;
    height: 50px;
  }
}
</style>
