<template>
  <div class="submenu" :aria-label="submenuLabel">
    <ul class="submenu__list">
      <li v-for="(page, index) in pages" :key="index" class="submenu__item">
        <!-- <nuxt-link :to="page.url" :title="page.label" class="submenu__link">{{
          page.label
        }}</nuxt-link> -->
        <a
          :href="page.url"
          :title="page.label"
          class="submenu__link"
          @click="setMenuItemCookie(item.menuItemId)"
          v-html="page.label"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Submenu",
  props: {
    item: Object,
  },
  computed: {
    pages() {
      return this.$props.item.childItems;
    },
    submenuLabel() {
      return `${this.$props.item.label} Submenu`;
    },
  },
  methods: {
    setMenuItemCookie(pageId) {
      this.$cookies.set("menuItemId", pageId);
    },
  },
};
</script>

<style lang="scss">
.submenu {
  background-color: white;
  border: solid get-color("grey", 300) 1px;
  border-top: none;
  font-family: $font-sans;
  font-size: 1rem;
  font-variant: normal;
  line-height: 1.75;
  opacity: 0;
  position: absolute;
  left: -0.5rem;
  text-transform: none;
  top: 1.55rem;
  transition: all 0.8s;
  visibility: hidden;
  width: 15rem;
  z-index: 1000;
  .site-nav__item:hover & {
    opacity: 1;
    transition: all 0.4s;
    visibility: visible;
    z-index: 1000;
    &:before {
      content: "";
      width: 3rem;
      height: 115%;
      height: calc(100% + 3rem);
      position: absolute;
      left: -3rem;
      z-index: 500;
    }
  }
  &__list {
    list-style-type: none;
  }
  &__item {
    color: black;
    position: relative;
    padding-left: 0.75rem;
    &:hover {
      color: white;
      background-color: get-color("brown");
    }
  }
  &__link {
    color: currentColor;
    display: inline-block;
    text-decoration: none;
    width: 100%;
  }
  &__bullet {
    display: none;
    position: absolute;
    right: 0.25rem;
    top: 0.33rem;
  }
}
</style>
