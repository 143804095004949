/* eslint-disable no-shadow */
export const state = () => ({
  startDate: null,
  endDate: null,
  diseaseType: "All",
  county: "All",
  actionTaken: null,
  areResults: false,
  results: [],
  filteredResults: [],
  incident: null,
});

export const mutations = {
  setStartDate(state, date) {
    state.startDate = date;
  },
  setEndDate(state, date) {
    state.endDate = date;
  },
  setDiseaseType(state, type) {
    state.diseaseType = type;
  },
  setCounty(state, county) {
    state.county = county;
  },
  setActionTaken(state, action) {
    state.actionTaken = action;
  },
  setAreResults(state, boolean) {
    state.areResults = boolean;
  },
  setResults(state, results) {
    state.results = results;
  },
  setFilteredResults(state, results) {
    state.filteredResults = results;
  },
  setIncident(state, incident) {
    state.incident = incident;
  },
};
