import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import schema from "./fragments.json";
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema,
});

export default ({ req, app }) => {
  let headersVal = {
    "Accept-Language": "en-us",
  };
  return {
    httpEndpoint: process.env.WP_GQL_URL,
    httpLinkOptions: {
      headers: headersVal,
    },
    cache: new InMemoryCache({ fragmentMatcher }),
  };
};
