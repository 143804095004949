/* eslint-disable no-shadow */
export const state = () => ({
  menuOpen: false,
});

export const mutations = {
  toggleMenu(state) {
    if (state.menuOpen) {
      state.menuOpen = false;
      return;
    }
    state.menuOpen = true;
  },
  setMenuOpen(state, value) {
    state.menuOpen = value;
  },
};
