<template>
  <section class="sponsor-logos">
    <a
      href="http://www.dnr.illinois.gov/"
      class="sponsor-logos__link"
      title="Illinois Department of Natural Resources"
    >
      <img
        src="/images/footer/idnr.svg"
        class="sponsor-logos__image sponsor-logos__image--idnr"
        alt="The Illinois Department of Natural Resources logo"
      />
    </a>
    <a
      href="http://www.fws.gov/"
      class="sponsor-logos__link"
      title="United States Fish and Wildlife Service"
    >
      <img
        src="/images/footer/fishandwildlife.svg"
        class="sponsor-logos__image sponsor-logos__image--fws"
        alt="The U.S. Fish and Wildlife Service logo"
      />
    </a>
    <a
      href="https://wsfrprograms.fws.gov/subpages/grantprograms/wr/wr.htm"
      class="sponsor-logos__link"
      title="United States Fish and Wildlife Wildlife Restoration Grant Program"
    >
      <img
        src="/images/footer/restoration.svg"
        class="sponsor-logos__image sponsor-logos__image--restoration"
        alt="The Wildlife Restoration logo"
      />
    </a>
    <a
      href="http://www.ngrrec.org/"
      class="sponsor-logos__link"
      title="The National Great Rivers Research and Education Center"
    >
      <img
        src="/images/footer/ngrrec.svg"
        class="sponsor-logos__image sponsor-logos__image--ngrrec"
        alt="The National Great Rivers Research and Education Center logo"
      />
    </a>
  </section>
</template>

<script>
export default {
  name: "SponsorLogos",
};
</script>

<style lang="scss">
.sponsor-logos {
  align-items: center;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0.5rem;
  @include desktop() {
    justify-content: flex-start;
    min-height: 130px;
    padding: 0.5rem 2.5rem;
  }
  &__link {
    text-decoration: none;
    color: black;
  }
  &__image {
    margin: 0 0.5rem;
    &--idnr {
      // width: 46px;
      height: 86px;
    }
    &--fws {
      // width: 57px;
      height: 67px;
    }
    &--restoration {
      // width: 63px;
      height: 61px;
    }
    &--ngrrec {
      // width: 220px;
      height: 70px;
    }
  }
}
</style>
