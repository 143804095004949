import { get } from "lodash";

export const cleanMenuNode = function (node) {
  const item = {
    label: node.label,
    menuItemId: node.menuItemId,
  };
  if (get(node, "connectedObject.pageId")) {
    item.pageId = node.connectedObject.pageId;
    if (get(node, "connectedObject.status")) {
      item.status = node.connectedObject.status;
    }
  }
  if (node.url) {
    item.url = node.url.replace(new RegExp(process.env.WP_URL), "");
  }
  if (get(node, "connectedObject.slug")) {
    item.slug = node.connectedObject.slug;
  }
  if (
    get(node, "childItems") &&
    get(node, "childItems.nodes") &&
    node.childItems.nodes.length
  ) {
    item.childItems = node.childItems.nodes.map((obj) => cleanMenuNode(obj));
  }
  if (get(node, "connectedObject.frontPageIcon"))
    item.frontPageIcon = get(node, "connectedObject.frontPageIcon");
  if (get(node, "connectedObject.featuredImage.sourceUrl"))
    item.featuredImage = get(node, "connectedObject.featuredImage.sourceUrl");
  if (get(node, "connectedObject.target"))
    item.target = get(node, "connectedObject.target");
  return item;
};
