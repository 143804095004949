/* eslint-disable no-shadow */

import { get } from "lodash";
import { cleanMenuNode } from "~/apollo/dataUtils";

export const state = () => ({
  current: {},
  submenu: null,
  insidePage: {},
  nextPage: null,
  prevPage: null,
});

export const mutations = {
  setCurrentChapter(state, data) {
    state.current = data;
  },
  setCurrentChapterSubmenu(state, menuItems) {
    // console.log(JSON.stringify(menuItems));
    if (get(menuItems, "nodes[0].childItems.nodes")) {
      const cleanMenuItems = menuItems.nodes[0].childItems.nodes.map((obj) =>
        cleanMenuNode(obj)
      );
      state.submenu = cleanMenuItems;
    } else {
      state.submenu = null;
    }
  },
  setCurrentChapterImage(state, url) {
    state.current.imageUrl = url;
  },
  setInsidePage(state, page) {
    state.insidePage = page;
  },
  setPrevPage(state, slug) {
    state.prevPage = slug;
  },
  setNextPage(state, slug) {
    state.nextPage = slug;
  },
};

export const actions = {};
