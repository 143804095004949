/*eslint-disable*/
const sampleData = {
  "labels": [
    2005,
    2006,
    2007,
    2008,
    2009,
    2010,
    2011,
    2012,
    2013,
    2014,
    2015,
    2016
  ],
  "datasets": [
    {
      "backgroundColor": "rgba(0, 99, 132, 0.6)",
      "fill": false,
      "lineTension": 0,
      "borderCapStyle": "butt",
      "borderDash": [],
      "borderDashOffset": 0,
      "borderJoinStyle": "miter",
      "borderWidth": 2,
      "pointBorderWidth": 1,
      "pointHoverRadius": 3,
      "pointHoverBorderWidth": 2,
      "pointRadius": 3,
      "pointHitRadius": 10,
      "spanGaps": false,
      "label": "Buck Harvest",
      "yAxisId": "y-axis-0",
      "borderColor": "rgba(0, 99, 132, 0.6)",
      "pointHoverBorderColor": "rgba(0, 99, 132, 0.6)",
      "data": [
        109163,
        105819,
        106893,
        94945,
        93956,
        91832,
        92170,
        92860,
        75715,
        77129,
        83210,
        80036
      ]
    }
  ]
}

export default sampleData;
