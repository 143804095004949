<template>
  <main class="content-page">
    <div class="content-page__parent">
      <h2 class="content-page__parent-link" v-html="pageSlug" />
      <MenuButton />
    </div>
    <section class="content-page__body">
      <div class="content-page__wrapper">
        <a
          v-if="isLoggedIn && editLink"
          :href="editLink"
          target="_blank"
          class="content-page__button content-page__button--edit"
          >Edit</a
        >
        <slot />
      </div>
    </section>
  </main>
</template>

<script>
import { get } from "lodash";
import slugify from "slugify";
import MenuButton from "~/components/MobileMenu/MenuButton";
import GetChapterPage from "~/apollo/queries/getChapterPage";
import ChapterNavLinks from "./ChapterNavLinks";

function toggleOpen(event) {
  event.stopPropagation();
  const accordion = event.target.closest(".accordion");
  accordion.classList.toggle("accordion--open");
}

export default {
  name: "ContentPage",

  components: {
    MenuButton,
  },

  props: {
    pageSlug: String,
    parentLink: String,
  },
  head() {
    return {
      title: `White-tailed Deer Illinois: ${this.$props.pageSlug}`,
    };
  },
  computed: {
    editLink() {
      // const wp = process.env.WP_URL;
      // const pageId = get(
      //   this.$store.state.chapter,
      //   "insidePage.pageId",
      //   get(this.$store.state.chapter, "current.pageId")
      // );
      // if (wp && pageId) {
      //   return `${wp}/wp-admin/post.php?post=${pageId}&action=edit`;
      // }
      return false;
    },
  },

  mounted() {
    const accordions = document.getElementsByClassName("accordion__title");
    if (accordions.length > 0) {
      for (let accordion of accordions) {
        accordion.addEventListener("click", toggleOpen);
      }
    }
  },

  destroyed() {
    const accordions = document.getElementsByClassName("accordion__title");
    if (accordions.length > 0) {
      for (let accordion of accordions) {
        accordion.removeEventListener("click", toggleOpen);
      }
    }
  },

  methods: {
    isLoggedIn() {
      if (
        this.user.current &&
        this.user.current.username &&
        this.user.current.token
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
%content-padding {
  padding-left: 2rem;
  padding-right: 0.5rem;
}
%main-element-width {
  margin: auto;
  max-width: 850px;
  width: 100%;
}
.content-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: $font-sans;
  width: 100%;
  &__wrapper {
    position: relative;
  }
  &__parent {
    @extend %main-element-width;
    align-items: center;
    color: get-color("grey");
    display: inline-flex;
    justify-content: space-between;
    line-height: 1.5;
    margin: 1.5rem auto;
    min-height: 1.5em;
    padding: 0;
    padding-left: 1rem;
    @include tablet {
      padding-left: 3rem;
    }
    text-transform: uppercase;
    @include desktop {
      padding: 0;
    }
  }
  &__parent-link {
    font-family: $font-sans;
    font-size: 40px;
    font-weight: 400;
  }
  &__title {
    background-color: white;
    border-radius: 10px 10px 0 0;
    color: black;
    display: inline-block;
    font-family: $font-serif;
    font-size: $h1-font-size;
    // font-weight: bold;
    line-height: 1.5;
    margin-right: auto;
    max-width: calc(100% - 96px);
    padding: 0.3rem 0.5rem 0.3rem 0;
    text-transform: uppercase;
  }
  &__body {
    @extend %main-element-width;
    background-color: white;
    flex-grow: 1;
    line-height: 1.4;
    // overflow: hidden;
    margin: auto;
    padding: 2rem 1rem;
    @include tablet {
      padding: 2rem 3rem;
    }
  }
  &__html {
    @include clear;
    @include book;
    a {
      color: get-color(brown);
      font-weight: 400;
      cursor: pointer;
      text-decoration: none;
      transition: color 300ms;
      &.button {
        color: #363636;
        font-weight: 100;
        text-align: center;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    em a {
      font-family: $font-sans;
    }
  }
  @import "./scss/headings";
  &__paragraph,
  &__p {
    // font-weight: 100;
    margin: 0 0 0.8rem 0;
    padding-right: 0.5rem;
    &.is-style-no-space {
      margin-bottom: 0;
    }
  }
  &__video {
    width: 100%;
  }
  &__iframe {
    max-width: 100%;
  }
  &__button {
    @include button;
    &--edit {
      padding: 0.15rem 0.5rem;
      text-transform: uppercase;
      &:hover {
        background-color: $darkgreen;
        color: white;
      }
    }
  }
  @import "./scss/images";
  @import "./scss/aside";
  @import "./scss/blockquote";
  @import "./scss/lists";
  @import "./scss/table";
  @import "./scss/popout/popout";
  @import "./scss/popout/popout-date-frame";
  @import "./scss/popout/popout-image-component";
  @import "./scss/popout/popout-image";
  @import "./scss/popout/popout-quote";
  @import "./scss/accordion";
  @import "./scss/app-button";
  @import "./scss/wp-blocks";
}
</style>
