/* eslint-disable no-shadow */

export const state = () => ({
  searchTerm: null,
  searchResults: null,
  countOfResultsShown: null,
  countOfResults: null,
});

export const mutations = {
  setSearchTerm(state, data) {
    state.searchTerm = data;
  },
  setSearchResults(state, data) {
    state.searchResults = data;
  },
  setCountOfResultsShown(state, data) {
    state.countOfResultsShown = data;
  },
  setCountOfResults(state, data) {
    state.countOfResults = data;
  },
};

export const actions = {};
