/* eslint-disable no-shadow */

// import { get } from "lodash";

export const state = () => ({
  current: null,
});

export const mutations = {
  setUser(state, data) {
    state.current = data;
  },
};

export const actions = {};
