<template>
  <section class="app-container">
    <GovBanner />
    <mobile-menu />
    <StandardHeader />
    <nuxt />
    <Footer />
  </section>
</template>

<script>
import GovBanner from "~/components/GovBanner/GovBanner.vue";
import StandardHeader from "~/components/StandardHeader/StandardHeader.vue";
import Footer from "~/components/Footer/Footer.vue";
import MobileMenu from "~/components/MobileMenu/MobileMenu";

export default {
  name: "InsidePage",
  components: {
    GovBanner,
    StandardHeader,
    Footer,
    MobileMenu,
  },
};
</script>

<style lang="scss">
.app-container {
  @include app-container;
}
</style>
