<template>
  <section class="social-icons">
    <a
      class="social-icons__link"
      href="https://www.facebook.com/livingwithwildlifeillinois"
      target="_blank"
      aria-label="Living with Wildlife Facebook page"
    >
      <i class="social-icons__icon fab fa-facebook" />
    </a>
    <a
      class="social-icons__link"
      href="https://instagram.com/livingwithwildlifeillinois/"
      target="_blank"
      aria-label="Living with Wildlife Instagram page"
    >
      <i class="social-icons__icon fab fa-instagram" />
    </a>
  </section>
</template>

<script>
export default {
  name: "SocialIcons",
};
</script>

<style lang="scss">
.social-icons {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  padding: 0.5rem 2.5rem;
  &__icon {
    color: white;
  }
  &__link {
    & + & {
      margin-left: 1rem;
    }
  }
  @include tablet() {
    // float: right;
    justify-content: flex-end;
    order: 3;
  }
}
</style>
