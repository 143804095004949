const chartColors = {
  barlightblue: "rgba(0, 99, 132, 0.6)",
  lightblue: "rgba(0, 99, 132, 0.3)",
  blue: "rgba(0, 99, 132, 1)",
  barlightgreen: "rgba(0, 132, 99, 0.6)",
  lightgreen: "rgba(0, 132, 99, 0.3)",
  green: "rgba(0, 132, 99, 1)",
  lightred: "rgba(240, 99, 132, 0.3)",
  red: "rgba(240, 99, 132, 1)",
  barlightorange: "rgba(230,131,64,0.6)",
  lightorange: "rgba(230,131,64,0.3)",
  orange: "rgba(230,131,64,1)",
  lightbrown: "rgba(134, 86, 11, 0.3)",
  brown: "rgba(134, 86, 11, 1)",
  lightgray: "rgba(128, 128, 128, 0.3)",
  gray: "rgba(128, 128, 128, 1)",
  lightblack: "rgba(0,0,0,0.3)",
  black: "rgba(0,0,0,1)",

  totalBackground: "rgba(0,0,0,0.3)",
  totalBorder: "rgba(0,0,0,1)",

  buckBackground: "rgba(34, 74, 112, 0.3)",
  buckBorder: "rgba(34, 74, 112, 1)",

  doeBackground: "rgba(138, 57, 59, 0.3)",
  doeBorder: "rgba(138, 57, 59, 1)",

  antleredBackground: "rgba(57, 114, 167, 0.3)",
  antleredBorder: "rgba(57, 114, 167, 1)",

  antlerlessBackground: "rgba(230, 131, 64, 0.3)",
  antlerlessBorder: "rgba(230, 131, 64, 1)",

  buttonBackground: "rgba(77, 152, 149, 0.3)",
  buttonBorder: "rgba(77, 152, 149, 1)",

  doeFawnBackground: "rgba(197, 103, 113, 0.3)",
  doeFawnBorder: "rgba(197, 103, 113, 1)",

  yearlingBuckBackground: "rgba(163, 205, 221, 0.3)",
  yearlingBuckBorder: "rgba(163, 205, 221, 1)",

  adultBuckBackground: "rgba(0, 93, 100, 0.3)",
  adultBuckBorder: "rgba(0, 93, 100, 1)",

  adultDoeBackground: "rgba(184, 79, 83, 0.3)",
  adultDoeBorder: "rgba(184, 79, 83, 1)",

  // line0: "#0000003c",
  line1: "#3b6c4c",
  // line2: "#eb7008",
  line3: "#81459b",
  line4: "#8dc3b3",
  line5: "#efcf11",
  line6: "#a25926",
  line7: "#164475",
  line8: "#b7b4a2",
  line9: "#648250",
  line10: "#eb9e08",
  line11: "#d22c98",
  line12: "#678fba",
  line13: "#b9a635",
  line14: "#5d3021",
  line15: "#33a5b8",
  line16: "#d8d6cb",
  male: "#b3c7dc",
  female: "#e3cdbe",
};

export default chartColors;

// Create an infinite iterable of chart colors

function colorVal() {
  const colorKeys = Object.keys(chartColors).filter((k) => k.match(/^line/));
  let index = 0;
  return {
    reset() {
      index = 0;
    },
    next() {
      if (index >= colorKeys.length) {
        index = 0;
      }
      return {
        // eslint-disable-next-line no-plusplus
        value: chartColors[colorKeys[index++]],
        done: false,
      };
    },
  };
}

export const colorIterator = colorVal();
