export default function (req, res, next) {
  if (process.server) {
    const mongoose = require("mongoose");
    const Users = mongoose.model("users");

    try {
      console.log("auth serverMiddleware run");
      // console.log("req:", req.headers.cookie);
      const rawCookie = req.headers.cookie;
      const tokenMatch = rawCookie.match(/token=(%24.*);?/);
      if (tokenMatch) {
        const encodedToken = tokenMatch[1];
        const token = decodeURIComponent(encodedToken);
        Users.findOne({ token: token }, (err, result) => {
          if (err) {
            console.error("user not found");
            res.redirect("/admin/login");
          }
          if (!result) {
            console.log("user token not found");
            res.redirect("/admin/login");
          }
          console.log("user result:", result);
          next();
        });
      }
    } catch (e) {
      console.error("server middleware error", e);
      res.redirect("/admin/login");
    }
  }
  next();
}
