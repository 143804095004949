<template>
  <nav
    class="mobile-menu"
    role="navigation"
    :class="{ 'mobile-menu--open': menuOpen }"
  >
    <section
      v-for="section in sections"
      :key="section.id"
      class="mobile-menu__section"
    >
      <h1 class="mobile-menu__heading" v-html="section.label" />
      <ul class="mobile-menu__list">
        <li
          v-for="item in section.childItems"
          :key="item.id"
          class="mobile-menu__item"
        >
          <a
            :href="item.url"
            class="mobile-menu__link"
            :target="item.target"
            v-html="item.label"
          />
        </li>
      </ul>
    </section>
  </nav>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  computed: {
    sections() {
      return this.$store.state.menu.siteMenuData;
    },
    menuOpen() {
      return this.$store.state.mobileMenu.menuOpen;
    },
  },
  watch: {
    menuOpen(val) {
      const body = document.getElementsByTagName("body")[0];
      const openClass = "body--menu-open";
      if (val) {
        body.classList.add(openClass);
      } else {
        body.classList.remove(openClass);
      }
    },
  },
  created() {
    this.$store.commit("mobileMenu/setMenuOpen", false);
  },
};
</script>

<style lang="scss">
.mobile-menu {
  $transition-time: 0.4s;
  background: rgba(255, 255, 255, 0.95);
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin: 0;
  position: fixed;
  transform: translateY(100vh);
  transition: all $transition-time ease-out;
  visibility: hidden;
  width: 100%;
  z-index: 1002;
  @include desktop {
    display: none;
  }
  &--open {
    transform: translateY(0);
    transition: transform $transition-time ease-in;
    visibility: visible;
  }
  &__section {
    width: 100%;
  }
  &__heading {
    background-color: get-color("green", 400);
    border-radius: 0;
    color: white;
    display: inline-block;
    font-family: $font-serif;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    max-width: 100%;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    text-transform: uppercase;
    width: 100%;
  }
  &__list {
    font-family: $font-sans;
    font-size: 1.2rem;
    list-style-type: none;
    margin: 0.5rem 1rem;
    padding: 0;
    position: relative;
  }
  &__item {
    line-height: 1.5;
    position: relative;
  }
  &__link {
    color: currentColor;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
