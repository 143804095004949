<template>
  <div class="gov-banner">
    <section class="gov-banner__text gov-banner__text-left">
      <p>
        <a
          class="gov-banner__link"
          href="https://www.dnr.illinois.gov/"
          target="_blank"
        >
          <span class="gov-banner__text-first">ILLINOIS</span>
          <span class="gov-banner__text-second">DEPARTMENT</span>
          <span class="gov-banner__text-third">OF NATURAL</span>
          RESOURCES
        </a>
      </p>
    </section>
    <section>
      <a href="https://www.dnr.illinois.gov/" target="_blank">
        <svg
          id="Layer_1"
          class="gov-banner__logo"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 156 296"
          style="enable-background: new 0 0 156 296"
          xml:space="preserve"
        >
          <rect x="0.8" y="25.6" width="2.5" height="198" />
          <rect x="152.7" y="25.6" width="2.6" height="198" />
          <path
            d="M122,75.2c0.2,0.1,0.5,0,0.7,0c-0.1,0.2,0.1,0.2,0.3,0.2l0.2,0l0,0.2c0.2,0.1,0.4,0,0.7,0l0,0.2
          c0.2,0.1,0.5,0,0.8,0l0,0.2c0.2,0.1,0.5,0,0.7,0l0,0.4c0.3,0.1,0.7,0,1-0.1c-0.1,0.2,0.2,0.2,0.3,0.2l0.2,0
          c-0.1,0.2,0.2,0.2,0.3,0.2l0.2,0c-0.1,0.2,0.1,0.3,0.3,0.2l0.2,0l0,0.4c0.2,0.1,0.5,0,0.7,0c-0.1,0.2,0.1,0.3,0.3,0.2l0.2,0
          c0,0-0.1,0.2,0,0.2l0.2,0c-0.1,0.2,0.1,0.2,0.3,0.2l0.1,0c-0.1,0.2,0.2,0.3,0.3,0.2l0.2,0c0,0.1,0,0.2,0.1,0.2l0.2,0
          c-0.1,0.2,0.2,0.3,0.3,0.2l0.1,0l0,0.1c0.2,0.2,0.5,0.1,0.8,0.1c-0.1,0.2,0.2,0.2,0.3,0.2l0.2,0l0,0.2c0.3,0.1,0.7,0.1,1,0.1l0,0.2
          c0.6,0.1,1.4,0,2.1-0.1l0-0.2c0.2,0,0.5,0.1,0.8-0.2l0-0.2c0.2,0,0.5,0.1,0.7-0.1l0-0.1c0.4-0.1,1.2,0.2,1.2-0.3
          c0.5-0.1,1.3,0.2,1.5-0.3c0.2,0,0.5,0,0.7-0.1l0,0.1c0.2,0,0.5,0,0.8-0.2l0-0.2l1.7-0.1l0,0.4c-0.1,0-0.3-0.1-0.5,0.1l0,0.2
          c-0.1,0-0.3-0.1-0.4,0.1l0,0.1c-0.2,0.1-0.6,0-0.8,0.2l0,0.1c-0.2,0.1-0.4,0-0.5,0.1l0,0.2c-0.2,0-0.5,0-0.8,0.2l0,0.2
          c-0.2,0-0.4,0-0.5,0.1l0,0.2c-0.2,0-0.5,0-0.8,0.2l0,0.1c-0.2,0-0.3,0-0.5,0.1l0,0.1l-0.5,0l0,0.2l-0.2,0l0,0.1l-0.2,0l0,0.2l-0.5,0
          l0,0.3c-0.1,0-0.2,0-0.2,0.1l0,0.2l-0.2,0l0,0.2l-0.2,0l0,0.2l-0.2,0l0,0.3l-0.5,0l0,0.2c-0.2,0-0.4,0-0.5,0.1l0,0.2
          c-0.2,0-0.5-0.1-0.8,0l0,0.2l-2.6,0.1c0.1-0.2-0.2-0.2-0.3-0.2l-0.2,0c0-0.1,0-0.2-0.1-0.2l-0.2,0c0-0.1,0-0.2-0.1-0.2l-0.2,0
          c0-0.1,0-0.2-0.1-0.2l-0.1,0c0-0.1,0-0.2-0.1-0.2l-0.1,0l0-0.2l-0.2,0l0-0.2l-0.5,0l0-0.2l-0.2,0l0-0.2l-0.2,0c0-0.1,0-0.2-0.1-0.2
          l-0.2,0l0-0.2l-0.3,0l0-0.2l-0.2,0c0.1-0.2-0.2-0.2-0.3-0.2l-0.2,0c0-0.1,0-0.2-0.1-0.2l-0.2,0l0-0.2l-0.2,0
          c0.1-0.2-0.1-0.2-0.3-0.2l-0.2,0l0-0.2l-0.2,0c0,0,0-0.1-0.1-0.2l-0.2,0c0-0.1,0.1-0.2,0-0.2l-0.2,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.2,0
          c0-0.1,0.1-0.2,0-0.2l-0.1,0c-0.1-0.1,0-0.2-0.1-0.2l-0.1,0c-0.1-0.1,0-0.1-0.1-0.2l-0.2,0c0.1-0.2-0.2-0.3-0.3-0.2l-0.2,0
          c0.1-0.2-0.2-0.2-0.3-0.2l-0.1,0l0-0.2l-0.2,0c0.1-0.2-0.2-0.2-0.3-0.2l-0.2,0l0-0.2c-0.2-0.1-0.5,0-0.7,0c0.1-0.2-0.2-0.3-0.3-0.2
          l-0.2,0c0.1-0.2-0.2-0.2-0.3-0.2l-0.2,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.2-0.5l-0.5-0.4c0.2,0-0.2-0.4-0.3-0.8l0.9-0.4L122,75.2
          L122,75.2z"
          />
          <polygon points="71.5,217.4 7.2,217.4 7.2,216.7 71.9,216.7 " />
          <polygon points="105.5,183.2 7.2,183.2 7.2,182.5 107.5,182.5 " />
          <rect x="7.2" y="177.7" width="141.6" height="0.7" />
          <polygon points="97.1,189 7.2,189 7.2,188.3 98,188.3 " />
          <polygon points="91.2,195.6 7.2,195.5 7.2,194.9 91.9,194.9 " />
          <polygon points="87.1,201.5 7.2,201.4 7.2,200.8 87.8,200.8 " />
          <polygon points="82.7,207.4 7.2,207.4 7.2,206.7 83.1,206.7 " />
          <polygon points="77.3,212.5 7.2,212.5 7.2,211.8 78.6,211.8 " />
          <polygon points="94.7,222.2 148.8,222.2 148.8,223.2 93.9,223.2 " />
          <g>
            <path
              d="M8.9,234.2L8.9,234.2c0-3.6-1.2-4.4-3.6-4.4c-0.5,0-0.8,0-1.2,0.1v5.9c0,2.5,0,2.8,1.2,2.8
            c1.7,0,3.1-0.3,3.5-2.9C8.9,235.2,8.9,234.7,8.9,234.2z M11,237.6c-1.3,2.2-3.6,2.6-6,2.6H1.7c-0.4,0-0.7,0.1-1,0.1
            c0.3-0.9,0.5-1.4,0.5-5.1c0-6-0.3-6.4-0.5-7.1c0.4,0,0.7,0.1,1,0.1H5c2.6,0,4.8,0.1,6,2.5c0.6,1.2,0.8,2.4,0.8,3.5
            C11.8,235.4,11.6,236.6,11,237.6z"
            />
            <path
              d="M17.5,233.2c0.9,0,1.8,0,2.6-0.1c0.7-0.1,1-0.2,1.3-0.3c-0.1,0.4-0.1,0.8-0.1,1.2s0,0.8,0.1,1.1
            c-0.2-0.1-0.3-0.1-0.5-0.2c-0.4-0.1-1.2-0.2-2.1-0.2h-1.4v2c0,1.7,0.2,1.7,1.7,1.7c1.9,0,2.7-0.1,3.5-0.9c-0.3,1.2-0.3,1.5-0.4,2.6
            c-1.2-0.1-2.4-0.2-4.3-0.2c-1.5,0-2.5,0.1-3.7,0.2c0.2-0.4,0.4-0.7,0.4-1.3c0.1-0.7,0.1-3.9,0.1-5.2c0-4.7-0.5-5.2-0.7-5.6
            c0.7,0.1,1.5,0.2,3.8,0.2c1.4,0,2.9,0,4.4-0.2c-0.1,1.1-0.1,1.3-0.1,2.3c-0.5-0.4-0.7-0.5-2.3-0.5h-2.3
            C17.5,229.8,17.5,233.2,17.5,233.2z"
            />
            <path
              d="M31.8,232.1L31.8,232.1c0-1-0.3-1.6-0.6-1.8c-0.5-0.5-1.3-0.5-1.7-0.5c-0.5,0-0.9,0-1.3,0.1v4.5
            c0.3,0.1,0.7,0.1,1.3,0.1C30.9,234.5,31.8,233.8,31.8,232.1z M33.6,235c-0.3,0.4-1.1,1.2-4,1.2c-0.4,0-0.9-0.1-1.4-0.1
            c0,0.7,0,2.4,0.1,3s0.2,0.8,0.4,1.1c-0.7-0.1-1.3-0.1-1.9-0.1c-0.7,0-1.2,0.1-1.9,0.1c0.2-0.4,0.3-0.8,0.4-2v-3.4
            c0-1.3,0-4.3-0.2-5.5c-0.2-0.8-0.3-1-0.5-1.3c0.6,0.1,2.9,0.1,5.2,0c1.6-0.1,3.1,0.1,3.9,1c1,1.1,1,2.4,1,2.9S34.7,233.9,33.6,235z
            "
            />
            <path
              d="M38.9,235.2L38.9,235.2l3,0c-0.3-1.3-0.4-1.7-1.5-5.4c-0.1,0.3-0.1,0.6-0.2,0.8L38.9,235.2z M38.3,237.2
            c-0.6,2.2-0.5,2.8-0.5,2.9c-0.5,0-1.1-0.1-1.6-0.1c-0.4,0-0.9,0-1.4,0.1c0.5-1,0.7-1.5,1.1-2.8c0.5-1.5,1.2-3.3,1.8-5.4
            c0.2-0.8,0.9-3.2,1.1-4c0.7,0,1.3,0.1,1.9,0.1c0.7,0,1.3,0,2-0.1c0.3,1.3,0.4,1.8,1.1,4.2c1.4,4.7,1.6,5.4,2.7,8
            c-0.7,0-1.3-0.1-1.9-0.1c-0.6,0-1.1,0-1.7,0.1c0-0.1,0-0.3-0.6-2.9C42.3,237.2,38.3,237.2,38.3,237.2z"
            />
            <path
              d="M54.9,231.9c0-0.9-0.3-1.5-0.4-1.7c-0.5-0.5-1.3-0.5-3.1-0.5c-0.1,2.2-0.1,2.4-0.1,4.5h0.8
            C53.3,234.2,54.9,234.1,54.9,231.9z M57.3,233.4c-0.6,1-1.5,1.4-2.1,1.6c0.3,0.4,0.5,0.8,0.8,1.2c1.1,1.7,1.9,2.9,3,3.9
            c-0.7,0-1.4-0.1-2.1-0.1c-0.6,0-1.3,0-1.9,0.1c-0.1-0.3-0.2-0.5-0.3-0.8c-0.4-0.9-1.5-2.5-2.2-3.6h-1.4c0,1,0,1.9,0.1,2.9
            c0.1,0.7,0.1,1,0.4,1.5c-0.6-0.1-1.2-0.1-1.8-0.1s-1.2,0-1.8,0.1c0.1-0.2,0.3-0.4,0.3-2.1s-0.1-6.7-0.1-7.4
            c-0.1-1.9-0.3-2.4-0.5-2.7c0.2,0,0.5,0.1,0.8,0.1c0.4,0.1,0.8,0.1,1.4,0.1h3.4c1.2,0,2.7,0.1,3.7,1.1c0.6,0.6,0.9,1.5,0.9,2.4
            C57.9,232.2,57.8,233,57.3,233.4z"
            />
            <path
              d="M63.3,229.9h-1.8c-1.4,0-1.8,0.3-2.2,0.6c0-0.5,0.1-0.8,0.1-1.3c0-0.4,0-0.8-0.1-1.2c0.9,0.1,2.9,0.2,5.3,0.2
            c2,0,3.9-0.1,5.5-0.2c0,0.3-0.1,0.7-0.1,1.2c0,0.4,0,0.9,0.1,1.3c-0.6-0.5-1-0.6-2.3-0.6h-1.7v6c0,1,0,1.7,0.1,2.1
            c0.1,1.1,0.2,1.6,0.5,2.1c-0.6-0.1-1.2-0.1-1.9-0.1s-1.4,0.1-2.1,0.1c0.2-0.3,0.4-0.5,0.5-1.1c0.1-0.9,0.1-3.6,0.1-4.1
            C63.4,233.3,63.4,231.6,63.3,229.9z"
            />
            <path
              d="M82.9,230.9v-0.5c-0.4,1.2-0.4,1.5-0.9,2.7l-1.2,3c-1.1,2.7-1.2,2.9-1.5,4.1c-0.2,0-0.6-0.1-0.9-0.1
            s-0.5,0.1-0.8,0.1c-0.2-0.7-0.3-1.1-0.7-2.3c-0.6-1.6-1.1-3.2-1.7-4.8c-0.3-0.8-0.5-1.5-0.8-2.3v0.6c0,2.1-0.2,5-0.2,6.8
            c0,1.1,0.1,1.5,0.2,2c-0.5-0.1-1-0.1-1.5-0.1s-0.9,0.1-1.4,0.1c0.2-0.6,0.4-1.4,0.5-2.7c0.1-1.2,0.3-4.8,0.3-7c0-1.6-0.1-2-0.3-2.5
            c0.7,0.1,1.5,0.1,2.2,0.1c0.8,0,1.5-0.1,2.3-0.1c0.3,1.2,0.5,2.1,1.6,5.4c0.2,0.7,0.6,2.2,0.7,2.5c0.2-0.4,0.7-2,1-2.4
            c1.4-3.3,1.5-3.9,1.8-5.5c0.7,0.1,1.5,0.1,2.2,0.1s1.4-0.1,2.1-0.1c-0.4,0.4-0.7,0.7-0.7,8.1c0,2.3,0.1,2.9,0.5,4.1
            c-0.6-0.1-1.1-0.1-1.7-0.1s-1.2,0.1-1.8,0.1c0.2-0.4,0.3-0.8,0.4-3.8L82.9,230.9L82.9,230.9z"
            />
            <path
              d="M92,233.1c0.9,0,1.8,0,2.6-0.1c0.7-0.1,1-0.2,1.3-0.3c-0.1,0.4-0.1,0.8-0.1,1.2s0,0.8,0.1,1.1
            c-0.2-0.1-0.3-0.1-0.5-0.2c-0.4-0.1-1.2-0.2-2.1-0.2h-1.4v2c0,1.7,0.2,1.7,1.7,1.7c1.9,0,2.7-0.1,3.5-0.9c-0.3,1.2-0.3,1.5-0.4,2.6
            c-1.2-0.1-2.4-0.2-4.3-0.2c-1.5,0-2.5,0.1-3.7,0.2c0.2-0.4,0.4-0.7,0.4-1.3c0.1-0.7,0.1-3.9,0.1-5.2c0-4.7-0.5-5.2-0.7-5.6
            c0.7,0.1,1.5,0.2,3.8,0.2c1.4,0,2.9,0,4.4-0.2c-0.1,1.1-0.1,1.3-0.1,2.3c-0.5-0.4-0.7-0.5-2.3-0.5H92V233.1z"
            />
            <path
              d="M110.2,236.5c0,1.4,0,2.3,0.1,3.6c-0.4-0.1-0.9-0.1-1.3-0.1s-0.9,0.1-1.4,0.1c-0.3-0.6-0.6-1.1-1.3-2.3
            c-0.7-1.2-1.5-2.3-2.2-3.5c-1.5-2.3-1.7-2.6-2.2-3.5v0.6c0.1,0.7,0,4.4,0.1,6.2c0.1,1.7,0.2,2.1,0.4,2.4c-0.6-0.1-1-0.1-1.5-0.1
            s-1.1,0.1-1.6,0.1c0.1-0.3,0.3-0.6,0.3-1.3c0.2-1.2,0.2-4.8,0.2-5.5c0-0.8,0-1.7-0.1-2.7c-0.1-2.1-0.4-2.4-0.6-2.7
            c0.7,0.1,1.4,0.1,2,0.1c0.7,0,1.3-0.1,2-0.1c0.8,1.7,2,3.5,3.1,5.2c1.1,1.8,1.8,2.9,2,3.4c-0.1-0.4-0.2-0.9-0.2-2.1
            c0-2.5,0-3.7-0.1-4.8s-0.3-1.4-0.5-1.7c0.6,0.1,1.1,0.1,1.7,0.1c0.5,0,1-0.1,1.5-0.1c-0.2,0.5-0.4,0.8-0.5,3.3
            C110.2,232.3,110.2,235.6,110.2,236.5L110.2,236.5z"
            />
            <path
              d="M116.1,229.9h-1.8c-1.4,0-1.8,0.3-2.2,0.6c0-0.5,0.1-0.8,0.1-1.3c0-0.4,0-0.8-0.1-1.2c0.9,0.1,2.9,0.2,5.3,0.2
            c2,0,3.9-0.1,5.5-0.2c0,0.3-0.1,0.7-0.1,1.2c0,0.4,0,0.9,0.1,1.3c-0.6-0.5-1-0.6-2.3-0.6h-1.7v6c0,1,0,1.7,0.1,2.1
            c0.1,1.1,0.2,1.6,0.5,2.1c-0.6-0.1-1.2-0.1-1.9-0.1s-1.4,0.1-2.1,0.1c0.2-0.3,0.4-0.5,0.5-1.1c0.1-0.9,0.1-3.6,0.1-4.1
            C116.2,233.3,116.2,231.6,116.1,229.9z"
            />
            <path
              d="M142,234.2L142,234.2c0-1-0.1-2.4-0.7-3.3c-0.4-1-1.2-1.4-2.3-1.4c-2.6,0-2.8,2.6-2.8,4.6
            c0,2.6,0.6,4.6,2.9,4.6c0.4,0,1.7,0,2.4-1.5C141.9,236.3,142,234.9,142,234.2z M143.3,229.6c1.4,1.5,1.6,3.5,1.6,4.7
            c0,0.6,0,2.9-1.6,4.5c-0.9,0.8-2.3,1.5-4.1,1.5c-0.9,0-1.9-0.2-2.9-0.7c-1.9-1-2.9-2.7-2.9-5.5c0-4.4,2.8-6.2,5.7-6.2
            C140.9,227.9,142.3,228.6,143.3,229.6z"
            />
            <path
              d="M150.6,233.4c1.8,0,2.5,0,3.2-0.1s0.8-0.2,1.1-0.4c-0.1,0.6-0.1,1.1-0.1,1.5s0,0.7,0.1,1.1
            c-0.4-0.2-0.7-0.3-1.5-0.4c-0.6-0.1-1.3-0.1-2.7,0v3.3c0.1,0.9,0.2,1.2,0.4,1.7c-0.6-0.1-1.1-0.2-1.7-0.2c-0.6,0-1.2,0.1-1.8,0.2
            c0.2-0.2,0.2-0.4,0.3-0.7c0.2-0.9,0.2-2.8,0.2-5.9c0-5.1-0.5-5.3-0.7-5.6c1.6,0.1,3.2,0.2,4.9,0.2c1.8,0,2.4-0.1,3.5-0.2
            c-0.1,0.6-0.2,0.9-0.2,1.5v0.9c-0.6-0.4-1-0.5-2.4-0.5h-2.4v3.6L150.6,233.4z"
            />
          </g>
          <g>
            <path
              d="M17.5,264.8c0,2.2,0.1,3.5,0.2,5.5c-0.7-0.1-1.4-0.2-2.1-0.2c-0.7,0-1.4,0.1-2.2,0.2c-0.5-1-0.9-1.7-2-3.5
            s-2.3-3.6-3.4-5.3c-2.4-3.6-2.6-4-3.4-5.4l0.1,0.5l0.1,0.5c0.2,1,0,6.7,0.2,9.5c0.1,2.5,0.3,3.2,0.6,3.7c-0.9-0.1-1.6-0.2-2.3-0.2
            c-0.8,0-1.6,0.1-2.4,0.2c0.2-0.5,0.4-1,0.5-1.9c0.2-1.9,0.3-7.4,0.3-8.5c0-1.2,0-2.6-0.1-4.1c-0.1-3.2-0.6-3.6-0.9-4.1
            c1,0.1,2.1,0.2,3.1,0.2s2-0.1,3-0.2c1.3,2.6,3,5.4,4.7,8c1.7,2.7,2.7,4.4,3.1,5.3c-0.1-0.6-0.2-1.3-0.2-3.2c0-3.9,0-5.6-0.1-7.3
            s-0.4-2.2-0.7-2.7c0.9,0.1,1.7,0.2,2.6,0.2c0.8,0,1.5-0.1,2.3-0.2c-0.4,0.7-0.6,1.2-0.8,5.1C17.5,258.4,17.5,263.5,17.5,264.8z"
            />
            <path
              d="M31,262.9L31,262.9l4.6,0.1c-0.5-2-0.7-2.6-2.3-8.3c-0.1,0.5-0.2,0.8-0.3,1.2L31,262.9z M36.2,265.9l-5.7,0.1
            c-0.9,3.5-0.8,4.3-0.8,4.5c-0.9-0.1-1.7-0.2-2.5-0.2c-0.7,0-1.5,0.1-2.2,0.2c0.6-1.6,0.9-2.4,1.6-4.3c0.8-2.3,1.7-5.1,2.7-8.3
            c0.3-1.1,1.5-4.9,1.7-6.1c0.9,0.1,1.8,0.2,2.8,0.2s2-0.1,3-0.2c0.4,2,0.6,2.8,1.7,6.4c2.1,7.1,2.4,8.3,4.1,12.2
            c-1.1-0.1-1.9-0.2-2.9-0.2c-0.9,0-1.7,0.1-2.6,0.2C37.1,270.2,37.2,269.8,36.2,265.9L36.2,265.9z"
            />
            <path
              d="M52.2,254.7h-2.7c-2.2,0-2.7,0.4-3.4,0.9c0.1-0.7,0.1-1.3,0.1-1.9c0-0.6,0-1.2-0.1-1.9
            c1.4,0.1,4.5,0.3,8.1,0.3c3,0,5.9-0.1,8.5-0.3c-0.1,0.5-0.1,1.1-0.1,1.8c0,0.6,0.1,1.4,0.1,2.1c-0.9-0.7-1.6-0.9-3.5-0.9h-2.6
            c0,2.3,0,4.6-0.1,6.9v2.2c0,1.6,0,2.6,0.1,3.2c0.1,1.7,0.3,2.4,0.8,3.3c-0.9-0.1-1.9-0.2-2.8-0.2c-1.1,0-2.1,0.1-3.2,0.2
            c0.3-0.4,0.6-0.8,0.7-1.7c0.2-1.4,0.2-5.5,0.2-6.3C52.2,259.8,52.2,257.3,52.2,254.7L52.2,254.7z"
            />
            <path
              d="M82,262.5v-6c0-4.3-0.5-4.5-0.8-4.7c0.9,0.1,1.7,0.1,2.5,0.1s1.7-0.1,2.6-0.1c-0.3,0.3-0.6,0.5-0.7,1.5
            c-0.2,1.1-0.2,3.6-0.2,4.9v4.9c0,2-0.1,3.6-0.5,4.7c-1.1,2.4-4.3,2.8-7.1,2.8c-1.9,0-3.7-0.2-5.1-0.9c-2.5-1.2-2.6-3.3-2.6-5.5
            v-5.1c0-1.2,0-4.7-0.1-5.7c-0.1-0.9-0.4-1.2-0.7-1.6c1.2,0.1,2.3,0.1,3.4,0.1c0.8,0,1.6,0,2.5-0.1c-0.2,0.4-0.8,0.8-0.8,4.3v7.7
            c0,1.9,0.2,3.7,3.9,3.7C81.5,267.5,82,266,82,262.5L82,262.5z"
            />
            <path
              d="M105.4,257.5L105.4,257.5c0-1.3-0.4-2.1-0.8-2.5c-0.8-0.8-2-0.8-4.7-0.8c-0.1,3.4-0.1,3.7-0.1,6.9h1.3
            C102.9,261.1,105.4,260.9,105.4,257.5z M110.1,257.2c0,0.9-0.2,2-0.7,3c-0.9,1.6-2.3,2.1-3.2,2.5l1.2,1.8c1.7,2.6,2.9,4.4,4.6,6
            c-1-0.1-2.1-0.2-3.1-0.2s-2,0.1-3,0.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.7-1.4-2.3-3.8-3.4-5.5h-2.1c0,1.5,0,2.8,0.1,4.4
            c0.1,1.1,0.2,1.5,0.6,2.3c-0.9,0-1.8-0.1-2.7-0.1c-0.9,0-1.8,0-2.7,0.1c0.2-0.2,0.5-0.7,0.5-3.2c0-2.6-0.1-10.2-0.2-11.3
            c-0.1-2.9-0.5-3.6-0.8-4.1c0.3,0,0.7,0.1,1.2,0.1c0.6,0.1,1.2,0.1,2.1,0.1c0.6,0,1.7-0.1,2.7-0.1c1-0.1,1.9-0.1,2.4-0.1
            c1.8,0,4.1,0.1,5.6,1.6C109.7,254.4,110.1,255.8,110.1,257.2L110.1,257.2z"
            />
            <path
              d="M123.7,262.9L123.7,262.9l4.6,0.1c-0.5-2-0.7-2.6-2.3-8.3c-0.1,0.5-0.2,0.8-0.3,1.2L123.7,262.9z M129,265.9
            l-5.7,0.1c-0.9,3.5-0.8,4.3-0.8,4.5c-0.9-0.1-1.7-0.2-2.5-0.2c-0.7,0-1.5,0.1-2.2,0.2c0.6-1.6,0.9-2.4,1.6-4.3
            c0.8-2.3,1.7-5.1,2.7-8.3c0.3-1.1,1.5-4.9,1.7-6.1c0.9,0.1,1.8,0.2,2.8,0.2s2-0.1,3-0.2c0.4,2,0.6,2.8,1.7,6.4
            c2.1,7.1,2.4,8.3,4.1,12.2c-1.1-0.1-1.9-0.2-2.9-0.2c-0.9,0-1.7,0.1-2.6,0.2C129.9,270.2,130,269.8,129,265.9L129,265.9z"
            />
            <path
              d="M147.1,265.8c0.1,1.7,0.7,1.9,2.5,1.9c3.1,0,4.2-0.5,5.2-1c-0.5,1.5-0.6,1.9-0.8,3.6c-2.3-0.1-3.8-0.2-7.4-0.2
            c-1.9,0-2.8,0.1-4.7,0.2c0.3-0.4,0.5-0.8,0.7-1.7c0.1-0.8,0.1-4.5,0.1-5.5c0-2.6-0.2-7.7-0.3-9.3c-0.1-1.1-0.4-1.5-0.8-2.2
            c0.7,0.1,1.5,0.2,2.8,0.2c1.1,0,2.1-0.1,3.2-0.2c-0.3,0.7-0.4,1.2-0.6,2.2c-0.2,1.4-0.2,3.2-0.2,4.8C147,260,147,264.5,147.1,265.8
            z"
            />
            <path
              d="M11,280.5c0-1.3-0.4-2.2-0.8-2.5c-0.8-0.8-2-0.8-4.7-0.8c-0.1,3.4-0.1,3.7-0.1,6.9h1.3
            C8.5,284.1,11,283.9,11,280.5z M15.7,280.2c0,0.9-0.2,2-0.7,3c-0.9,1.6-2.3,2.1-3.2,2.5l1.2,1.8c1.7,2.6,2.9,4.4,4.6,6
            c-1-0.1-2.1-0.2-3.1-0.2s-2,0.1-3,0.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.7-1.4-2.3-3.8-3.4-5.5H5.5c0,1.5,0,2.8,0.1,4.4
            c0.1,1.1,0.2,1.5,0.6,2.3c-0.9,0-1.8-0.1-2.7-0.1s-1.8,0-2.7,0.1c0.2-0.2,0.5-0.7,0.5-3.2c0-2.6-0.1-10.2-0.2-11.3
            c-0.1-2.9-0.5-3.6-0.8-4.1c0.3,0,0.7,0.1,1.2,0.1c0.6,0.1,1.2,0.1,2.1,0.1c0.6,0,1.7-0.1,2.7-0.1c1-0.1,1.9-0.1,2.4-0.1
            c1.8,0,4.1,0.1,5.6,1.6C15.3,277.4,15.7,278.8,15.7,280.2L15.7,280.2z"
            />
            <path
              d="M24.8,282.5c1.4,0,2.7,0,4-0.1c1.1-0.1,1.5-0.2,2-0.4c-0.1,0.7-0.2,1.2-0.2,1.8c0,0.6,0.1,1.2,0.2,1.7
            c-0.3-0.1-0.5-0.2-0.7-0.3c-0.6-0.2-1.8-0.3-3.3-0.3h-2.1v3c0,2.6,0.3,2.6,2.7,2.6c2.9,0,4.2-0.2,5.4-1.3c-0.4,1.9-0.5,2.3-0.7,3.9
            c-1.9-0.1-3.6-0.2-6.6-0.2c-2.2,0-3.8,0.1-5.7,0.2c0.4-0.6,0.5-1,0.6-2.1s0.1-6,0.1-8c0-7.3-0.7-7.9-1.1-8.6c1,0.1,2.3,0.3,5.9,0.3
            c2.2,0,4.4,0,6.7-0.3c-0.2,1.6-0.2,2.1-0.2,3.5c-0.8-0.5-1.1-0.7-3.5-0.7h-3.6v5.3H24.8z"
            />
            <path
              d="M45.3,292.5c-1.4,0.7-3.5,1-4.9,1c-2.2,0-4.2-0.5-5.4-0.8c0.5-1.4,0.6-1.7,1-3.5c0.4,0.3,0.7,0.6,1.2,0.8
            c0.9,0.4,2.2,0.7,3.2,0.7c3,0,3.1-1.9,3.1-2.2c0-1.5-1.4-2.2-3-2.8c-0.4-0.2-0.9-0.3-1.3-0.5c-1.5-0.7-3.5-2.1-3.5-4.9
            c0-1.1,0.3-2.5,1.5-3.7c1.7-1.7,4-1.9,6-1.9c2.5,0,3.7,0.4,4.7,0.7c-0.5,1.4-0.6,1.7-1,3.3c-1.5-1.2-3.1-1.3-3.8-1.3
            c-1.8,0-2.9,1-2.9,2.2c0,1.4,1.4,2.1,1.8,2.4c0.9,0.5,1.8,0.8,2.6,1.2c0.9,0.4,3.4,1.6,3.4,4.8C48.2,290.2,46.8,291.8,45.3,292.5
            L45.3,292.5z"
            />
            <path
              d="M63.9,284.2c0-1.5-0.2-3.7-0.8-5.1c-0.7-1.6-2-2.2-3.6-2.2c-4,0-4.3,4.1-4.3,7.1c0,4,0.8,7,4.4,7
            c0.6,0,2.6,0,3.6-2.3C63.8,287.4,63.9,285.3,63.9,284.2z M65.8,276.9c2.2,2.2,2.5,5.3,2.5,7.2c0,0.9,0,4.4-2.5,6.9
            c-1.3,1.3-3.5,2.4-6.3,2.4c-1.3,0-2.9-0.2-4.4-1c-2.8-1.5-4.4-4.2-4.4-8.4c0-6.7,4.3-9.5,8.7-9.5C62.2,274.5,64.4,275.7,65.8,276.9
            z"
            />
            <path
              d="M83.1,285.5v-6c0-4.3-0.5-4.5-0.8-4.7c0.9,0.1,1.7,0.1,2.5,0.1c0.9,0,1.7-0.1,2.6-0.1
            c-0.3,0.3-0.6,0.5-0.7,1.5c-0.2,1.1-0.2,3.6-0.2,4.9v4.9c0,2-0.1,3.6-0.5,4.7c-1.1,2.4-4.3,2.8-7.1,2.8c-1.9,0-3.7-0.2-5.1-0.9
            c-2.5-1.2-2.6-3.3-2.6-5.5V282c0-1.2,0-4.7-0.1-5.7c-0.1-0.9-0.4-1.2-0.7-1.6c1.2,0.1,2.3,0.1,3.4,0.1c0.8,0,1.6,0,2.5-0.1
            c-0.2,0.4-0.8,0.8-0.8,4.3v7.7c0,1.9,0.2,3.7,3.9,3.7C82.6,290.4,83.1,288.9,83.1,285.5L83.1,285.5z"
            />
            <path
              d="M101.4,280.5c0-1.3-0.4-2.2-0.8-2.5c-0.8-0.8-2-0.8-4.7-0.8c-0.1,3.4-0.1,3.7-0.1,6.9h1.3
            C98.9,284.1,101.4,283.9,101.4,280.5z M106.1,280.2c0,0.9-0.2,2-0.7,3c-0.9,1.6-2.3,2.1-3.2,2.5l1.2,1.8c1.7,2.6,2.9,4.4,4.6,6
            c-1-0.1-2.1-0.2-3.1-0.2s-2,0.1-3,0.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.7-1.4-2.3-3.8-3.4-5.5h-2.1c0,1.5,0,2.8,0.1,4.4
            c0.1,1.1,0.2,1.5,0.6,2.3c-0.9,0-1.8-0.1-2.7-0.1c-0.9,0-1.8,0-2.7,0.1c0.2-0.2,0.5-0.7,0.5-3.2c0-2.6-0.1-10.2-0.2-11.3
            c-0.1-2.9-0.5-3.6-0.8-4.1c0.3,0,0.7,0.1,1.2,0.1c0.6,0.1,1.2,0.1,2.1,0.1c0.6,0,1.7-0.1,2.7-0.1c1-0.1,1.9-0.1,2.4-0.1
            c1.8,0,4.1,0.1,5.6,1.6C105.7,277.4,106.1,278.8,106.1,280.2L106.1,280.2z"
            />
            <path
              d="M123.5,293.2c-1.6,0.2-2.6,0.3-4.1,0.3s-6.3,0-8.6-4.1c-0.8-1.5-1.3-3.4-1.3-5.3c0-1.4,0.3-4.6,2.5-6.8
            c1.4-1.4,3.9-2.6,7.4-2.6c2.3,0,3.8,0.4,4.9,0.8c-0.6,1.4-0.8,1.7-1.2,3.4l-0.4-0.4c-1.2-1-2.6-1.2-3.1-1.2c-3.7,0-5.5,2.5-5.5,6.1
            c0,3.8,1.6,7.7,6.6,7.7c0.7,0,1.3-0.1,1.9-0.2c0.8-0.2,1.1-0.5,1.5-0.8C124.1,290,123.5,293.2,123.5,293.2z"
            />
            <path
              d="M132.4,282.5c1.4,0,2.7,0,4-0.1c1.1-0.1,1.5-0.2,2-0.4c-0.1,0.7-0.2,1.2-0.2,1.8c0,0.6,0.1,1.2,0.2,1.7
            c-0.3-0.1-0.5-0.2-0.7-0.3c-0.6-0.2-1.8-0.3-3.3-0.3h-2.1v3c0,2.6,0.3,2.6,2.7,2.6c2.9,0,4.2-0.2,5.4-1.3c-0.4,1.9-0.5,2.3-0.7,3.9
            c-1.9-0.1-3.6-0.2-6.6-0.2c-2.2,0-3.8,0.1-5.7,0.2c0.4-0.6,0.5-1,0.6-2.1s0.1-6,0.1-8c0-7.3-0.7-7.9-1.1-8.6c1,0.1,2.3,0.3,5.9,0.3
            c2.2,0,4.4,0,6.7-0.3c-0.2,1.6-0.2,2.1-0.2,3.5c-0.8-0.5-1.1-0.7-3.5-0.7h-3.6v5.3H132.4z"
            />
            <path
              d="M151.9,292.5c-1.4,0.7-3.5,1-4.9,1c-2.2,0-4.2-0.5-5.4-0.8c0.5-1.4,0.6-1.7,1-3.5c0.4,0.3,0.7,0.6,1.2,0.8
            c0.9,0.4,2.2,0.7,3.2,0.7c3,0,3.1-1.9,3.1-2.2c0-1.5-1.4-2.2-3-2.8c-0.4-0.2-0.9-0.3-1.3-0.5c-1.5-0.7-3.5-2.1-3.5-4.9
            c0-1.1,0.3-2.5,1.5-3.7c1.7-1.7,4-1.9,6-1.9c2.5,0,3.7,0.4,4.7,0.7c-0.5,1.4-0.6,1.7-1,3.3c-1.5-1.2-3.1-1.3-3.8-1.3
            c-1.8,0-2.9,1-2.9,2.2c0,1.4,1.4,2.1,1.8,2.4c0.9,0.5,1.8,0.8,2.6,1.2c0.9,0.4,3.4,1.6,3.4,4.8
            C154.8,290.2,153.4,291.8,151.9,292.5L151.9,292.5z"
            />
          </g>
          <g>
            <path
              d="M6,15.5c0,4.6,0.5,5,0.8,5.5c-0.9-0.1-1.9-0.2-2.8-0.2c-1,0-1.9,0.1-2.9,0.2c0.3-0.5,0.7-1,0.7-5.5V10
            c0-5.2-0.2-6.9-0.7-7.6c0.8,0.2,1.7,0.3,2.6,0.3c1,0,1.9-0.2,2.9-0.3C6.2,4,6,4.9,6,9.2V15.5z"
            />
            <path
              d="M22,16.5c0.1,1.7,0.7,2,2.5,2c3.1,0,4.2-0.5,5.2-1c-0.5,1.5-0.6,1.9-0.8,3.6c-2.3-0.1-3.8-0.2-7.4-0.2
            c-1.9,0-2.8,0.1-4.7,0.2c0.3-0.4,0.5-0.8,0.7-1.7c0.1-0.8,0.1-4.5,0.1-5.5c0-2.6-0.2-7.7-0.3-9.3c-0.1-1.1-0.4-1.5-0.8-2.2
            c0.7,0.1,1.5,0.2,2.8,0.2c1.1,0,2.1-0.1,3.2-0.2c-0.2,0.7-0.4,1.2-0.5,2.1c-0.2,1.4-0.2,3.2-0.2,4.8C21.8,10.7,21.8,15.2,22,16.5z"
            />
            <path
              d="M42.8,16.5c0.1,1.7,0.7,2,2.5,2c3.1,0,4.2-0.5,5.2-1c-0.5,1.5-0.6,1.9-0.8,3.6c-2.3-0.1-3.8-0.2-7.4-0.2
            c-1.9,0-2.8,0.1-4.7,0.2c0.3-0.4,0.5-0.8,0.7-1.7c0.1-0.8,0.1-4.5,0.1-5.5c0-2.6-0.2-7.7-0.3-9.3c-0.1-1.1-0.4-1.5-0.8-2.2
            c0.7,0.1,1.5,0.2,2.8,0.2c1.1,0,2.1-0.1,3.2-0.2c-0.3,0.7-0.4,1.2-0.6,2.2c-0.2,1.4-0.2,3.2-0.2,4.8C42.6,10.7,42.6,15.2,42.8,16.5
            z"
            />
            <path
              d="M63.5,15.5c0,4.6,0.5,5,0.8,5.5c-0.9-0.1-1.9-0.2-2.8-0.2c-1,0-1.9,0.1-2.9,0.2c0.3-0.5,0.7-1,0.7-5.5V10
            c0-5.2-0.2-6.9-0.7-7.6c0.9,0.1,1.8,0.3,2.7,0.3c1,0,1.9-0.2,2.9-0.3c-0.4,1.5-0.6,2.4-0.6,6.7v6.4H63.5z"
            />
            <path
              d="M91,15.5c0,2.2,0.1,3.5,0.2,5.5c-0.7-0.1-1.4-0.2-2.1-0.2s-1.4,0.1-2.2,0.2c-0.5-1-0.9-1.7-2-3.5
            s-2.3-3.6-3.4-5.3c-2.4-3.6-2.6-4-3.4-5.4l0.1,0.5l0.1,0.5c0.2,1,0,6.7,0.2,9.5c0.1,2.5,0.3,3.2,0.6,3.7c-0.9-0.1-1.6-0.2-2.3-0.2
            c-0.8,0-1.6,0.1-2.4,0.2c0.2-0.5,0.4-1,0.5-1.9c0.2-1.9,0.3-7.4,0.3-8.5c0-1.2,0-2.6-0.1-4.1c-0.1-3.2-0.6-3.6-0.9-4.1
            c1,0.1,2.1,0.2,3.1,0.2s2-0.1,3-0.2c1.3,2.6,3,5.4,4.7,8c1.7,2.7,2.7,4.4,3.1,5.3c-0.1-0.6-0.2-1.3-0.2-3.2c0-3.9,0-5.6-0.1-7.3
            S87.4,3,87.1,2.5c0.8,0,1.6,0.1,2.5,0.1c0.8,0,1.5-0.1,2.3-0.2c-0.4,0.7-0.6,1.2-0.8,5.1C91,9,91,14.1,91,15.5z"
            />
            <path
              d="M114.1,11.9c0-1.5-0.2-3.7-0.8-5c-0.7-1.6-2-2.2-3.6-2.2c-4,0-4.3,4.1-4.3,7.1c0,4,0.8,7,4.4,7
            c0.6,0,2.6,0,3.6-2.3C114,15.1,114.1,13,114.1,11.9z M116.1,4.7c2.2,2.2,2.5,5.3,2.5,7.2c0,0.9,0,4.4-2.5,6.9
            c-1.3,1.3-3.5,2.4-6.3,2.4c-1.3,0-2.9-0.2-4.4-1c-2.8-1.5-4.4-4.2-4.4-8.4c0-6.7,4.3-9.5,8.7-9.5C112.5,2.3,114.7,3.4,116.1,4.7z"
            />
            <path
              d="M132.9,15.5c0,4.6,0.5,5,0.8,5.5c-0.9-0.1-1.9-0.2-2.8-0.2c-1,0-1.9,0.1-2.9,0.2c0.3-0.5,0.7-1,0.7-5.5V10
            c0-5.2-0.2-6.9-0.7-7.6c0.9,0.1,1.8,0.3,2.7,0.3c1,0,1.9-0.2,2.9-0.3c-0.4,1.5-0.6,2.4-0.6,6.7L132.9,15.5L132.9,15.5z"
            />
            <path
              d="M152.8,20.2c-1.4,0.7-3.5,1-4.9,1c-2.2,0-4.2-0.5-5.4-0.8c0.5-1.4,0.6-1.7,1-3.5c0.4,0.3,0.7,0.6,1.2,0.8
            c0.9,0.4,2.2,0.7,3.2,0.7c3,0,3.1-1.9,3.1-2.2c0-1.5-1.4-2.2-3-2.8c-0.4-0.2-0.9-0.3-1.3-0.5c-1.5-0.7-3.5-2.1-3.5-4.9
            c0-1.1,0.3-2.5,1.5-3.7c1.7-1.7,4-1.9,6-1.9c2.5,0,3.7,0.4,4.7,0.7c-0.5,1.4-0.6,1.7-1,3.3c-1.5-1.2-3.1-1.3-3.8-1.3
            c-1.8,0-2.9,1-2.9,2.2c0,1.4,1.4,2.1,1.8,2.4c0.9,0.5,1.8,0.8,2.6,1.2c0.9,0.4,3.4,1.6,3.4,4.8C155.7,17.9,154.2,19.4,152.8,20.2
            L152.8,20.2z"
            />
          </g>
          <g>
            <rect x="0.7" y="244.2" width="154.6" height="2" />
          </g>
          <path
            d="M11.8,223.2c0,0,20.7-3.9,42.4-2.5c19.7,1.3,23.8-6.6,33.7-18.9s12.1-15.3,20.3-18.5c9.8-3.8,20.3-3.8,40.7-4.3
          v-1.4c0,0-6.1,0.1-16.8,0.3c-24,0.5-31.8,7.5-37.1,12.9s-13.4,17.5-20.3,23.7c-6.8,6.2-22,4.2-33.6,4.4c-11.6,0.2-27.1,1.5-33.8,3.3
          v1H11.8z"
          />
          <path
            d="M93.3,223.2c6-12.7,11.3-27.2,24.8-32.6c13-5.2,18.8-5.4,30.7-5.8v1.3c-10.8,0.8-21.6,0.7-34.4,8.4
          c-10.2,6.1-16.7,22.5-19,28.7H93.3z"
          />
          <path
            d="M149.2,158.8L149,159c-0.4,0-0.7,0.1-0.9-0.2v-1l0-0.6V157h0.8l0.3,0.3l0-1c-0.5-0.1-0.8-0.3-1.2-0.5
          c0-0.3-0.1-0.4-0.3-0.5c-0.4,0.3-0.5-0.5-0.9-0.2v0.2c-0.1,0.2-0.3,0.1-0.4,0.1v0.2h-0.8v0.2h-0.5c0,0.4-0.5,0.4-0.5,0.7
          c-0.2,0.1-0.3,0.1-0.5,0.1v0.2h-0.8c0.2,0.3-0.2,0.4-0.2,0.5c-0.5,0.3-1.2,0.3-1.7,0.3v0.2c-0.6,0.1-1.5,0.1-2.2,0v-0.2
          c-0.3,0-0.6,0.1-0.9,0v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.3,0-0.6,0.1-0.8,0V157c-0.3,0-0.7,0.1-1,0v-0.2c-0.3-0.1-0.7,0-1-0.1v-0.2
          c-0.2,0-0.5,0.1-0.7,0c0.2-0.3-0.2-0.4-0.2-0.5c-0.5-0.1-0.8-0.3-1.2-0.3v-0.2c-0.3,0-0.6-0.1-0.7-0.2c0-0.4-0.5-0.5-0.5-0.8
          c-0.5-0.2-1.2-0.5-1.7-0.7c-0.6,0.1-0.1-0.7-0.5-0.5v-0.7h-0.9c0.1,0.2-0.2,0.4-0.2,0.4c-0.2,0.1-0.4,0-0.5,0.1v0.1
          c-0.2,0.1-0.4,0-0.5,0v0.2c-0.2,0.1-0.4,0-0.5,0c-0.2,0.2-0.5,0.6-0.7,0.9c-0.2,0.1-0.3,0-0.5,0c0.2,0.3-0.2,0.4-0.2,0.5
          c-0.2,0.1-0.5,0-0.7,0v0.2c-0.1,0.1-0.3,0-0.5,0v0.2c-0.3,0.2-0.7,0.1-1,0.1v0.2h-0.5v0.2h-0.4c0.1,0.3-0.2,0.4-0.2,0.5h-2.2v0.2
          c-0.7,0.1-1.5,0.1-2.2,0v-0.2c-0.3,0-0.7,0.1-1,0V156h-1.5v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2
          c-0.5-0.1-0.7-0.3-1.2-0.3v-0.2c-0.3,0-0.6,0.1-0.8,0c0.2-0.3-0.2-0.4-0.2-0.5c-0.4,0-0.8-0.2-1-0.4c-0.2,0-0.5,0-0.7-0.1v-0.2h-0.4
          v0.2c-0.2,0.1-0.4,0.1-0.5,0.1v0.1c-0.2,0.1-0.4,0-0.5,0.1c0.2,0.2-0.2,0.4-0.2,0.4c-0.2,0.1-0.3,0-0.5,0v0.2c-0.3,0.1-0.6,0-0.8,0
          c0.2,0.2-0.2,0.4-0.2,0.4c-0.1,0.2-0.3,0.1-0.5,0.1c0,0.4-0.5,0.4-0.5,0.7H105v0.3h-0.9v0.2h-3v-0.2c-0.3,0-0.8,0.1-1,0v-0.2
          c-0.3,0-0.6,0.1-0.8,0v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.5-0.3-1.1-0.6-1.6-0.8c-0.2-0.4-0.6-0.6-0.8-1
          c-0.3,0-0.6,0.1-0.8,0c0.1-0.3-0.4-0.3-0.4-0.5c-0.1-0.4-0.1-0.5-0.3-0.7h-0.7c-0.1,0.4-0.3,0.7-0.5,1.1c-0.6,0.2-0.8,0.7-1.2,1.2
          c-0.2,0.1-0.5,0-0.7,0c0.1,0.2-0.2,0.4-0.2,0.4c-0.2,0.2-0.4,0.1-0.5,0.1v0.2h-0.7c0.1,0.3-0.2,0.4-0.2,0.5h-0.5v0.2h-0.7v0.2
          c-0.1,0.1-0.3,0.1-0.5,0.1v0.2h-1c0.2,0.3-0.1,0.2-0.2,0.2v-0.2h-1.5v-0.2l-1.2-0.1v-0.2c-0.2,0-0.5,0.1-0.7,0V156
          c-0.5-0.1-0.7-0.3-1.2-0.3v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.2,0-0.5-0.1-0.7-0.2v-0.2c-0.3,0-0.7-0.3-0.9-0.5c-0.4,0-0.8,0.1-1,0
          v-0.1c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1,0-0.2-0.1-0.3c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.3,0.1-0.6,0-0.7-0.2c-0.1-0.2-0.2-0.3-0.3-0.4
          c-0.2,0-0.5-0.1-0.6-0.2c-0.3-0.5-0.8-0.8-1.1-1.2c-0.2,0-0.5,0-0.4-0.2h-0.9v0.2h-0.5v-0.1h-0.5v0.1c-0.1,0.1-0.4,0.1-0.5,0.1
          c-0.2,0.4-0.1,0.7-0.5,0.9c-0.2,0.1-0.5,0.1-0.7,0.1c0,0.3-0.4,0.3-0.5,0.6c-0.2,0.1-0.5,0-0.7,0v0.2c-0.3,0.1-0.6,0-0.8,0v0.2
          c-0.1,0.1-0.4,0.1-0.5,0.1v0.1c-0.2,0.1-0.6,0-0.8,0.1v0.1c-0.2,0.1-0.6,0-0.9,0c0.2,0.3-0.2,0.4-0.2,0.5c-0.2,0.1-0.5,0-0.7,0v0.2
          c-0.2,0.1-0.5,0-0.7,0v0.2c-1.7,0.3-3.3-0.3-4.9-0.2v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.2,0-0.5,0.1-0.7,0v-0.2
          c-1.2-0.6-2.3-1.1-3.5-1.6c-0.1,0-0.1-0.1-0.1-0.2c-0.3,0-0.6-0.1-0.7-0.2c-0.4-0.7-0.8-1-1.2-1.5c-0.3,0-0.6,0-0.5-0.2H52
          c0.1,0.3-0.2,0.2-0.2,0.2c-0.3,0.5-0.6,1.2-0.8,1.6c-0.4,0-0.2,0.5-0.5,0.5c-0.1,0.1-0.4,0-0.5,0.1v0.1c-0.1,0.1-0.3,0-0.4,0
          c-0.3,0.4-0.8,0.7-1,1.2c-0.2,0.1-0.5,0-0.7,0c0,0.4-0.5,0.4-0.5,0.7c-0.2,0.1-0.5,0-0.7,0v0.2c-0.1,0.2-0.3,0.1-0.5,0.1v0.2h-0.9
          v0.2h-0.5v0.2H44v0.2h-0.9v0.2c-0.7,0.1-1.5,0.1-2.2,0V156h-1.5v-0.2c-0.6,0-1.3,0.1-1.9,0v-0.2c-0.4,0-0.7,0.1-1,0v-0.2h-1.2v-0.2
          c-0.3,0-0.6,0-0.5-0.2h-1.2v-0.2c-0.4,0-0.7-0.3-0.9-0.5v-20.4h0.6v-0.1c0.2-0.1,0.5,0,0.7-0.1v-0.1c0.3-0.1,0.7,0,1,0v-0.2
          c0.1-0.1,0.3,0,0.5,0v-0.2c0.1-0.1,0.3,0,0.5,0c-0.2-0.2,0.2-0.4,0.2-0.4c0.1-0.1,0.4-0.1,0.5-0.1c-0.1-0.2,0.1-0.5,0.2-0.7
          c0.3,0.1,0.4-0.5,0.7-0.5c0-0.2,0.1-0.5,0.2-0.7c0.4,0.2,0.1-0.3,0.5-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0-0.2,0.4-0.4,0.5-0.6
          c0.1-0.1,0.4-0.1,0.5-0.1v-0.2c0.1-0.1,0.4,0,0.5-0.1v-0.1c0.1-0.1,0.3,0,0.5-0.1V129c0.1-0.2,0.3-0.1,0.5-0.1v-0.2
          c0.1-0.2,0.3-0.1,0.5-0.1v-0.2c0-0.2,0.3-0.1,0.4-0.1v-0.2c0.1-0.2,0.4,0,0.5-0.1v-0.1c0.1-0.1,0.3,0,0.5-0.1v-0.1
          c0.3-0.1,0.6,0,1,0v-0.2c0.2-0.1,0.5,0,0.7,0v-0.2c0.4-0.1,0.7,0,1,0v-0.2c0.6-0.1,1.3,0,2,0v-0.2c1.2,0,2.1-0.4,3.3-0.3v-0.2h0.5
          v-0.2h0.5V126h0.5v-0.2c0.1-0.1,0.3-0.1,0.5-0.1v-0.2h1c-0.2-0.3,0.2-0.4,0.2-0.5h0.5c-0.2-0.2,0.2-0.3,0.2-0.4
          c0.1-0.1,0.4-0.1,0.5-0.1v-0.1c0.1-0.1,0.4-0.1,0.5-0.1c-0.2-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.5-0.1,0.7-0.1c-0.2-0.2,0.2-0.4,0.2-0.4
          c0.1-0.1,0.3,0,0.5-0.1c0-0.4,0.3-0.7,0.5-0.9c0.1-0.2,0.3,0,0.5-0.1c-0.2-0.2,0.2-0.4,0.2-0.4c0.1-0.1,0.3,0,0.5,0
          c0-0.2-0.1-0.4,0-0.5h2.2v0.2h1.4v0.3c0.3,0,0.6,0.2,0.7,0.2c0.1,0,0,0.1,0.1,0.2c0.3,0.1,0.5,0,0.7,0.1v0.2c0.4,0.1,0.8,0.3,1,0.5
          c0.3,0,0.6,0,0.8,0.1c0,0.3,0.4,0.3,0.5,0.6c0.5,0.2,0.7,0.4,1.1,0.5c0.2,0.4,0.8,0.7,1,1.2c0.2,0,0.5,0.2,0.6,0.2
          c0.6,0.8,1.5,1.4,2.2,2.2v0.4c0.3-0.2,0.4,0.2,0.5,0.2v0.5h0.1c0.1,0.1,0.1,0.3,0.1,0.4c0.2,0,0.4,0.4,0.6,0.5
          c0.1,0.2,0.1,0.4,0.1,0.5c0.2-0.1,0.3,0.2,0.4,0.2c0.1,0.2,0,0.4,0.1,0.5h0.1c0.1,0.1,0,0.3,0,0.5c0.3,0,0.5,0.5,0.7,0.5
          c0.1,0.1,0,0.3,0,0.5h0.1c0.1,0.1,0.1,0.3,0.1,0.5c0.2-0.2,0.3,0.2,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0.4,0.1,0.7,0.5,0.9,0.7
          c0.1,0.1,0,0.3,0,0.5c0.3-0.1,0.4,0.2,0.5,0.2c0.1,0.2,0,0.4,0,0.5c0.6,0.6,1.2,1.1,1.7,1.7c0.2,0,0.5,0.2,0.6,0.2
          c0.1,0,0.1,0.2,0.1,0.2c0.2,0,0.5,0,0.5,0.2h2.3c0.1-0.4,0.8-0.1,1.2-0.2v-0.2c0.3-0.1,0.6-0.1,0.8-0.1v-0.2h0.5v-0.2h1v-0.2H86
          v-0.2h0.5v-0.1c0.2-0.1,0.4-0.1,0.5-0.1v-0.1c0.1-0.1,0.3-0.1,0.5-0.1c-0.1-0.2,0.2-0.3,0.2-0.4c0.2-0.1,0.3-0.1,0.5-0.1
          c-0.2-0.2,0.2-0.4,0.2-0.4c0.1-0.1,0.3,0,0.5-0.1v-0.1c0.3-0.2,0.6-0.1,0.8-0.1v-0.2c0.2-0.2,0.5-0.1,0.7-0.1v-0.2
          c0.2-0.2,0.6-0.1,0.8-0.1v-0.2c1.1,0,2.1-0.6,3.1-0.5v-0.2H97v0.3h1.2v0.1c0.3,0.1,0.5,0,0.7,0.1c-0.2,0.2,0.2,0.3,0.2,0.4
          c0.3,0,0.6,0,0.8,0.1v0.3c0.2,0,0.5,0,0.7,0.1v0.2c0.2,0,0.5,0,0.7,0.1v0.1c0.5,0.1,0.7,0.3,1.2,0.3c-0.2,0.2,0.2,0.3,0.2,0.4
          c0.3,0,0.6,0,0.8,0.1v0.2c0.2,0,0.5,0,0.7,0.1v0.1c0.3,0,0.6,0,0.9,0.1v0.1c0.3,0,0.6-0.1,0.8,0v0.2c0.4,0,0.7-0.1,1,0v0.2h1.2v0.2
          h6.7c0.9-0.2,1.9-0.6,2.9-0.5v-0.2h0.7v-0.1c0.3-0.1,0.7-0.1,1-0.1v-0.1c0.3-0.1,0.6-0.1,0.8-0.1v-0.2c0.7,0.2,1.1-0.5,1.9-0.3v-0.2
          c0.4-0.1,0.7,0,1-0.1v-0.1c0.1-0.1,0.3,0,0.5-0.1v-0.1c0.2-0.2,0.5-0.1,0.7-0.1c-0.2-0.2,0.2-0.3,0.2-0.4c0.2-0.2,0.5-0.1,0.7-0.1
          c-0.2-0.2,0.2-0.4,0.2-0.4c0.6-0.3,1.3-0.4,2-0.3v-0.2c1.3-0.3,2.8,0.3,4.1,0.2v0.1l1.2,0.1c-0.2,0.2,0.2,0.3,0.2,0.4
          c0.5,0.1,0.7,0.3,1.2,0.3v0.2l1.2,0.1c-0.2,0.2,0.2,0.4,0.2,0.4c0.2,0.1,0.5,0,0.7,0.1c-0.2,0.2,0.2,0.3,0.2,0.4c0.3,0,0.8,0,1,0.1
          v0.2c0.3,0,0.6,0,0.8,0.1c-0.2,0.2,0.2,0.4,0.2,0.4c0.2,0,0.5-0.1,0.7,0v0.2c0.2,0,0.5-0.1,0.7,0v0.2c0.5,0.1,0.7,0.3,1.2,0.3v0.2
          l1.2,0.1v0.2h3.3v-0.2c0.3-0.1,0.7-0.1,1-0.1v-0.2h0.7v-0.2h0.7v-0.2h0.8v-0.2h0.7v-1.6c-1,0-2,0.5-2.8,0.4v0.2
          c-0.3,0.2-0.6,0.1-0.8,0.1v0.2h-3.2V136l-1.2-0.1v-0.2c-0.4,0-0.8,0-1-0.1v-0.1c-0.3,0-0.6,0-0.5-0.2c-0.3,0-0.7,0-0.9-0.1V135
          c-0.5-0.2-1.2-0.5-1.5-0.7c-0.3,0-0.8,0-1-0.1v-0.1c-0.5-0.3-1.2-0.6-1.7-0.8v-0.2c-0.2-0.1-0.5,0-0.7-0.1v-0.2
          c-0.4-0.3-1.1-0.6-1.4-0.8c-0.2,0-0.5,0.1-0.7,0v-0.2c-0.3-0.1-0.6,0-0.8-0.1v-0.4c-0.4,0.1-0.7-0.3-0.5-0.5c-0.3,0-0.6,0.1-0.8,0
          v-0.1c-0.3-0.1-0.7,0.1-1-0.1v-0.1c-0.2,0-0.5,0-0.7-0.1v-0.2h-2.6c-1,0.3-1.7,0.6-2.7,0.5v0.1c-0.2,0.1-0.5,0-0.7,0v0.2
          c-0.3,0.1-0.6,0-0.8,0v0.2c-0.7-0.2-1.2,0.5-1.9,0.3v0.2c-0.7-0.2-1.2,0.6-1.9,0.3v0.2h-0.4c1.5-20.3-9.2-30.2-12-32.5
          c-2.9-2.4-9.5-4.8-10.3-5.1s-0.8-1-0.7-1.5c0.1-0.4,0.3-0.8,0.5-1c0.1-0.3,0-0.6,0.1-0.9h0.2c0-0.4-0.1-0.7,0-1h0.2
          c0-0.5-0.1-1.2,0-1.7h0.2v-2.4h0.2v-1.6h-0.2v-2.4h-0.2c-0.1-1.8-0.1-3.7,0-5.6c0.2-0.7,0.1-2,0.2-2.8h0.3c0-0.4-0.1-0.8,0-1h0.2
          c0.1-0.4-0.1-0.7,0.1-1h0.2c0-0.3-0.1-0.7,0-1h0.2c0-0.2-0.1-0.5,0-0.7h0.3c0-0.3,0.2-0.8,0.4-0.9c0.1-0.3-0.1-0.6,0.1-0.8
          c0.3,0,0.3-0.4,0.6-0.4c0.2-0.5,0.4-0.8,0.5-1.2h0.2c0-0.3-0.1-0.6,0-0.8h0.2c0-0.4,0.3-0.8,0.5-1v-2.1h0.2l0.1-1.2h0.2v-2.5h-0.2
          c-0.1-0.5-0.1-1.1-0.1-1.6h-0.2v-0.8c-0.3,0.2-0.4-0.2-0.5-0.2V56h-0.2v-0.5h-0.1c-0.1-0.1-0.1-0.3-0.1-0.5h-0.1
          C98,54.9,98,54.7,98,54.5h-0.2c-0.1-0.1-0.1-0.3-0.1-0.5h-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c-0.3-0.1-0.6-0.6-0.9-0.8
          c-0.1,0,0-0.3,0-0.4c-0.3,0.2-0.4-0.2-0.5-0.2c-0.1-0.1,0-0.3,0-0.5h-0.2c-0.2-0.1-0.1-0.3-0.1-0.4h-0.2v-0.5h-0.2v-0.5H95v-0.5
          h-0.2v-1h-0.2c-0.1-0.1-0.1-0.3-0.1-0.5h-0.2v-0.5H94v-0.5h-0.2v-0.9h-0.2v-0.5h-0.2c-0.1-0.2-0.1-0.6-0.1-0.9H93v-0.5h-0.2
          c0.2-0.9-0.5-1.3-0.3-2.2h-0.2v-1h-0.1c-0.1-0.2-0.1-0.6-0.1-0.9H92c0.1-1.1-0.4-2.1-0.3-3.1h-0.2c-0.1-1.2-0.1-2.6-0.1-3.8h-0.2
          c-0.1-0.3-0.1-0.7-0.1-1H91c-0.1-0.2,0-0.5-0.1-0.7h-0.1c-0.1-0.1,0-0.3,0-0.5h-0.2c-0.1-0.1,0-0.3,0-0.5c-0.3,0.2-0.4-0.2-0.5-0.2
          c-0.1-0.1,0-0.3,0-0.5c-0.6-0.3-1-1-1.5-1.4c-0.2,0-0.5-0.1-0.7-0.2c0-0.5-0.7-0.3-0.5-0.8c-0.5,0.3-0.5-0.4-1-0.3
          c0-0.1-0.1,0,0-0.1h62.4v-1.4H7.2v1.4l70.4,0.2c0,0,0.7,0,1,0.1v0.1c1,0.4,1.7,0.8,2.6,1.2c0,0.2,0.2,0.4,0.3,0.5
          c0.6,0.2,1.1,0.5,1.6,0.7c0.1,0.4,0,0.6,0.3,0.8c0.2,0,0.5,0.2,0.7,0.2c0.1,0.4,0.6,0.6,0.8,1c0.2,0,0.5,0.2,0.6,0.2
          c0.1,0.1,0.1,0.4,0.1,0.5h0.2c0.1,0.1,0.1,0.4,0.1,0.5h0.2c0.1,0.3,0,0.6,0.1,0.9h0.1c0,1.2,0.6,2.4,0.5,3.6H87c0.1,0.8,0,1.7,0,2.6
          h0.2c0.1,0.5,0,1.2,0,1.7h0.2c0.1,0.5,0.1,1.1,0.1,1.7h0.2c-0.2,0.9,0.5,1.3,0.3,2.1h0.2v1h0.2v0.8h0.2c0.1,0.1,0.1,0.3,0.1,0.5h0.2
          v0.5h0.2v0.5h0.2v0.5h-0.1v0.7h0.2c0.1,0.1,0.1,0.4,0.1,0.5c0.2-0.2,0.3,0.2,0.4,0.2v0.4c0.3,0,0.4,0.5,0.6,0.5
          c0.1,0.2,0.1,0.4,0.1,0.5h0.1c0.1,0.2,0.1,0.4,0.1,0.5H91c0.1,0.2,0.1,0.4,0.1,0.5h0.2c0.1,0.2,0.1,0.5,0.1,0.7h0.2
          c0.1,0.3,0,0.6,0.1,0.8h0.1c0.1,0.1,0,0.3,0.1,0.5H92c0.1,0.3,0,0.6,0,0.8h0.2c0.1,0.2,0,0.5,0,0.7h0.2c0.1,0.1,0,0.3,0,0.5h0.2
          c0,1.1,0.6,2.1,0.5,3.1h0.2c0.1,0.6,0.1,1.3,0,2h-0.2c0,0.3,0.1,0.7,0,1h-0.2c0,0.2-0.2,0.5-0.2,0.6c0,0.2-0.1,0.1-0.2,0.1
          c0.1,0.2-0.1,0.5-0.2,0.6c0,0.2-0.1,0-0.2,0.1c0,0.3,0.1,0.5,0,0.7c-0.2-0.2-0.4,0.2-0.4,0.2c-0.1,0.5-0.3,0.7-0.3,1.2h-0.2
          c0,0.3,0,0.6-0.2,0.5c0,0.3,0,0.7-0.1,1h-0.1c0,0.3,0,0.7-0.1,1h-0.1c0,0.3,0.1,0.6,0,0.9h-0.2c0,0.3,0.1,0.7,0,0.9h-0.2v2.2H90
          c0.1,1.8-0.4,3.5-0.3,5.3h-0.2V84h0.2c0.1,0.7,0.1,1.4,0.1,2.2H90v2.7h-0.2c-0.1,0.3,0,0.5-0.1,0.7h-0.2c0,0.2,0.1,0.5,0,0.7h-0.2
          c0,0.3,0.1,0.6,0,0.8c-0.3-0.2-0.4,0.2-0.5,0.2c-0.1,0.5-0.4,0.7-0.5,1.1c-0.4,0-0.5,0.6-0.8,0.6c-0.1,0.5-0.4,0.7-0.5,1.1
          c-0.3,0.2-0.6,0.6-0.9,0.8c0,0.1-0.2,0.5-0.2,0.6c-0.9,0.7-1.7,1.5-2.4,2.4h-0.8c-0.3,0.3-0.8,0.6-1,1.1c-0.1,0.1-0.3,0.1-0.5,0.1
          v0.2c-0.1,0.1-0.3,0.1-0.5,0.1c0,0.3-0.4,0.3-0.5,0.6c-0.1,0.2-0.3,0.1-0.4,0.1c0.1,0.2-0.2,0.3-0.2,0.4c-0.1,0.2-0.3,0.1-0.5,0.1
          v0.1c-0.1,0.2-0.3,0-0.4,0.1v0.1c-0.2,0.1-0.4,0-0.5,0v0.2c-0.2,0.1-0.5,0-0.7,0v0.2c-0.3,0.1-0.6,0-0.8,0c0.2,0.2-0.2,0.4-0.2,0.4
          c-0.1,0.2-0.3,0.1-0.5,0.1v0.2h-0.5v0.2h-0.4c-0.5,0.7-1.3,1-1.7,1.7c0,0.2,0.1,0.5,0,0.7c-0.6,0.3-1,1-1.4,1.4
          c-0.1,0.2-0.4,0.1-0.5,0.1c-0.2,0.2-0.7,0.6-0.8,0.9c-0.1,0.1-0.3,0-0.5,0v0.1c-0.1,0.1-0.3,0-0.5,0c0.2,0.2-0.2,0.4-0.2,0.4
          c0,0.2-0.3,0.1-0.4,0.1v0.2h-0.5v0.2h-0.5v0.2c-1.5,0.2-2.9,0.8-4.4,0.8v0.2h-0.4c0.2,0.3-0.2,0.4-0.2,0.5h-0.8
          c0,0.3-0.4,0.3-0.5,0.6c-0.1,0.1-0.4,0.1-0.5,0.1c0.2,0.2-0.2,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c0.1,0.3-0.4,0.3-0.5,0.4
          c-0.2,0.4-0.2,0.7-0.5,0.9c-0.1,0.2-0.4,0-0.5,0.1c0.1,0.2-0.4,0.2-0.5,0.4c-0.1,0.5-0.4,0.8-0.5,1.2H58c0.1,0.3-0.1,0.6-0.2,0.7
          c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.5-0.3,0.7-0.3,1.2h-0.2c0.1,0.3-0.2,0.6-0.2,0.7c0,0.1-0.1,0.1-0.2,0.1c0,0.3,0,0.5-0.1,0.7
          c-0.5,0.5-1.3,1.2-1.8,1.9c-0.1,0.2-0.3,0.1-0.5,0.1c0,0.3-0.4,0.5-0.5,0.7h-0.5c0.2,0.2-0.2,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
          v0.2h-0.5c0.2,0.3-0.2,0.4-0.2,0.5h-0.4c0.2,0.2-0.2,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.5,0.1v0.1c-0.1,0.1-0.4,0.1-0.5,0.1v0.2
          c-0.1,0.1-0.4,0.1-0.5,0.1v-0.1c-0.2,0.1-0.5,0.1-0.7,0.1v0.2c-0.3,0.1-0.6,0.1-0.8,0.1v0.1c-0.6,0.1-1.2,0-1.9,0.1v0.1
          c-1.1-0.1-1.8,0.4-2.9,0.3v0.2c-0.2,0.2-0.6,0.1-0.9,0.1v0.2c-0.1,0.2-0.4,0.1-0.5,0.1v0.1c-0.1,0.2-0.4,0-0.5,0.1v0.1
          c-0.1,0.1-0.3,0-0.5,0v0.2c-0.2,0.1-0.5,0-0.7,0c0.2,0.3-0.2,0.4-0.2,0.5s-0.3,0-0.4,0v0.2c-0.1,0.2-0.4,0.1-0.5,0.1v0.2h-0.5
          c0,0.3-0.4,0.3-0.5,0.7h-0.5v0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0.2-0.7,0.6-0.8,0.9h-0.5c0,0.3-0.5,0.3-0.5,0.6
          c-0.3,0.1-0.5,0-0.7,0.3c0,0.2-0.2,0.5-0.2,0.6c-0.1,0.2-0.3,0.1-0.5,0.1c0.2,0.2-0.2,0.3-0.2,0.4c0,0.2-0.3,0.1-0.4,0.1v0.2
          c-0.4,0.2-0.7,0.1-1,0.1v0.1c-0.3,0.2-0.6,0-0.8,0.1v0.1c-0.2,0.1-0.5,0-0.7,0v0.2c-0.7,0.1-1.4,0.1-2,0v-0.2c-0.6,0-1.2,0.1-1.7,0
          V130c-0.3,0-0.6,0-0.5-0.2c-0.3,0-0.7,0-0.9-0.1v-0.2l-1.5-0.1v-0.2h-2.1v0.2c-0.3,0.2-0.6,0.1-0.9,0.1v0.2
          c-0.3,0.2-0.6,0.1-0.8,0.1v0.1c-0.2,0.2-0.5,0-0.7,0.1c0.2,0.2-0.2,0.4-0.2,0.4c-0.1,0.1-0.3,0-0.4,0v0.2c-0.3,0.1-0.6,0-0.8,0v0.2
          c-0.1,0.1-0.4,0-0.5,0v0.2c-0.3,0.2-0.6,0.1-0.8,0.1v0.2h-0.5v0.2h-2.4v0.2c-0.6,0.1-1.2,0.1-1.8,0v-0.2h-2.1v-0.2H9.1v-0.2
          c-0.3,0-0.6-0.1-0.7-0.2c-0.1,0-0.1-0.1-0.1-0.2c-0.2,0-0.5,0-0.4-0.2H7.2v43.7h141.7v-1.7H44.5v-0.2c-0.5-0.1-0.7-0.3-1.2-0.3
          c-0.4-0.6-1.3-1.2-1.9-1.8c-0.1-0.2,0-0.3,0-0.5c-0.3,0.1-0.4-0.2-0.5-0.2c-0.1-0.1,0-0.3,0-0.5h-0.2c-0.2-0.1-0.1-0.3-0.1-0.5
          c-0.4,0.1-0.4-0.4-0.5-0.5c-0.2,0-0.5-0.1-0.6-0.2c-0.1,0-0.1-0.1-0.1-0.2c-0.3,0-0.7,0.1-1,0c0-0.4-0.4-0.4-0.4-0.7
          c0.3,0,0.2,0,0.2-0.2c0-0.5,0.7-0.2,1-0.3v-0.1c0.6-0.1,1.4-0.1,2,0v0.4h0.2v0.5c0.2,0,0.5,0.2,0.6,0.2c0.1,0.3,0.1,0.7,0.1,1h0.2
          c0.1,0.1,0.1,0.3,0.1,0.5c0.1-0.1,0.5,0.2,0.6,0.2s0,0.1,0.1,0.2c0.1-0.1,0.5,0.2,0.6,0.2c0.1,0,0,0.1,0,0.2c0.2,0.1,0.5,0,0.7,0.1
          v0.2c0.3,0,0.6-0.1,0.8,0v0.2h104.1l0-1.6l-1.5-0.1c-0.1-0.2-0.4-0.4-0.4-0.4c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.3-0.7-0.5-0.8-0.7
          c-0.1-0.1-0.1-0.3-0.1-0.5c-0.3,0-0.3-0.4-0.6-0.4c-0.1-0.4-0.1-0.6-0.3-0.8c0-0.4,0.3-0.5,0.5-0.7c0.1-0.1,0.3,0,0.4,0v-0.2
          c0.5-0.1,1.1-0.1,1.5,0v0.4h0.2c0.1,0.3,0,0.6,0,0.8c0.2-0.2,0.4,0.2,0.4,0.2c0.1,0.2,0.1,0.4,0.1,0.5c0.2,0.1,1.1,0.6,1.2,0.8
          L149.2,158.8z M113.2,132.5c-0.9,0-1.9,0-2.7-0.1v-0.2c-0.7,0-1.3,0.1-1.9,0V132h-1.5v-0.2c-0.3-0.1-0.8,0-1-0.1v-0.2
          c-0.3,0-0.6,0.1-0.8,0v-0.2c-0.3,0-0.6,0-0.5-0.2l-1-0.3l-1.1-0.3h-0.5v-0.2c-0.3-0.1-0.7-0.1-1-0.1V130c-0.2-0.1-0.5,0-0.8-0.1
          c0.1-0.2-0.1-0.2-0.3-0.2h-0.2v-0.1c-0.2-0.1-0.5-0.1-0.8-0.1v-0.2c-0.2-0.1-0.4-0.1-0.7-0.1c0.1-0.2-0.2-0.2-0.3-0.2h-0.2v-0.2
          c-0.2-0.1-0.5,0-0.7-0.1v-0.1c-1.2-0.1-2.7-0.1-3.9,0v0.1c-0.5,0.1-1.1-0.1-1.7,0.1v0.2c-0.4,0.1-1.1-0.2-1.2,0.2
          c-0.4,0.1-1.1-0.2-1.2,0.2c-0.2,0-0.5-0.1-0.7,0.1v0.1c-0.2,0.1-0.3,0-0.5,0.1v0.1c-0.2,0.1-0.5,0-0.7,0.1v0.2c-0.2,0-0.5,0-0.7,0.1
          v0.2c-0.2,0-0.4,0-0.5,0.1v0.2c-0.2,0-0.5,0-0.7,0.1v0.1c-0.2,0-0.4,0-0.5,0.1v0.1h-0.7v0.2h-1.7v0.2h-2.4v-0.2
          c-0.2-0.1-0.4,0-0.7,0c-0.1,0,0-0.2-0.1-0.2h-0.2c0,0,0-0.2-0.1-0.2h0.2c0,0,0-0.1-0.1-0.2h-0.2c0-0.1,0-0.2-0.1-0.2h-0.1
          c0-0.1,0-0.2-0.1-0.2h-0.1v-0.2h-0.3v-0.3H78v-0.4h-0.2v-0.2h-0.2c0-0.1,0-0.4-0.1-0.5h-0.2c0-0.1,0.1-0.1,0-0.2h-0.2
          c0-0.2,0.1-0.4,0-0.5h-0.2c0-0.2,0.1-0.4,0-0.5h-0.2c0-0.1,0.1-0.2,0-0.2h-0.1c-0.1-0.2,0-0.4-0.1-0.5h-0.1c-0.1,0,0-0.2-0.1-0.2
          h-0.2c0-0.2,0-0.4-0.1-0.5h-0.2c0-0.1,0-0.2-0.1-0.2h-0.2c0-0.1,0-0.2-0.1-0.2h-0.1c0-0.1,0-0.3-0.1-0.5h-0.1c0-0.1,0.1-0.3,0-0.5
          h-0.2c0-0.1,0.1-0.2,0-0.2h-0.2c0,0,0.1-0.2,0-0.2h-0.2c0,0,0.1-0.2,0-0.2h-0.1c-0.1,0,0-0.1-0.1-0.2h-0.1c-0.1-0.2,0-0.4-0.1-0.5
          h-0.2c0-0.1,0-0.2-0.1-0.2h-0.6c0-0.1,0-0.2-0.1-0.2h-0.2c0-0.1,0-0.1-0.1-0.2h-0.1c0-0.1,0-0.2-0.1-0.2h-0.1v-0.2h-0.3v-0.2H72V122
          h-0.2v-0.2h-0.2c0.1-0.2-0.1-0.3-0.3-0.2h-0.2c0-0.1,0.1-0.2,0-0.2h-0.2c0,0,0.1-0.2,0-0.2h-0.2c0,0,0.1-0.2,0-0.2h-0.1
          c-0.1,0,0-0.2-0.1-0.2h-0.1c0.1-0.2-0.1-0.3-0.3-0.2h-0.2c0-0.1,0-0.2-0.1-0.2h-0.2c0,0,0-0.2-0.1-0.2h-0.1c0.1-0.2-0.1-0.2-0.3-0.2
          h-0.2c0.1-0.2-0.1-0.2-0.3-0.2h-0.2c0.1-0.2-0.1-0.3-0.3-0.2H68c-0.1-0.1,0-0.2-0.1-0.2h-0.2v-0.2c-0.2-0.1-0.5,0-0.7-0.1V119
          l-1.5-0.1v-0.1c-0.3-0.1-0.6-0.1-0.9-0.1c0.1-0.2-0.1-0.3-0.3-0.2h-0.2c0-0.1,0-0.4-0.1-0.5h-0.2v-0.9H64v-0.2h0.7v-0.1
          c0.4,0,0.7,0,1-0.1v-0.2c0.5-0.1,1.2,0.2,1.4-0.2c0.5,0,1.3,0.2,1.4-0.2h2.4v-0.2h1.7v-0.2c0.5-0.1,1.2,0.2,1.5-0.2
          c0.1,0,0.3,0.1,0.4,0v-0.2c0.3,0,0.7,0.1,1,0v-0.2c0.2,0,0.4,0.1,0.5,0v-0.2c0,0,0.2,0.1,0.2,0v-0.1c0.1-0.1,0.4,0.1,0.4-0.1v-0.1
          c0.1,0,0.2,0.1,0.2-0.1v-0.2c0.1,0,0.2,0.1,0.2-0.1V114c0.1,0,0.2,0.1,0.2-0.1v-0.2c0.1,0,0.2,0.1,0.2-0.1v-0.1
          c0.1-0.1,0.2,0,0.2-0.1v-0.1c0.1,0,0.2,0,0.2-0.1V113c0.1,0,0.2,0,0.2-0.1v-0.5c0.2,0.1,0.2-0.2,0.2-0.3V112
          c0.2,0.1,0.2-0.2,0.2-0.3v-0.3h0.2v-0.2h0.2V111H79c0.1-0.2,0.1-0.5,0.1-0.7c0.2-1.2,0.3-2.6,1.7-3c1.4-0.5,5.9-3.7,7.6-5.6
          c0.8-0.5,1.6-0.3,3.2-0.1s9.3,0.8,15.8,8.3C113.7,117.1,113.5,127.4,113.2,132.5z M53,152.5c-0.1,0.2,0.1,0.2,0.3,0.2l0.2,0
          c0,0.1-0.1,0.2,0,0.2l0.2,0c0,0.2-0.1,0.4,0,0.5l0.1,0c0.1,0,0,0.2,0.1,0.2l0.1,0c0,0,0,0.2,0.1,0.2l0.2,0c-0.1,0.2,0.1,0.3,0.3,0.2
          l0.2,0c-0.1,0.2,0.1,0.3,0.3,0.2l0.1,0l0,0.2c0.3,0.1,0.6,0.1,0.9,0.1c-0.1,0.2,0.2,0.2,0.3,0.2l0.2,0c0,0,0,0.2,0.1,0.2l0.2,0
          c0,0.1,0,0.2,0.1,0.2l0.2,0c-0.1,0.2,0.2,0.3,0.3,0.2l0.1,0l0,0.2c0.2,0.1,0.5,0,0.8,0c-0.1,0.2,0.1,0.2,0.3,0.2l0.2,0l0,0.2l1.2,0
          l0,0.2l1.5,0l0,0.2c1.2,0.1,2.4,0.1,3.6,0l0-0.2l0.5,0l0-0.2l0.7,0l0-0.2c0.2,0,0.5,0.1,0.8-0.1l0-0.2c0,0,0.1,0.1,0.2,0l0-0.2
          c0.2,0,0.4,0.1,0.5,0l0-0.2c0.2,0,0.5,0.1,0.7,0l0-0.2c0.2,0,0.4,0.1,0.5,0l0-0.1c0.1-0.1,0.4,0,0.5-0.1l0-0.2c0,0,0.1,0,0.2-0.1
          l0-0.2c0.2,0,0.4,0.1,0.5,0l0-0.2c0.2,0,0.4,0.1,0.5,0l0-0.2l0.4,0c0,0.1-0.1,0.2,0,0.2l0.3,0l0,0.9c-0.2-0.1-0.3,0.2-0.2,0.3l0,0.2
          c-0.2,0-0.7-0.1-0.5,0.3l0,0.2c-0.2,0-0.4,0-0.5,0.1l0,0.2l-0.2,0l0,0.2l-0.2,0l0,0.2l-0.7,0l0,0.2l-0.8,0l0,0.2c0,0-0.2,0-0.2,0.1
          l0,0.2l-0.5,0l0,0.2l-0.5,0l0,0.2l-0.7,0l0,0.2l-0.2,0l0,0.2c-0.2,0-0.5,0-0.7,0.1l0,0.2c-0.2,0-0.4-0.1-0.5,0l0,0.2
          c-0.2,0-0.4-0.1-0.5,0l0,0.2c-0.5,0-1.3-0.2-1.5,0.2l-0.9,0l0-0.2l-2.6-0.1l0,0.3c-0.2-0.1-0.5,0-0.7,0c0.1-0.2-0.1-0.2-0.3-0.2
          l-0.2,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.2,0c0-0.1,0.1-0.2,0-0.2l-0.1,0c-0.1-0.1,0-0.2-0.1-0.2l-0.1,0l0-0.2c-0.3-0.1-0.6,0-1,0l0-0.2
          c-0.2-0.1-0.5,0-0.8,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.1,0l0-0.2l-1.2,0c0.1-0.2-0.1-0.3-0.3-0.2l-0.4,0l0-0.2l-0.2,0
          c0.1-0.2-0.1-0.2-0.3-0.2l-0.2,0c0,0,0-0.2-0.1-0.2l-0.2,0c0,0,0.1-0.2,0-0.2l-0.2,0c0-0.1,0.1-0.2,0-0.2l-0.2,0
          c0-0.1,0.1-0.3,0-0.5l-0.2,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.1,0l-0.1,1.2c-0.2-0.1-0.3,0.2-0.2,0.3l0,0.2l-0.2,0l0,0.2l-0.2,0l0,0.2
          l-0.2,0l0,0.2c0,0-0.2,0-0.2,0.1l0,0.2l-0.5,0l0,0.2l-0.2,0l0,0.2l-0.7,0l0,0.2l-0.2,0l0,0.2c-0.1,0-0.2,0-0.2,0.1l0,0.2
          c-0.1,0-0.4-0.1-0.4,0l0,0.2c-0.1,0-0.2-0.1-0.2,0l0,0.2c-0.4,0-1.1-0.2-1.2,0.2c-0.4,0-0.7-0.1-1,0l0,0.1c-0.2,0.1-0.4,0-0.5,0.1
          l0,0.1c-0.3,0.1-0.7,0-0.9,0.1l0,0.2c-0.5,0.1-1.2-0.2-1.5,0.2c-0.3,0-0.7,0-0.9,0.1l0,0.2l-1.7,0l0-0.2c-0.2-0.1-0.4-0.1-0.7-0.1
          l0-0.2c-0.3-0.1-0.6-0.1-1-0.1l0-0.2l-1.2-0.1l0-0.1c-0.3-0.1-0.7,0-1-0.1l0-0.1c-0.2-0.1-0.5,0-0.8,0c0.1-0.2-0.1-0.3-0.3-0.2
          l-0.2,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.2,0c0-0.1,0.1-0.2,0-0.2l-0.2,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.1,0c0.1-0.2-0.1-0.2-0.3-0.2l-0.2,0
          c0-0.2,0-0.4-0.1-0.5l-0.2,0c0,0,0-0.2-0.1-0.2l-0.2,0l0-0.2c0.2,0.1,0.3-0.1,0.2-0.3l0-0.2l0.2,0l0-0.2l0.6,0
          c-0.1,0.2,0.1,0.3,0.3,0.2l0.2,0c-0.1,0.2,0.1,0.3,0.3,0.2l0.2,0l0,0.2c0.5,0.1,1.1,0,1.7,0.1c-0.1,0.2,0.1,0.2,0.3,0.2l0.2,0l0,0.2
          l2.9,0.1l0,0.2l3.2,0l0-0.2l0.4,0l0-0.2c0.5,0,1.3,0.2,1.5-0.2c0.1,0,0.4,0,0.5-0.1l0-0.2l0.4,0l0-0.2l0.8,0l0-0.2l0.5,0l0-0.2
          l0.2,0l0-0.2c0.1,0,0.4,0.1,0.5-0.1l0-0.2c0.2,0,0.5,0.1,0.7,0l0-0.2c0.1,0,0.2,0.1,0.2,0l0-0.2c0,0,0.2,0.1,0.2,0l0-0.2
          c0,0,0.2,0.1,0.2,0l0-0.1c0-0.1,0.1,0,0.2-0.1l0-0.1c0.1,0,0.2,0,0.2-0.1l0-0.2l0.9,0c0,0.1,0,0.2,0.1,0.3l0.2,0
          c-0.1,0.2,0.2,0.2,0.3,0.2l0.2,0c0.1-0.2,0.1-0.6,0-0.8l-0.2,0l0-0.2c0.2,0.2,0.2-0.1,0.2-0.3l0-0.1c0.2,0.1,0.2-0.2,0.2-0.3l0-1.1
          c0,0,0.1,0,0.2-0.1l0-0.2c0.2,0.1,0.3-0.1,0.2-0.2l1,0C53,152.4,53,152.5,53,152.5z M74.4,152.6c0,0.1,0,0.2,0.1,0.2h0.2
          c0,0.2,0,0.5,0.1,0.7H75v0.5c0.2,0.1,0.7,0,1,0c-0.1,0.3,0.1,0.3,0.3,0.3v0.2h0.2c0,0-0.1,0.2,0.1,0.2h0.2v0.2c0.2,0.1,0.5,0,0.8,0
          c0,0.1-0.1,0.2,0,0.2h0.1c0.1,0.1,0,0.2,0.1,0.2h0.1v0.2c0.3,0.2,0.6,0,1,0.1c-0.1,0.2,0.1,0.2,0.3,0.2h0.2c0,0.1-0.1,0.2,0,0.2h0.2
          c0,0.1-0.1,0.2,0.1,0.2h0.2v0.2h1.2v0.2c0.2,0.1,0.5,0,0.7,0.1v0.2H82v0.2c0.2,0.1,0.5,0,0.7,0c-0.1,0.2,0.1,0.3,0.3,0.2h0.3v0.9
          c-1,0-2.2-0.1-3.2,0v0.2h-0.6v0.2c-0.2-0.1-0.4,0-0.7,0c0.1-0.2-0.2-0.2-0.3-0.2h-0.2v-0.2c-0.2-0.1-0.5,0-0.7-0.1
          c0.1-0.2-0.1-0.2-0.3-0.2h-0.2c0.1-0.2-0.1-0.3-0.3-0.2h-0.2c0-0.1,0-0.2-0.1-0.2h-0.2v-0.2c-0.2-0.1-0.5,0-0.8,0
          c0.1-0.2-0.1-0.2-0.3-0.2h-0.1c-0.1,0,0-0.2-0.1-0.2h-0.2c0-0.1,0-0.2-0.1-0.2h-0.2c0-0.1,0-0.2-0.1-0.2h-0.2c0,0,0-0.2-0.1-0.2H74
          c0,0,0-0.2-0.1-0.2h-0.1v-0.2c-0.2-0.1-0.5,0-0.8,0c0-0.1,0.1-0.2,0-0.2h-0.1c-0.1-0.1,0-0.2-0.1-0.2h-0.1c-0.1-0.3,0-0.6-0.1-1
          h-0.2v-0.2c0.2,0.1,0.2-0.1,0.2-0.3v-0.1c0.2,0.1,0.2-0.2,0.2-0.3v-0.2c0.1,0,0.3-0.1,0.2-0.2h1.4V152.6z M94.7,155.2
          c-0.1,0.2,0.1,0.2,0.3,0.2h0.2v0.2h0.3v0.5h0.2c-0.2,0.2,0.1,0.3,0.3,0.2h0.2v0.2c0.2,0.2,0.5,0,0.8,0.1c0,0.1-0.1,0.2,0,0.2h0.1
          c0.1,0.1,0,0.2,0.1,0.2h0.1v0.2c0.3,0.1,0.7,0,1,0c0,0.1,0,0.2,0.1,0.2h0.1v0.2c0.3,0.1,0.7,0,1,0.1v0.2c0.2,0.1,0.5,0,0.8,0v0.2
          c0.2,0.1,0.5,0,0.7,0v0.2c1.5,0.1,3,0.1,4.4,0v-0.2h0.5v-0.2h0.2v-0.2c0.2,0,0.4,0,0.5-0.1v-0.2h0.5v-0.2h0.2V157h0.5v-0.2h0.9
          c-0.1,0.2,0.1,0.3,0.3,0.2h0.1v0.7c0,0-0.2,0-0.2,0.1v0.2h-0.2v0.2c-0.2-0.1-0.3,0.1-0.2,0.3v0.2h-0.2v0.2c-0.1,0-0.2,0-0.2,0.1v0.2
          c-0.2,0-0.4-0.1-0.5,0v0.2c-0.2,0-0.5-0.1-0.8,0v0.2c0,0-0.2-0.1-0.2,0v0.2c-0.2,0-0.5-0.1-0.8,0v0.1c-0.7,0.1-1.3,0-1.9,0.1v0.1
          h-2.6V160l-1.2-0.1v-0.1h-1.5v-0.2c-0.2-0.1-0.4,0-0.7,0v-0.1c-0.2-0.1-0.5,0-0.7,0v-0.2c-0.2-0.1-0.5,0-0.8,0v-0.2
          c-0.2-0.1-0.5,0-0.7-0.1c0.1-0.2-0.2-0.2-0.3-0.2h-0.2v-0.2H95v-0.2c-0.2-0.1-0.5,0-0.7,0v-0.2h-0.2V158h-0.2v-0.2h-0.2
          c0-0.3,0-0.7-0.1-1h-0.2v-0.7c0.2,0.1,0.2-0.1,0.2-0.3v-0.1c0.2-0.1,0.6,0.1,0.5-0.2h0.6L94.7,155.2z M127.6,156
          c0.1,0.1,0,0.2,0.1,0.2h0.1c0,0.1,0,0.2,0.1,0.2h0.2v0.4c-0.2-0.1-0.3,0.2-0.2,0.3v0.2h-0.2v0.2h-0.2v0.2c-0.2,0-0.4,0-0.5,0.1v0.2
          h-0.2v0.2h-0.2v0.2h-0.2v0.2h-0.2v0.2c-0.2,0-0.7-0.1-0.5,0.3v0.2c0,0-0.2-0.1-0.2,0v0.2c-0.2,0-0.4-0.1-0.5,0v0.2
          c-0.1,0-0.2-0.1-0.2,0v0.1c-0.2,0.1-0.3,0-0.5,0.1v0.1c-0.2,0.1-0.5,0-0.7,0.1v0.2c-0.2,0-0.5,0-0.7,0.1v0.2
          c-0.5,0.1-1.3-0.2-1.4,0.2h-4.1c0.1-0.2-0.1-0.3-0.3-0.2H117c-0.1-0.1,0-0.2-0.1-0.2h-0.2v-0.2l-1.5-0.1c0.1-0.2-0.2-0.2-0.3-0.2
          h-0.2c0.1-0.2-0.2-0.2-0.3-0.2H114v-0.2c-0.5-0.1-1.2,0-1.7,0v0.1h-1.2c0.1-0.2-0.1-0.2-0.3-0.2h-0.1c0.1-0.2-0.2-0.2-0.3-0.2h-0.2
          c0-0.2,0-0.4-0.1-0.5h-0.2v-0.9c0.2,0.1,0.3-0.1,0.2-0.3v-0.2h0.5v-0.2h0.4v-0.2h0.9c-0.1,0.2,0.2,0.2,0.3,0.2h0.2
          c-0.1,0.2,0.2,0.3,0.3,0.2h0.2v0.2c0.2,0.1,0.6,0,1,0v0.2c0.2,0.1,0.5,0,0.7,0c0,0-0.1,0.2,0,0.2h0.2v0.2c0.2,0.1,0.5,0,0.7,0v0.2
          h1.5v0.2h1.2v0.2c0.7,0.1,1.3,0.1,2,0v-0.1h1v-0.2c0.5,0,1.2,0.2,1.5-0.2h0.9V158c0.2,0,0.4,0,0.5-0.1v-0.2h0.5v-0.2h0.5v-0.2h0.2
          v-0.2h0.5v-0.2c0.2,0,0.3,0.1,0.5-0.1v-0.2c0.2,0,0.3,0.1,0.5,0v-0.2C127.3,156.6,127,155.8,127.6,156L127.6,156z M92.5,156.7
          c-0.1,0.2,0.2,0.3,0.3,0.2H93v0.9h-0.2v0.2h-0.2v0.2h-0.2v0.2h-0.7v0.2c-0.2,0-0.3,0-0.5,0.1v0.2c-0.1,0-0.3-0.1-0.4,0v0.2
          c-0.2,0-0.4-0.1-0.5,0v0.2c-0.2,0-0.5-0.1-0.7,0v0.2c-0.2,0-0.4-0.1-0.5,0v0.1c-0.2,0.1-0.5,0-0.7,0.1v0.1c-0.3,0.1-0.7,0-1,0.1v0.2
          c-0.7,0-1.3,0-1.9,0.1v0.2c-0.4,0.1-1.1-0.2-1.2,0.2h-3.1c0-0.1,0.1-0.2,0-0.2H81v-0.9h2.2v-0.2H84v0.1c0.4,0,1.1,0.2,1.2-0.2h0.7
          V159c0.5-0.1,1.2,0.2,1.4-0.2h1v-0.4H89v-0.2h0.8V158H90v-0.2c0.2,0,0.5,0,0.7-0.1v-0.2h0.5v-0.2h0.2v-0.2c0.2,0.1,0.3,0,0.2-0.2
          h0.9L92.5,156.7z M56.6,159.8c0,0.1,0,0.2,0.1,0.2h0.2c0,0.1,0,0.2,0.1,0.2h0.2c0,0.1,0,0.3,0.1,0.4v0.2h-0.2v0.2h-0.2v0.2
          c-0.2-0.1-0.3,0.2-0.2,0.3v0.2h-0.2v0.2h-0.2v0.2h-0.2v0.2h-0.2v0.2c-0.2,0-0.4,0-0.5,0.1v0.2h-0.5v0.2h-0.5v0.2h-2.8V163
          c-0.3-0.1-0.6,0-1,0v-0.2c-0.2-0.1-0.5,0-0.7,0c0.1-0.2-0.1-0.2-0.3-0.2h-0.2v-0.9h1v-0.2c0.2,0,0.5,0.1,0.7-0.1v-0.2
          c0.2,0,0.5,0.1,0.7,0V161c0.4,0,0.7,0.1,1,0v-0.2c0.3,0,0.7,0.1,1,0v-0.2c0.1,0,0.4,0.1,0.5,0v-0.1c0.2-0.1,0.4,0,0.5-0.1v-0.1
          c0.1,0,0.3,0,0.5-0.1V160c0.1,0,0.3-0.1,0.2-0.2H56.6L56.6,159.8z M70.8,160.2c0,0.1-0.1,0.3,0,0.4H71c0,0.1-0.1,0.2,0,0.2h0.3v0.2
          c0.2,0.1,0.4,0,0.7,0v0.7h-0.2v0.2h-0.2v0.2h-0.2v0.2h-0.2v0.2c-0.1,0-0.2,0-0.2,0.1v0.2h-0.2v0.2h-0.2v0.2h-0.2v0.2h-0.2v0.2
          c-0.2,0-0.5,0-0.7,0.1v0.2c-0.3,0-0.7-0.1-1,0v0.2h-1.6v-0.2c-0.6-0.1-1.2,0-1.7,0c0.1-0.2-0.1-0.2-0.3-0.2h-0.2v-0.9h0.2v-0.2
          c0.2,0,0.5,0,0.7-0.1v-0.2h0.7v-0.2c0.5,0,1.3,0.2,1.4-0.2h0.8v-0.2H69v-0.2c0.1,0,0.2,0.1,0.2-0.1v-0.2c0,0,0.2,0.1,0.2,0V161
          c0,0,0.2,0.1,0.2,0v-0.2c0,0,0.1,0.1,0.2,0v-0.2c0.1,0,0.2,0.1,0.2,0v-0.1c0.2,0,0.3-0.1,0.2-0.2L70.8,160.2L70.8,160.2z
          M43.2,161.4c0,0.1-0.1,0.2,0,0.2h0.2c0,0.1-0.1,0.2,0,0.2h0.2v0.7h-0.2v0.2c-0.2-0.1-0.2,0.2-0.2,0.3v0.2h-0.5v0.2
          c-0.2-0.1-0.3,0.1-0.2,0.3v0.2c-0.1,0-0.2,0-0.2,0.1v0.2c-0.1,0-0.2-0.1-0.2,0v0.2c-0.1,0-0.4-0.1-0.4,0v0.2
          c-0.2-0.1-0.3,0.1-0.2,0.3v0.1c-0.1,0.1-0.2,0-0.2,0.1v0.1c-0.2,0.1-0.5,0-0.7,0.1v0.2c-0.2,0-0.4,0-0.5,0.1v0.2h-4.2v-0.5h-0.6
          c0-0.2,0.1-0.6-0.3-0.4h-0.2v-0.5h-0.2v-0.9h0.2v-0.2h3.3c0.1,0.1,0,0.2,0.1,0.2h0.1c0,0.1,0,0.2,0.1,0.2h0.2v-0.2h0.2v-0.2H39v-0.2
          h0.4V163c0.2,0,0.4,0,0.5-0.1v-0.2h0.5v-0.2h0.5v-0.2h0.2v-0.2h0.7v-0.2c0.2,0,0.6,0.1,0.5-0.2h0.9
          C43.2,161.7,43.2,161.4,43.2,161.4z M45.7,162.4c-0.1,0.2,0.2,0.2,0.3,0.2h0.1v0.2h0.2c0,0.2-0.1,0.7,0.3,0.5h0.3v0.2
          c0.2,0.1,0.5,0,0.7,0c-0.1,0.2,0.1,0.2,0.3,0.2h0.2c-0.2,0.2,0.1,0.2,0.3,0.2h0.1v0.2c0.2,0.1,0.5,0,0.8,0v0.2c0.2,0.1,0.5,0,0.7,0
          v0.2c0.2,0.1,0.5,0,0.7,0v0.2c0.3,0.1,0.7,0,1,0.1v0.2h1.4c0,0.1-0.1,0.2,0.1,0.2h0.2v0.6c-0.1,0.1-0.2,0-0.2,0.1v0.1
          c-0.2,0.1-0.4,0-0.5,0.1v0.2h-3.3v-0.2c-0.2-0.1-0.5,0-0.8-0.1v-0.1c-0.2-0.1-0.7,0-1-0.1c0.1-0.2-0.1-0.2-0.3-0.2h-0.2v-0.2
          c-0.2-0.1-0.5,0-0.8,0c0.1-0.2-0.1-0.2-0.3-0.2h-0.1V165c-0.2-0.1-0.5,0-0.8,0c0.1-0.2-0.1-0.2-0.3-0.2h-0.1c0-0.2,0-0.4-0.1-0.5
          h-0.1v-1.4c0.2,0.1,0.2-0.1,0.2-0.3v-0.2C45.4,162.9,45.1,162.1,45.7,162.4z M87.1,161.4c-0.1,0.2,0.2,0.2,0.3,0.2h0.2v0.2h0.2v0.2
          H88v0.9h-0.2v0.2h-0.5v0.2h-0.5v0.2H86v0.2c-0.2,0-0.5,0-0.7,0.1v0.2c-0.9,0-1.9-0.1-2.9,0v0.2h-0.7V164h-1.2c0-0.2,0.1-0.4,0-0.5
          h-0.2V163c0.5,0,1.3,0.2,1.5-0.2h1.7v-0.2c0.2,0,0.5,0,0.7-0.1v-0.2h0.5v-0.2h0.5v-0.2h0.2v-0.2h0.5v-0.2c0.2-0.1,0.8,0.2,0.7-0.2
          L87.1,161.4L87.1,161.4z M58.5,162.8c-0.1,0.2,0.1,0.2,0.3,0.2H59c0,0.1-0.1,0.2,0.1,0.2h0.2c0,0.1-0.1,0.2,0,0.2h0.2
          c0,0.1-0.1,0.2,0,0.2h0.2c0,0-0.1,0.2,0,0.2h0.2c-0.2,0.2,0.1,0.2,0.3,0.2h0.1c-0.1,0.2,0.1,0.3,0.3,0.2h0.2c0,0.2-0.1,0.7,0.3,0.5
          h0.1c-0.1,0.2,0.1,0.2,0.3,0.2h0.2v0.2h1.4v0.2c0.2,0.1,0.5,0,0.7,0v0.2c0.3,0.1,0.6,0,1,0v0.2c0.2,0.1,0.5,0,0.8,0v0.1l3.1,0.2
          c0,0,0,0.2,0.1,0.2H69v0.7c-0.1,0-0.2,0-0.2,0.1v0.1c-0.1,0-0.3,0-0.4,0.1v0.1h-0.2v0.2h-5v-0.2c-0.2-0.1-0.5,0-0.7,0
          c0.1-0.2-0.1-0.2-0.3-0.2H62V167c-0.2-0.1-0.4-0.1-0.7-0.1v-0.2c-0.3-0.1-0.7-0.1-1-0.1c0.1-0.2-0.1-0.2-0.3-0.2h-0.3v-0.3
          c-0.2-0.1-0.5,0-0.7-0.1c0,0,0-0.2-0.1-0.2h-0.2c0,0,0.1-0.1,0-0.2h-0.2c0-0.1,0.1-0.2,0-0.2h-0.2c0-0.1,0.1-0.2,0-0.2h-0.2V165
          c-0.2-0.1-0.6,0-0.9,0c0.1-0.2-0.1-0.2-0.3-0.2h-0.1v-0.9H57v-0.2c0.1,0,0.2,0,0.2-0.1v-0.2c0.2,0,0.6,0.1,0.5-0.3v-0.2h0.2v-0.2
          C58.2,163.2,58.3,162.9,58.5,162.8z M73.9,163.2c0,0.1-0.1,0.2,0,0.2h0.2c-0.1,0.2,0.1,0.2,0.3,0.2h0.2c-0.1,0.2,0.1,0.2,0.3,0.2H75
          c0,0.1,0,0.2,0.1,0.2h0.2c-0.1,0.2,0.2,0.3,0.3,0.2h0.2v0.2c0.2,0.1,0.6,0,1,0v0.2c0.2,0.1,0.6,0,1,0.1c-0.1,0.2,0.1,0.2,0.3,0.2
          h0.2v0.2c0.2,0.1,0.6,0,0.9,0c-0.1,0.2,0.1,0.3,0.3,0.2h0.1c-0.1,0.2,0.1,0.3,0.3,0.2h0.2v0.1c1.6,0.2,3.2,0.1,4.8,0.1v0.1
          c0.3,0.1,0.6,0.1,0.9,0.1c0,0.1,0,0.2,0.1,0.2h0.2v0.9h-0.2v0.2h-0.2v0.2H85v0.2h-0.7v0.2c-0.5,0.1-1.3-0.2-1.5,0.2h-1.2v-0.2
          l-3.1-0.1v-0.1c-0.3-0.1-0.6,0-1,0v-0.2c-0.2-0.1-0.5,0-0.7,0c0.1-0.2-0.1-0.2-0.3-0.2h-0.2V167c-0.2-0.1-0.4,0-0.7,0v-0.2
          c-0.2-0.1-0.5-0.1-0.7-0.1c0.1-0.2-0.1-0.2-0.3-0.2h-0.2v-0.2c-0.2-0.1-0.5-0.1-0.7-0.1V166c-0.3-0.1-0.7-0.1-1-0.1
          c0,0,0-0.2-0.1-0.2h-0.1c0,0,0-0.2-0.1-0.2h-0.1v-0.4H72v-1.4h0.2v-0.2h0.5v-0.2c0.1,0,0.2,0,0.2-0.1V163
          C73.6,163.7,73.3,162.8,73.9,163.2L73.9,163.2z M114.5,162.7c0,0-0.1,0.2,0,0.2h0.2c0,0-0.1,0.2,0,0.2h0.1c0.1,0.1,0,0.2,0.1,0.2
          h0.1c0,0.1,0,0.2,0.1,0.2h0.2v0.7c-0.1,0-0.2-0.1-0.2,0v0.2c0,0-0.2-0.1-0.2,0v0.2c0,0-0.2-0.1-0.2,0v0.2c-0.2,0-0.4-0.1-0.5,0v0.1
          c-0.2,0.1-0.4,0-0.5,0.1v0.1c-0.4,0.1-1.1-0.2-1.2,0.2c-1.1,0-2.2,0-3.4,0.1v0.2H108v-0.2h-0.2v-0.6h0.2v-0.2h0.7v-0.2h1v-0.2
          c0.4-0.1,1.1,0.2,1.2-0.2h0.5v-0.4h0.7v-0.2h0.5v-0.2h0.5V163C113.8,163.2,113.7,162.4,114.5,162.7L114.5,162.7z M128.4,162.7
          c-0.1,0.2,0.2,0.2,0.3,0.2h0.2v0.2h0.2v1.4c-0.1,0-0.2-0.1-0.2,0v0.2c-0.1,0-0.2-0.1-0.2,0v0.2c-0.1,0-0.3-0.1-0.5,0v0.1
          c-0.2-0.1-0.2,0.2-0.2,0.3v0.2c-0.1,0-0.2,0-0.2,0.1v0.2c-0.1,0-0.2,0-0.2,0.1v0.2c-0.1,0-0.2,0-0.2,0.1v0.1c-0.3,0-0.6,0-0.9,0.1
          v0.1h-1.7v0.2h-1.1v-0.2l-3.4,0.1c0.1-0.2-0.2-0.2-0.3-0.2h-0.2c0.1-0.2-0.2-0.2-0.3-0.2h-0.2V166c-0.2-0.1-0.5-0.1-0.7-0.1
          c0-0.1,0-0.2-0.1-0.2h-0.2v-0.6h0.2v-0.2h1.1v0.2c0.3,0.1,0.6,0,1,0v0.1c0.6,0.1,1.3,0.1,1.9,0v-0.1c0.4-0.1,1.1,0.2,1.2-0.2h0.8
          v-0.3h0.7v-0.2h0.7v-0.2c0.2,0,0.3,0,0.5-0.1v-0.2h0.5v-0.3c0.2,0.1,0.2-0.2,0.2-0.3v-0.2h0.5v-0.2
          C128.1,162.9,127.9,162.5,128.4,162.7L128.4,162.7z M90.2,164.7c-0.1,0.2,0.1,0.3,0.3,0.2h0.2c-0.1,0.2,0.1,0.2,0.3,0.2h0.2
          c-0.2,0.2,0.1,0.2,0.3,0.2h0.1v0.2c0.3,0.1,0.7,0,1,0c0,0.1,0,0.2,0.1,0.2h0.1c-0.1,0.2,0.2,0.3,0.3,0.2h0.2v0.1
          c0.2,0.2,0.5,0.1,0.7,0.1v0.1h2.8v-0.1c0.5-0.1,1.2,0.2,1.5-0.2h1.4v1.1c-0.1,0-0.2,0-0.2,0.1v0.1c-0.1,0-0.2,0-0.2,0.1v0.1h-0.7
          v0.2h-1.9v0.2h-1v0.2H95v0.2h-0.7V168c-0.2-0.1-0.6,0-1,0c0.1-0.2-0.1-0.3-0.3-0.2h-0.1v-0.2c-0.3-0.1-0.7,0-1,0v-0.2h-1.4
          c0.1-0.2-0.2-0.2-0.3-0.2h-0.4c0.1-0.2-0.2-0.2-0.3-0.2h-0.2c0.1-0.2-0.2-0.3-0.3-0.2h-0.2v-0.2h-0.2v-0.2c-0.3-0.1-0.6,0-1-0.1
          c0,0,0.1-0.2,0-0.2h-0.2v-0.9h0.2V165c0.2,0,0.4,0,0.5-0.1v-0.2c0.2,0,0.6,0.1,0.5-0.2h1.6V164.7z M131.5,164.2c0.2,0.1,0.5,0,0.7,0
          c0,0.1-0.1,0.2,0,0.2h0.2v0.2c0.2,0.1,0.4,0,0.7,0v0.2c0.2,0.1,0.5,0,0.8,0c-0.1,0.2,0.1,0.2,0.3,0.2h0.1v0.2h0.2
          c-0.1,0.2,0.2,0.3,0.3,0.2h0.2v0.2c0.2,0.1,0.6,0.1,1,0.1v0.2l1.2,0.1v0.1c0.3,0.1,0.7,0.1,1,0V166c0.3,0,0.7,0,1-0.1v-0.2
          c0.9,0,1.9,0,2.9-0.1v-0.2h1.4v0.2h0.2c0,0.1-0.1,0.2,0.1,0.2h0.2c0,0.1-0.1,0.3,0,0.4h0.2c0,0.1-0.1,0.2,0,0.2h0.2
          c0,0.2-0.1,0.4,0,0.5h0.2v0.9h-0.5v0.2c-0.4,0-1.1-0.2-1.2,0.2c-0.2,0-0.4,0-0.5,0.1v0.2h-4.7v-0.2c-0.5-0.1-1.2,0-1.7-0.1V168h-1.2
          v-0.2c-0.3-0.1-0.6,0-0.9,0c0.1-0.2-0.2-0.2-0.3-0.2h-0.2c0,0,0-0.2-0.1-0.2h-0.2c0,0,0-0.2-0.1-0.2h-0.1c0,0,0-0.2-0.1-0.2h-0.1
          v-0.2h-0.2c0.1-0.2-0.2-0.2-0.3-0.2H132v-0.2h-0.2c0,0,0-0.2-0.1-0.2h-0.2V166h-0.2c0.1-0.2-0.2-0.3-0.3-0.2h-0.2
          c0.1-0.2-0.2-0.2-0.3-0.2h-0.2v-0.2h-0.2V165h-0.2v-0.5h0.2v-0.2c0.2,0,0.6,0.1,0.5-0.2L131.5,164.2L131.5,164.2L131.5,164.2z
          M102.9,164.8c0.2,0.1,0.5,0,0.8,0v0.2c0.2,0.1,0.5,0,0.7,0c-0.1,0.2,0.2,0.2,0.3,0.2h0.2c0,0.2-0.1,0.6,0.3,0.5h0.2
          c-0.1,0.2,0.2,0.3,0.3,0.2h0.2v0.2c0.3,0.1,0.7,0.1,1,0.1v0.1c0.2,0.2,0.5,0,0.8,0.1v0.1c0.6,0.1,1.3,0.1,2,0v-0.1
          c0.9-0.1,1.8,0,2.6-0.1v-0.1h0.7v0.9h-0.5v0.2c-0.5,0-1.2-0.2-1.2,0.2c-0.8,0-1.5,0-2.2,0.1v0.2h-0.7v0.2h-1v0.2h-1.9V168
          c-0.2-0.1-0.5,0-0.8,0v-0.2h-1.4v-0.2c-0.2-0.1-0.7,0-1-0.1c0.1-0.2-0.2-0.2-0.3-0.2h-0.2V167c-0.2-0.1-0.5,0-0.8,0v-0.2h-0.2
          c0.1-0.2-0.2-0.2-0.3-0.2h-0.2v-1.1c0,0,0.2,0,0.2-0.1v-0.1c0.2,0,0.4,0,0.5-0.1v-0.1h0.5v-0.2C102.3,165.2,102.1,164.4,102.9,164.8
          L102.9,164.8z M116.6,165.2c-0.1,0.2,0.2,0.2,0.3,0.2h0.2v0.5h0.2c-0.1,0.2,0.2,0.3,0.3,0.2h0.2v0.1c0.2,0.2,0.5,0,0.7,0.1
          c-0.1,0.2,0.1,0.2,0.3,0.2h0.2v0.2h0.2v0.2c0.2,0.1,0.5,0,0.7,0v0.2c0.2,0.1,0.5,0,0.8,0v0.2c1.3,0.1,2.8,0.1,4.1,0v-0.2h1.4
          c0,0.1-0.1,0.2,0,0.2h0.2v0.4h-0.2v0.2H126v0.2h-0.7v0.2c-0.5,0.1-1.3-0.2-1.5,0.2h-5.2v-0.2l-1.2-0.1c0.1-0.2-0.2-0.2-0.3-0.2h-0.2
          c0-0.1,0.1-0.2,0-0.2h-0.2v-0.2c-0.2-0.1-0.4,0-0.7,0c-0.1,0,0-0.2-0.1-0.2h-0.2c0,0,0-0.2-0.1-0.2h-0.2c0.1-0.2-0.2-0.2-0.3-0.2
          H115c0-0.2,0-0.3-0.1-0.5h-0.1v-0.6c0,0,0.2,0,0.2-0.1v-0.2c0.2,0.1,0.2-0.2,0.2-0.3v-0.2c0.2,0,0.6,0.1,0.5-0.2h0.9V165.2z
          M147.2,158.4l-0.1,0.6c0.2,0.2-0.1,0.1-0.2,0.2c-0.1,0.4-0.1,0.7-0.4,0.9h-0.8v0.1c-0.2,0.1-0.4,0.1-0.5,0.1
          c0.1,0.2-0.2,0.4-0.2,0.4c-0.5,0.2-1.3,0.3-1.9,0.3v0.2c-0.3,0.1-0.6,0.1-0.8,0.1v0.1h-3.1v-0.1c-0.4,0-0.7,0-1-0.1V161
          c-0.6,0-1.2,0-1.7-0.1v-0.2c-0.3,0-0.6,0-0.8-0.1v-0.2l-1.4-0.1v-0.1c-0.3,0-0.6,0.1-0.8-0.1V160c-0.5-0.2-1.1-0.6-1.4-0.8
          c-0.2,0-0.5,0.1-0.7,0V159c-0.3,0-0.6-0.1-0.7-0.2c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3,0.1-0.6,0-0.7-0.2c-0.1,0-0.1-0.2-0.1-0.2
          c-0.2,0-0.6-0.1-0.7-0.2c-0.1-0.4-0.1-0.6-0.3-0.8c0.2-0.5,0.7-0.8,1-1.2h0.8v0.2c0.2-0.1,0.5,0.2,0.7,0.2c0.2,0.4,0.8,0.8,1,1.2
          h1.2c0,0.4,0.4,0.4,0.5,0.7c0.3,0,0.6-0.1,0.8,0v0.2c0.3,0,0.7,0.3,0.9,0.5c0.6,0,1.2-0.1,1.7,0v0.2c0.3,0.1,0.8,0.3,1,0.5h5v-0.2
          c0.1-0.1,0.3,0,0.5,0V159c0.1-0.1,0.3-0.1,0.5-0.1v-0.2h0.5c-0.2-0.3,0.1-0.4,0.2-0.5h0.7c-0.2-0.2,0.2-0.4,0.2-0.4
          c0.4-0.1,0.8-0.1,1.2,0v0.4C147.3,158,147.1,158.3,147.2,158.4z"
          />
        </svg>
      </a>
    </section>
    <section class="gov-banner__text gov-banner__text-right">
      <p>
        <a
          class="gov-banner__link"
          href="https://www.illinois.gov/Gov"
          target="_blank"
        >
          <span class="gov-banner__text-first">J.B. Pritzker,</span>
          <span class="gov-banner__text-second">Governor</span>
        </a>
        <span class="gov-banner__text-pipe">|</span>
        <a
          class="gov-banner__link"
          href="https://illinois.gov/"
          target="_blank"
        >
          <span class="gov-banner__text-fourth">illinois.gov</span>
        </a>
      </p>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.gov-banner {
  background-color: $forest;
  padding: 10px;
  height: 120px;
  display: flex;
  align-items: center;
  &__text,
  p {
    flex-basis: 50%;
    vertical-align: middle;
    margin-bottom: 5px;
    line-height: 14px;
    font-family: "Avenir Next LT W01 Bold";
    color: white;
    &-left p {
      text-align: right;
      font-size: 12px;
      letter-spacing: 0.1em;
    }
    &-right p {
      font-size: 14px;
    }
  }
  &__link {
    color: white;
    text-decoration: none;
    &:hover {
      color: $cream;
    }
  }
  &__logo {
    height: 100px;
    padding: 0 20px;
    fill: white;
    &:hover {
      color: $cream;
      fill: $cream;
    }
  }
}
@media only screen and (max-width: 865px) {
  .gov-banner__text-left .gov-banner__text-first::after,
  .gov-banner__text-left .gov-banner__text-second::after,
  .gov-banner__text-third::after {
    content: "\a";
    white-space: pre;
  }
}
@media only screen and (max-width: 595px) {
  .gov-banner__text-pipe {
    display: none;
  }
  .gov-banner__text-first::after,
  .gov-banner__text-second::after {
    content: "\a";
    white-space: pre;
  }
  .gov-banner__text-fourth {
    display: inline-block;
    margin-top: 5px;
  }
}
</style>
