<template>
  <section class="app-container">
    <GovBanner />
    <mobile-menu />
    <standard-header />
    <nuxt />
    <Footer />
  </section>
</template>

<script>
import StandardHeader from "~/components/StandardHeader/StandardHeader.vue";
import Footer from "~/components/Footer/Footer.vue";
import MobileMenu from "~/components/MobileMenu/MobileMenu";
import GovBanner from "~/components/GovBanner/GovBanner.vue";
export default {
  name: "ChapterLayout",
  components: {
    StandardHeader,
    Footer,
    MobileMenu,
    GovBanner,
  },
};
</script>

<style lang="scss">
.app-container {
  @include app-container;
}
</style>
