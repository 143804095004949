/* eslint-disable no-shadow */

import get from "lodash/get";
import GetMenus from "~/apollo/queries/getMenus";
import GetMenuById from "~/apollo/queries/getMenuById";
import GetGemInformationBySlug from "~/apollo/queries/getGemInformationBySlug";

import { cleanMenuNode } from "~/apollo/dataUtils";

export const state = () => ({
  siteMenuData: null,
  sidebarMenuData: null,
  gemNav: [],
});

export const mutations = {
  setMenuData(state, data) {
    state[data.menuName] = data.menu;
  },
  setGemNav(state, data) {
    state.gemNav = data;
  },
  addItemToGemNav(state, page) {
    state.gemNav.push(page);
  },
};

export const actions = {
  async load(state, client) {
    try {
      // console.log("get menus");
      const { data: mData } = await client.query({
        query: GetMenus,
      });
      let node = mData.menus.nodes.find((obj) => obj.slug === "site-nav");
      if (node) {
        const siteNav = node;
        const menuId = siteNav.id;
        const { data: menuData } = await client.query({
          query: GetMenuById,
          // Always get the server instead of reading from the cache
          fetchPolicy: "network-only",
          prefetch: {
            id: menuId,
          },
          variables: {
            id: menuId,
          },
        });
        if (get(menuData, "menu.menuItems.nodes")) {
          const menu = menuData.menu.menuItems.nodes.map((obj) =>
            cleanMenuNode(obj)
          );
          const publishedMenus = menu.filter((m) => m.status === "publish");
          publishedMenus.forEach((m) => {
            if (m.childItems) {
              m.childItems = m.childItems.filter(
                (i) => i.status === "publish" || !i.pageId
              );
            }
          });
          // console.log("-- publishedMenus: ", publishedMenus);
          this.commit("menu/setMenuData", {
            menuName: "siteMenuData",
            menu: publishedMenus,
          });

          // const gemNav = [];
          const gemPromises = [];
          publishedMenus.forEach((item) => {
            const promise = client.query({
              query: GetGemInformationBySlug,
              prefetch: {
                slug: item.url,
              },
              variables: {
                slug: item.url,
              },
            });
            gemPromises.push(promise);
          });
          await Promise.all(gemPromises).then((values) => {
            const gemPages = values.map((val) => {
              // console.log("-- val:", val);
              const menuItem = publishedMenus.find(
                (pmenu) => pmenu.pageId === val.data.page.pageId
              );
              return Object.assign(val.data.page, {
                menuItemId: menuItem.menuItemId,
              });
            });
            this.commit("menu/setGemNav", gemPages);
          });
        } else {
          this.commit("menu/setMenuData", {
            menuName: "siteMenuData",
            menu: [],
          });
        }
      }
      // console.log("got site-nav");

      node = mData.menus.nodes.find((obj) => obj.slug === "sidebar-nav");
      if (node) {
        const sidebarNav = node;
        const menuId = sidebarNav.id;
        const { data: menuData } = await client.query({
          query: GetMenuById,
          variables: {
            id: menuId,
          },
        });
        if (get(menuData, "menu.menuItems.nodes")) {
          const menu = menuData.menu.menuItems.nodes.map((obj) =>
            cleanMenuNode(obj)
          );
          this.commit("menu/setMenuData", {
            menuName: "sidebarMenuData",
            menu: menu,
          });
        } else {
          this.commit("menu/setMenuData", {
            menuName: "sidebarMenuData",
            menu: [],
          });
        }
      }
      // console.log("got sidebar-nav");

      node = mData.menus.nodes.find((obj) => obj.slug === "features-nav");
      if (node) {
        const featuresNav = node;
        const menuId = featuresNav.id;
        const { data: menuData } = await client.query({
          query: GetMenuById,
          variables: {
            id: menuId,
          },
        });
        if (get(menuData, "menu.menuItems.nodes")) {
          const menu = menuData.menu.menuItems.nodes.map((obj) =>
            cleanMenuNode(obj)
          );
          this.commit("menu/setMenuData", {
            menuName: "featuresMenuData",
            menu: menu,
          });
        } else {
          this.commit("menu/setMenuData", {
            menuName: "featuresMenuData",
            menu: [],
          });
        }
      }
      // console.log("got features-nav");

      node = mData.menus.nodes.find((obj) => obj.slug === "about-nav");
      if (node) {
        const aboutNav = node;
        const menuId = aboutNav.id;
        const { data: menuData } = await client.query({
          query: GetMenuById,
          variables: {
            id: menuId,
          },
        });
        if (get(menuData, "menu.menuItems.nodes")) {
          const menu = menuData.menu.menuItems.nodes.map((obj) =>
            cleanMenuNode(obj)
          );
          this.commit("menu/setMenuData", {
            menuName: "aboutMenuData",
            menu: menu,
          });
        } else {
          this.commit("menu/setMenuData", {
            menuName: "aboutMenuData",
            menu: [],
          });
        }
      }
      // console.log("got about-nav");
      node = mData.menus.nodes.find((obj) => obj.slug === "quick-links");
      if (node) {
        const aboutNav = node;
        const menuId = aboutNav.id;
        const { data: menuData } = await client.query({
          query: GetMenuById,
          variables: {
            id: menuId,
          },
        });
        if (get(menuData, "menu.menuItems.nodes")) {
          const menu = menuData.menu.menuItems.nodes.map((obj) =>
            cleanMenuNode(obj)
          );
          this.commit("menu/setMenuData", {
            menuName: "quickLinksMenuData",
            menu: menu,
          });
        } else {
          this.commit("menu/setMenuData", {
            menuName: "quickLinksMenuData",
            menu: [],
          });
        }
      }
      // console.log("got quick-links");
      node = mData.menus.nodes.find((obj) => obj.slug === "footer-menu");
      if (node) {
        const aboutNav = node;
        const menuId = aboutNav.id;
        const { data: menuData } = await client.query({
          query: GetMenuById,
          variables: {
            id: menuId,
          },
        });
        if (get(menuData, "menu.menuItems.nodes")) {
          const menu = menuData.menu.menuItems.nodes.map((obj) =>
            cleanMenuNode(obj)
          );
          this.commit("menu/setMenuData", {
            menuName: "footerMenuData",
            menu: menu,
          });
        } else {
          this.commit("menu/setMenuData", {
            menuName: "footerMenuData",
            menu: [],
          });
        }
      }
      // console.log("got quick-links");
    } catch (error) {
      console.error("menu error:", error);
    }
  },
};
