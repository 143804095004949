import { get, isEmpty, last, uniq, cloneDeep } from "lodash";
import chartColors from "./chartColors";
import VisualizationDataSamples from "~/lib/VisualizationDataSamples";
import lineDefaults from "~/components/visualization/lineDefaults";

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: false,
    text: "DVA Rate and Deer Harvest 2005-2019",
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  scales: {
    yAxes: [
      {
        id: "y-axis-0",
        position: "left",
        borderWidth: 1,
        // borderColor: chartColors.black,
        ticks: {
          type: "linear",
          beginAtZero: true,
          padding: 10,
          fontColor: chartColors.black,
          callback(value) {
            return value.toLocaleString("en");
          },
        },
        scaleLabel: {
          display: true,
          fontSize: 14,
          labelString: "Deer Harvested",
          fontColor: chartColors.black,
        },
        gridLines: {
          display: true,
          drawOnChartArea: false,
          drawTicks: true,
          color: chartColors.black,
          zeroLineColor: chartColors.black,
        },
      },
      {
        id: "y-axis-1",
        position: "right",
        fontColor: chartColors.black,
        ticks: {
          type: "linear",
          beginAtZero: true,
          padding: 10,
          fontColor: chartColors.black,
        },
        scaleLabel: {
          display: true,
          fontSize: 14,
          labelString: "Deer-Vehicle Accident (DVA)",
          fontColor: chartColors.black,
        },
        gridLines: {
          drawTicks: true,
          display: true,
          drawOnChartArea: false,
          offsetGridLines: false,
          color: chartColors.black,
          zeroLineColor: chartColors.black,
        },
      },
    ],
    xAxes: [
      {
        id: "x-axis",
        position: "bottom",
        // borderColor: chartColors.black,
        ticks: {
          // autoSkip: true,
          padding: 10,
          autoSkipPadding: 5,
          fontColor: chartColors.black,
        },
        scaleLabel: {
          display: true,
          labelString: "Year",
          fontColor: chartColors.black,
          fontSize: 14,
        },
        gridLines: {
          drawTicks: true,
          display: true,
          drawOnChartArea: false,
          offsetGridLines: false,
          color: chartColors.black,
          zeroLineColor: chartColors.black,
        },
      },
    ],
  },
  animation: {
    easing: "linear",
    duration: 500,
    // animationSteps: 30
  },
  tooltips: {
    mode: "label",
    caretSize: 0,
    backgroundColor: "rgba(239,239,234,1.0)",
    titleFontColor: "#000",
    bodyFontColor: "#000",
    callbacks: {
      label(tooltipItem, data) {
        return `${
          data.datasets[tooltipItem.datasetIndex].label
        }: ${tooltipItem.yLabel.toLocaleString()}`;
      },
    },
  },
  legend: {
    display: false,
  },
  legendCallback(chart) {
    const text = [];
    const type = chart.chart.config.type;
    let voffset = 0;
    text.push(
      `<div style="text-align: center; padding: 20px 0 0 0; margin-bottom: 0; "><ul>`
    );
    chart.data.datasets.forEach((ds, idx) => {
      text.push(
        `<li style="display: inline; margin-right: 1.5em;" onclick="updateDataset(event, ${idx});">`
      );
      text.push(
        // eslint-disable-next-line max-len
        `<span style="font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif; font-size: 12px; font-weight: bold;">`
      );
      text.push(
        `<svg version="1.1" baseProfile="full" width="60" height="24" xmlns="http://www.w3.org/2000/svg">`
      );
      if (type === "line") {
        voffset = -7;
        text.push(
          `<circle cx="35" cy="12" r="6" stroke="${ds.borderColor}" fill="white" stroke-width="2" />`
        );
        text.push(
          `<line x1="16" y1="12" x2="52" y2="12" style="stroke:${ds.borderColor}; stroke-width:2" />`
        );
      }
      if (type === "bar") {
        voffset = -4;
        text.push(
          `<rect width="48" height="18" x="0" y="6" style="fill:${ds.borderColor};storke-width:2" />`
        );
      }
      text.push(`</svg>`);
      text.push(
        `<span style="position: relative; top: ${voffset}px;">${ds.label}</span>`
      );
      text.push("</span></li>");
    });
    text.push("</ul></div>");
    return text.join("");
  },
};

const lineCharts = [
  "default",
  "trend",
  "buckHarvest",
  "adultBuckHarvest",
  "yearlingBuckHarvest",
  "buttonBuckHarvest",
  "doeHarvest",
  "adultDoeHarvest",
  "doeFawnHarvest",
];

const VisualizationFunctions = {
  isLineChart(type) {
    return lineCharts.indexOf(type) !== -1;
  },
  /**
   * Creates an array of values starting at beginVal through endVal
   * Similar to Ruby's [1...N], only works for whole numbers
   */
  rangeInclude(beginVal, endVal) {
    return Array(endVal + 1 - beginVal)
      .fill(0)
      .map((v, i) => i + beginVal);
  },
  getDatasetOptions(type) {
    const options = lineDefaults;
    switch (type) {
      case "trend":
        break;
      case "buckHarvest":
      case "adultBuckHarvest":
      case "yearlingBuckHarvest":
      case "buttonBuckHarvest":
      case "doeHarvest":
      case "adultDoeHarvest":
      case "doeFawnHarvest":
        Object.assign(options, {
          backgroundColor: chartColors.barlightblue,
          // borderColor: chartColors.barlightblue,
          pointHoverBorderColor: chartColors.barlightblue,
        });
        break;
      case "sexRatio":
        break;
      case "yearlingBucksRatio":
        break;
      case "matureBucksRatio":
        break;
      default:
        break;
    }
    return options;
  },
  getChartOptions(type) {
    // return defaultOptions;
    const options = defaultOptions;
    const xAxis = options.scales.xAxes[0];
    const leftYAxis = options.scales.yAxes.find((obj) => obj.id === "y-axis-0");
    const rightYAxis = options.scales.yAxes.find((obj) => {
      return obj.id === "y-axis-1";
    });
    switch (type) {
      case "trend":
        VisualizationFunctions.updateToTrendOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "buckHarvest":
        VisualizationFunctions.updateToBuckHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "adultBuckHarvest":
        VisualizationFunctions.updateToAdultBuckHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "yearlingBuckHarvest":
        VisualizationFunctions.updateToYearlingBuckHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "buttonBuckHarvest":
        VisualizationFunctions.updateToButtonBuckHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "doeHarvest":
        VisualizationFunctions.updateToDoeHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "adultDoeHarvest":
        VisualizationFunctions.updateToAdultDoeHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "doeFawnHarvest":
        VisualizationFunctions.updateToDoeFawnHarvestOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "sexRatio":
        VisualizationFunctions.updateToSexRatioOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "yearlingBucksRatio":
        VisualizationFunctions.updateToYearlingBucksRatioOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      case "matureBucksRatio":
        VisualizationFunctions.updateToMatureBucksRatioOptions(
          xAxis,
          leftYAxis,
          rightYAxis
        );
        break;
      default:
        VisualizationFunctions.restoreDefaults(xAxis, leftYAxis, rightYAxis);
        break;
    }
    return options;
  },
  updateAxesForLine(xAxis, rightYAxis) {
    xAxis.offset = false;
    rightYAxis.display = false;
  },
  updateToTrendOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Deer Harvested";
  },
  updateToBuckHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Buck Harvested";
  },
  updateToAdultBuckHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Adult Buck Harvested";
  },
  updateToYearlingBuckHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Yearling Buck Harvested";
  },
  updateToButtonBuckHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Button Buck Harvested";
  },
  updateToDoeHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Doe Harvested";
  },
  updateToAdultDoeHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Adult Doe Harvested";
  },
  updateToDoeFawnHarvestOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForLine(xAxis, rightYAxis);
    leftYAxis.scaleLabel.labelString = "Doe Fawn Harvested";
  },
  updateAxesForBar(xAxis, leftYAxis, rightYAxis) {
    rightYAxis.display = false;
    leftYAxis.scaleLabel.labelString = "Deer Harvested";
    xAxis.offset = true;
  },
  updateToSexRatioOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForBar(xAxis, leftYAxis, rightYAxis);
    xAxis.barPercentage = 0.9;
    xAxis.categoryPercentage = 0.9;
  },
  updateToYearlingBucksRatioOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForBar(xAxis, leftYAxis, rightYAxis);
  },
  updateToMatureBucksRatioOptions(xAxis, leftYAxis, rightYAxis) {
    VisualizationFunctions.updateAxesForBar(xAxis, leftYAxis, rightYAxis);
  },
  restoreDefaults(xAxis, leftYAxis, rightYAxis) {
    // xAxis.offset = null;
    rightYAxis.display = true;
    rightYAxis.scaleLabel.labelString =
      "Deer-Vehicle Accident (DVA) per billion miles traveled";
    leftYAxis.scaleLabel.labelString = "Deer Harvested";
  },
};

export default VisualizationFunctions;
