/*eslint-disable*/

const barData = {
  "labels": [
    2005,
    2006,
    2007,
    2008,
    2009,
    2010,
    2011,
    2012,
    2013,
    2014,
    2015,
    2016
  ],
  "datasets": [
    {
      "label": "Male",
      "yAxisId": "y-axis-0",
      "backgroundColor": "rgba(34, 74, 112, 1)",
      "borderColor": "rgba(34, 74, 112, 1)",
      "borderWidth": 2,
      "pointHoverBorderColor": "rgba(34, 74, 112, 1)",
      "data": [
        48675,
        48547,
        49731,
        42860,
        42839,
        42301,
        41466,
        41800,
        32732,
        35449,
        39204,
        39975
      ]
    },
    {
      "label": "Female",
      "yAxisId": "y-axis-0",
      "backgroundColor": "rgba(138, 57, 59, 1)",
      "borderColor": "rgba(138, 57, 59, 1)",
      "borderWidth": 2,
      "pointHoverBorderColor": "rgba(138, 57, 59, 1)",
      "data": [
        72415,
        69267,
        71870,
        75504,
        75810,
        72708,
        72152,
        70071,
        58820,
        56443,
        59210,
        53369
      ]
    }
  ]
}

export default barData;
