import chartColors from "./chartColors";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: false,
    text: "DVA Rate and Deer Harvest 1989-2014",
  },
  scales: {
    yAxes: [
      {
        id: "y-axis-0",
        position: "left",
        borderWidth: 1,
        // borderColor: chartColors.black,
        ticks: {
          type: "linear",
          beginAtZero: true,
          padding: 10,
          fontColor: chartColors.black,
          callback(value) {
            return value.toLocaleString("en");
          },
        },
        scaleLabel: {
          display: true,
          fontSize: 14,
          labelString: "Deer Harvested",
          fontColor: chartColors.black,
        },
        gridLines: {
          display: false,
          drawTicks: false,
          color: chartColors.black,
          zeroLineColor: chartColors.black,
        },
      },
      {
        id: "y-axis-1",
        position: "right",
        fontColor: chartColors.black,
        ticks: {
          type: "linear",
          beginAtZero: true,
          fontColor: chartColors.black,
        },
        scaleLabel: {
          display: true,
          fontSize: 14,
          labelString: "Rate (DVA/Billion Miles)",
          fontColor: chartColors.black,
        },
        gridLines: {
          // drawTicks: false,
          display: false,
          // lineWidth: 0
          color: chartColors.black,
          zeroLineColor: chartColors.black,
        },
      },
    ],
    xAxes: [
      {
        id: "x-axis",
        position: "bottom",
        ticks: {
          autoSkip: true,
          padding: 20,
          autoSkipPadding: 5,
          fontColor: chartColors.black,
        },
        scaleLabel: {
          display: true,
          labelString: "Year",
          fontColor: chartColors.black,
          fontSize: 14,
          // fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          // fontStyle: "normal"
        },
        gridLines: {
          drawTicks: false,
          display: false,
          // zeroLineColor: chartColors.red,
          // zeroLineWidth: 2
          color: chartColors.black,
          zeroLineColor: chartColors.black,
        },
      },
    ],
  },
  animation: {
    animationSteps: 30,
  },
  tooltips: {
    mode: "label",
    //   backgroundColor: "rgba(75,192,192,1)",
    //   titleFontColor: "#000",
    //   bodyFontColor: "#000"
    callbacks: {
      label(tooltipItem, data) {
        return `${
          data.datasets[tooltipItem.datasetIndex].label
        }: ${tooltipItem.yLabel.toLocaleString()}`;
      },
    },
  },
  legend: {
    // display: false
  },
  legendCallback(chart) {
    const text = [];
    const type = chart.chart.config.type;
    let voffset = 0;
    text.push(
      `<div style="text-align: center; padding: 20px 0 0 0; margin-bottom: 0; "><ul>`
    );
    chart.data.datasets.forEach((ds, idx) => {
      text.push(
        `<li style="display: inline; margin-right: 1.5em;" onclick="updateDataset(event, ${idx});">`
      );
      text.push(
        // eslint-disable-next-line max-len
        `<span style="font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif; font-size: 12px; font-weight: bold;">`
      );
      text.push(
        `<svg version="1.1" baseProfile="full" width="60" height="24" xmlns="http://www.w3.org/2000/svg">`
      );
      if (type === "line") {
        voffset = -7;
        text.push(
          `<circle cx="35" cy="12" r="6" stroke="${ds.borderColor}" fill="white" stroke-width="2" />`
        );
        text.push(
          `<line x1="16" y1="12" x2="52" y2="12" style="stroke:${ds.borderColor}; stroke-width:2" />`
        );
      }
      if (type === "bar") {
        voffset = -4;
        text.push(
          `<rect width="48" height="18" x="0" y="6" style="fill:${ds.borderColor};stroke-width:2" />`
        );
      }
      text.push(`</svg>`);
      text.push(
        `<span style="position: relative; top: ${voffset}px;">${ds.label}</span>`
      );
      text.push("</span></li>");
    });
    text.push("</ul></div>");
    return text.join("");
  },
};

export default options;
