<template>
  <div
    class="menu-button"
    aria-label="Mobile Navigation Button"
    aria-haspopup="true"
    :class="{ 'menu-button--open': menuOpen }"
    @click="toggleNav"
  >
    <div class="menu-button__open">
      <i class="fa fa-bars" aria-label="menu item"></i>
      <p class="menu-button__label">Menu</p>
    </div>
    <div class="menu-button__close">
      <i
        class="fas fa-times menu-button__icon menu-button__icon--close"
        aria-label="menu item"
      ></i>
      <p class="menu-button__label">Close</p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  computed: {
    menuOpen() {
      return this.$store.state.mobileMenu.menuOpen;
    },
  },
  methods: {
    toggleNav() {
      this.$store.commit("mobileMenu/toggleMenu");
    },
  },
};
</script>

<style lang="scss">
%fillings {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition-delay: 0.05s;
  transition: all 0.4s ease-out;
  width: 100%;
}

.menu-button {
  align-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  color: black;
  cursor: pointer;
  display: flex;
  flex: none;
  flex-wrap: wrap;
  font-size: 1rem;
  height: 50px;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
  overflow: hidden;
  transition-delay: 0.05s;
  // transition: all 0.4s ease-out;
  width: 50px;
  z-index: 1002;
  .body--menu-open & {
    margin-right: 17px;
  }
  @include desktop {
    display: none;
  }
  &__label {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }
  &__icon {
    width: 100%;
    text-align: center;
  }
  &__open {
    @extend %fillings;
    top: 0px;
    background-color: get-color("grey", 200);
  }
  &__close {
    @extend %fillings;
    top: 50px;
  }
  &--open {
    .menu-button__open {
      top: -50px;
    }
    .menu-button__close {
      top: -0px;
    }
  }
}
</style>
